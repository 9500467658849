import {
  AcctPermission,
  useGetApAdvancebyStatusLazyQuery,
  useGetApAdvancebyStatusListingLazyQuery,
  useGetApCreditNotebyStatusLazyQuery,
  useGetApCreditNotebyStatusListingLazyQuery,
  useGetApCreditorCreditNoteByStatusLazyQuery,
  useGetApCreditorCreditNoteByStatusListingLazyQuery,
  useGetApCreditorDebitNotebyStatusListingLazyQuery,
  useGetApDebitNotebyStatusLazyQuery,
  useGetApDebitNotebyStatusListingLazyQuery,
  useGetApInvoicebyStatusListingLazyQuery,
  useGetApPaymentbyStatusListingLazyQuery,
  useGetApRefundbyStatusLazyQuery,
  useGetApSelfBilledbyStatusListingLazyQuery,
  useGetUsersByAccountAndSoftwareQuery,
} from 'generated/graphql'
import React, { useEffect } from 'react'

export const useAPListingData: any = ({
  accountType,
  apSubmenu,
  CompanyID,
  setOriginalListing,
  filteredList,
}) => {
  const getSearch = JSON.parse(localStorage.getItem('searchFilter'))
  let listStatus = getSearch?.option ?? 'ACTIVE'

  //----------AP Queries----------//
  const {
    loading: getUsersLoading,
    error: getUsersError,
    data: { getUsersByAccountAndSoftware } = {
      getUsersByAccountAndSoftware: [],
    },
  } = useGetUsersByAccountAndSoftwareQuery({
    fetchPolicy: 'network-only',
  })

  const [
    loadApAdvance,
    {
      loading: ApAdvancebyStatusLoading,
      called: ApAdvanceCalled,
      data: { getAPAdvancebyStatusListing } = {
        getAPAdvancebyStatusListing: [],
      },
    },
  ] = useGetApAdvancebyStatusListingLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ getAPAdvancebyStatusListing }) => {
      if (getAPAdvancebyStatusListing?.length > 0) {
        setOriginalListing([...filteredList, ...getAPAdvancebyStatusListing])
      }
    },
  })
  const [
    loadApInvoice,
    {
      loading: ApInvoicebyStatusLoading,
      called: ApInvoiceCalled,
      data: { getAPInvoicebyStatusListing } = {
        getAPInvoicebyStatusListing: [],
      },
    },
  ] = useGetApInvoicebyStatusListingLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ getAPInvoicebyStatusListing }) => {
      if (getAPInvoicebyStatusListing?.length > 0) {
        setOriginalListing([...filteredList, ...getAPInvoicebyStatusListing])
      }
    },
  })

  const [
    loadApRefund,
    {
      loading: ApRefundbyStatusLoading,
      called: ApRefundCalled,
      data: { getAPRefundbyStatus } = {
        getAPRefundbyStatus: [],
      },
    },
  ] = useGetApRefundbyStatusLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ getAPRefundbyStatus }) => {
      if (getAPRefundbyStatus?.length > 0) {
        setOriginalListing(getAPRefundbyStatus)
      } else {
        setOriginalListing([])
      }
    },
  })
  const [
    loadApCreditNote,
    {
      loading: ApCreditNotebyStatusLoading,
      called: ApCreditNoteCalled,
      data: { getAPCreditNotebyStatusListing } = {
        getAPCreditNotebyStatusListing: [],
      },
    },
  ] = useGetApCreditNotebyStatusListingLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ getAPCreditNotebyStatusListing }) => {
      if (getAPCreditNotebyStatusListing?.length > 0) {
        setOriginalListing([...filteredList, ...getAPCreditNotebyStatusListing])
      }
    },
  })

  const [
    loadApCreditorCreditNote,
    {
      loading: ApCreditorCreditNotebyStatusLoading,
      called: ApCreditorCreditNoteCalled,
      data: { getAPCreditorCreditNotebyStatusListing } = {
        getAPCreditorCreditNotebyStatusListing: [],
      },
    },
  ] = useGetApCreditorCreditNoteByStatusListingLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ getAPCreditorCreditNotebyStatusListing }) => {
      if (getAPCreditorCreditNotebyStatusListing?.length > 0) {
        setOriginalListing([
          ...filteredList,
          ...getAPCreditorCreditNotebyStatusListing,
        ])
      }
    },
  })

  const [
    loadApDebitNote,
    {
      loading: ApDebitNotebyStatusLoading,
      called: ApDebitNoteCalled,
      data: { getAPDebitNotebyStatusListing } = {
        getAPDebitNotebyStatusListing: [],
      },
    },
  ] = useGetApDebitNotebyStatusListingLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ getAPDebitNotebyStatusListing }) => {
      if (getAPDebitNotebyStatusListing?.length > 0) {
        setOriginalListing([...filteredList, ...getAPDebitNotebyStatusListing])
      }
    },
  })

  const [
    loadApCreditorDebitNote,
    {
      loading: ApCreditorDebitNotebyStatusLoading,
      called: ApCreditorDebitNoteCalled,
      data: { getAPCreditorDebitNotebyStatusListing } = {
        getAPCreditorDebitNotebyStatusListing: [],
      },
    },
  ] = useGetApCreditorDebitNotebyStatusListingLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ getAPCreditorDebitNotebyStatusListing }) => {
      if (getAPCreditorDebitNotebyStatusListing?.length > 0) {
        setOriginalListing([
          ...filteredList,
          ...getAPCreditorDebitNotebyStatusListing,
        ])
      }
    },
  })

  const [
    loadAPPayment,
    {
      loading: getAPPaymentbyStatusListingLoading,
      called: ApPaymentCalled,
      data: { getAPPaymentbyStatusListing } = {
        getAPPaymentbyStatusListing: [],
      },
    },
  ] = useGetApPaymentbyStatusListingLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ getAPPaymentbyStatusListing }) => {
      if (
        getAPPaymentbyStatusListing?.length > 0 &&
        listStatus === 'COMPLETED'
      ) {
        setOriginalListing([...filteredList, ...getAPPaymentbyStatusListing])
      } else if (
        getAPPaymentbyStatusListing?.length > 0 &&
        listStatus !== 'COMPLETED'
      ) {
        setOriginalListing([
          ...filteredList,
          ...getAPPaymentbyStatusListing?.filter(x => !x?.BatchPaymentID),
        ])
      }
    },
  })

  const [
    loadApSelfBilled,
    {
      loading: ApSelfBilledbyStatusLoading,
      called: ApSelfBilledCalled,
      data: { getAPSelfBilledbyStatusListing } = {
        getAPSelfBilledbyStatusListing: [],
      },
    },
  ] = useGetApSelfBilledbyStatusListingLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ getAPSelfBilledbyStatusListing }) => {
      if (getAPSelfBilledbyStatusListing?.length > 0) {
        setOriginalListing([...filteredList, ...getAPSelfBilledbyStatusListing])
      }
    },
  })

  let loading,
    called,
    loadData,
    listData,
    subMenuName,
    primaryKey,
    createPermissionCheck,
    updatePermissionCheck,
    deletePermissionCheck,
    approveRejectPermissionCheck,
    userList = getUsersByAccountAndSoftware

  switch (apSubmenu) {
    case 'advance':
      loading = ApAdvancebyStatusLoading
      loadData = loadApAdvance
      called = ApAdvanceCalled
      listData = getAPAdvancebyStatusListing
      subMenuName = 'Advance'
      primaryKey = 'AdvanceID'
      createPermissionCheck = AcctPermission.AccPayableAdvancesCreate
      updatePermissionCheck = AcctPermission.AccPayableAdvancesUpdate
      deletePermissionCheck = AcctPermission.AccPayableAdvancesDelete
      approveRejectPermissionCheck =
        AcctPermission.AccPayableAdvancesApproveReject
      break
    case 'invoice':
      loading = ApInvoicebyStatusLoading
      loadData = loadApInvoice
      called = ApInvoiceCalled
      listData = getAPInvoicebyStatusListing
      subMenuName = 'Invoice'
      primaryKey = 'InvoiceID'
      createPermissionCheck = AcctPermission.AccPayableInvoicesCreate
      updatePermissionCheck = AcctPermission.AccPayableInvoicesUpdate
      deletePermissionCheck = AcctPermission.AccPayableInvoicesDelete
      approveRejectPermissionCheck =
        AcctPermission.AccPayableInvoicesApproveReject
      break
    case 'refund':
      loading = ApRefundbyStatusLoading
      loadData = loadApRefund
      called = ApRefundCalled
      listData = getAPRefundbyStatus
      subMenuName = 'ApRefund'
      primaryKey = 'RefundID'
      createPermissionCheck = AcctPermission.AccPayableRefundCreate
      updatePermissionCheck = AcctPermission.AccPayableRefundUpdate
      deletePermissionCheck = AcctPermission.AccPayableRefundDelete
      approveRejectPermissionCheck =
        AcctPermission.AccPayableRefundApproveReject
      break
    case 'debit-note':
      loading = ApDebitNotebyStatusLoading
      loadData = loadApDebitNote
      called = ApDebitNoteCalled
      listData = getAPDebitNotebyStatusListing
      subMenuName = 'DebitNote'
      primaryKey = 'DebitNoteID'
      createPermissionCheck = AcctPermission.AccPayableDebitNotesCreate
      updatePermissionCheck = AcctPermission.AccPayableDebitNotesUpdate
      deletePermissionCheck = AcctPermission.AccPayableDebitNotesDelete
      approveRejectPermissionCheck =
        AcctPermission.AccPayableDebitNotesApproveReject
      break
    case 'creditor-debit-note':
      loading = ApCreditorDebitNotebyStatusLoading
      loadData = loadApCreditorDebitNote
      called = ApCreditorDebitNoteCalled
      listData = getAPCreditorDebitNotebyStatusListing
      subMenuName = 'CreditorDebitNote'
      primaryKey = 'CreditorDebitNoteID'
      createPermissionCheck = AcctPermission.AccPayableCreditorDebitNotesCreate
      updatePermissionCheck = AcctPermission.AccPayableCreditorDebitNotesUpdate
      deletePermissionCheck = AcctPermission.AccPayableCreditorDebitNotesDelete
      approveRejectPermissionCheck =
        AcctPermission.AccPayableCreditorDebitNotesApproveReject
      break
    case 'credit-note':
      loading = ApCreditNotebyStatusLoading
      loadData = loadApCreditNote
      called = ApCreditNoteCalled
      listData = getAPCreditNotebyStatusListing
      subMenuName = 'CreditNote'
      primaryKey = 'CreditNoteID'
      createPermissionCheck = AcctPermission.AccPayableCreditNotesCreate
      updatePermissionCheck = AcctPermission.AccPayableCreditNotesUpdate
      deletePermissionCheck = AcctPermission.AccPayableCreditNotesDelete
      approveRejectPermissionCheck =
        AcctPermission.AccPayableCreditNotesApproveReject
      break
    case 'creditor-credit-note':
      loading = ApCreditorCreditNotebyStatusLoading
      loadData = loadApCreditorCreditNote
      called = ApCreditorCreditNoteCalled
      listData = getAPCreditorCreditNotebyStatusListing
      subMenuName = 'CreditorCreditNote'
      primaryKey = 'CreditorCreditNoteID'
      createPermissionCheck = AcctPermission.AccPayableCreditorCreditNotesCreate
      updatePermissionCheck = AcctPermission.AccPayableCreditorCreditNotesUpdate
      deletePermissionCheck = AcctPermission.AccPayableCreditorCreditNotesDelete
      approveRejectPermissionCheck =
        AcctPermission.AccPayableCreditorCreditNotesApproveReject
      break
    case 'payment':
      loading = getAPPaymentbyStatusListingLoading
      loadData = loadAPPayment
      called = ApPaymentCalled
      listData = getAPPaymentbyStatusListing
      subMenuName = 'Payment'
      primaryKey = 'PaymentID'
      createPermissionCheck = AcctPermission.AccPayablePaymentCreate
      updatePermissionCheck = AcctPermission.AccPayablePaymentUpdate
      deletePermissionCheck = AcctPermission.AccPayablePaymentDelete
      approveRejectPermissionCheck =
        AcctPermission.AccPayablePaymentApproveReject
      break
    case 'self-billed':
      loading = ApSelfBilledbyStatusLoading
      loadData = loadApSelfBilled
      called = ApSelfBilledCalled
      listData = getAPSelfBilledbyStatusListing
      subMenuName = 'SelfBilled'
      primaryKey = 'SelfBilledID'
      createPermissionCheck = AcctPermission.AccPayablePaymentCreate
      updatePermissionCheck = AcctPermission.AccPayablePaymentUpdate
      deletePermissionCheck = AcctPermission.AccPayablePaymentDelete
      approveRejectPermissionCheck =
        AcctPermission.AccPayablePaymentApproveReject
      break
  }

  return {
    loading,
    called,
    listData,
    subMenuName,
    primaryKey,
    userList,
    loadData,
    createPermissionCheck,
    updatePermissionCheck,
    deletePermissionCheck,
    approveRejectPermissionCheck,
  }
}
