import { AttachFile } from '@material-ui/icons'
import { attachmentDetailView } from 'helpers/Hooks/attachmentDetailsView'
import { formatDate } from 'helpers/StringNumberFunction/FormatDate'
import { default as React, useEffect, useState } from 'react'
import PrintIcon from '@material-ui/icons/Visibility'
import { useHistory, useParams } from 'react-router'
import '../Journal.scss'
import { useMenuOption } from 'helpers/Hooks/useMenuOption'
import { AttachmentDialog } from 'components/Dialog/AttachmentDialog'
import { useDocumentListingQuery } from 'generated/graphql'

interface DetailProps {
  listEl: any
  listStatus: string
  userList: any
  documentListing?: any
  mode?: string
}

export const JournalDetailContent = (props: DetailProps) => {
  let history = useHistory()
  const { CompanyID }: any = useParams()

  const { listEl, documentListing, listStatus, userList, mode } = props

  const {
    loading: documentLoading,
    data: { DocumentListing } = { DocumentListing: [] },
  } = useDocumentListingQuery({
    fetchPolicy: 'network-only',
    variables: {
      refID: listEl?.JournalProcessingID,
    },
  })

  const [openDoc, setOpenDoc] = useState<boolean>(false)
  const {
    anchorEl,
    menu,
    handleClick,
    handleClose,
    resetMenu: resetVert,
  } = useMenuOption()

  return (
    <>
      <div className="content-wrap left">
        <div className="desc" style={{ color: 'grey' }}>
          Reference No
        </div>
        <div className="mdDesc"> {listEl?.RefNo} </div>
      </div>

      <div className="content-wrap right">
        {/* <div className="desc" style={{ color: 'grey' }}>
          {'Preview Document'}
        </div> */}
        <span
          style={{ float: 'right' }}
          onClick={() => {
            history.push({
              pathname: `/general-ledger/${CompanyID}/journal-processing/${listEl?.JournalProcessingID}/preview`,
              state: {
                ...listEl,
                mode: 'detail',
              },
            })
          }}
        >
          <PrintIcon
            style={{
              fontSize: 'medium',
              padding: '4px 10px 2px 0px',
            }}
          />
        </span>
        {documentListing && documentListing?.length > 0 && (
          <>
            <span
              style={{ float: 'right', color: '#000' }}
              onClick={e => {
                setOpenDoc(true)
              }}
            >
              <AttachFile
                className="mdDesc"
                style={{
                  fontSize: 12,
                  color: 'orange',
                }}
              />

              <span
                className="mdDesc"
                style={{
                  float: 'right',
                  color: 'orange',
                  textDecoration: 'underline',
                  paddingRight: '5px',
                  paddingTop: '4px',
                }}
              >
                {documentListing?.length}
              </span>
            </span>
          </>
        )}
      </div>

      <div
        className="content-wrap left"
        style={{ width: mode === 'expansion' ? '40%' : '50%' }}
      >
        <div className="desc" style={{ color: 'grey' }}>
          Journal Type
        </div>
        <div className="mdDesc"> {listEl?.JournalType?.JournalType} </div>
      </div>

      <div className="content-wrap full ">
        <div className="desc " style={{ color: 'grey' }}>
          Description
        </div>
        <div className="mdDesc text-noflow">
          {' '}
          {listEl?.Description ?? listEl?.Remark}{' '}
        </div>
      </div>
      {/* <div className="content-wrap full">
        <div className="desc" style={{ color: 'grey' }}>
          Reference No.
        </div>
        <div className="mdDesc"> {listEl?.RefNo} </div>
      </div> */}

      <div className="content-wrap left">
        <div className="desc" style={{ color: 'grey' }}>
          {'Submitted Date'}
        </div>
        <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
          {formatDate(listEl?.submittedTs)}
        </div>
      </div>
      <div className="content-wrap right">
        <div className="desc" style={{ color: 'grey' }}>
          {'Submitted By'}
        </div>
        <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
          {userList?.filter(user => user.ID == listEl?.submittedBy)[0]?.name}
        </div>
      </div>

      {listStatus === 'COMPLETED' && (
        <>
          <div className="content-wrap left">
            <div className="desc" style={{ color: 'grey' }}>
              {'Approved Date'}
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {formatDate(listEl?.approvedTs)}
            </div>
          </div>
          <div className="content-wrap right">
            <div className="desc" style={{ color: 'grey' }}>
              {'Approved By'}
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {userList?.filter(user => user.ID == listEl?.approvedBy)[0]?.name}
            </div>
          </div>
        </>
      )}
      <AttachmentDialog
        title="Journal Entries"
        menu={menu}
        openDoc={openDoc}
        setOpenDoc={setOpenDoc}
        attachmentDetailView={attachmentDetailView}
        DocumentListing={DocumentListing}
      />
    </>
  )
}
