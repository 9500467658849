import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import { smTitle } from '@ifca-root/react-component/src/global/TitleVariable'
import { TotalAmountFooter } from 'components/Footer/TotalAmountFooter'
import React, { useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import { formatDate } from 'helpers/StringNumberFunction/FormatDate'
import { ARConsolidateEInvoiceDetailContent } from './ARConsolidateEInvoiceDetailContent'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import { ConsolidateTable } from '../ConsolidateTable/ConsolidateTable'
import { useGetCompanyNameQuery } from 'generated/graphql'

export const ARConsolidateEInvoiceDetail = (props: any) => {
  /*
  ===========================================
  =================VARIABLES=================
  ===========================================
  */

  let history = useHistory()
  let location = useLocation()
  const listData = location?.state as any
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const { CompanyID }: any = useParams()

  /*
  ===========================================
  =================USE STATE=================
  ===========================================
  */

  const [labelState, setLabelState] = useState<string>()

  /*
  ====================================
  ==============QUERY=================
  ====================================
  */

  const {
    loading: CompanyLoading,
    error: CompanyError,
    data: { getCompany } = { getCompany: [] },
  } = useGetCompanyNameQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID: CompanyID },
    onCompleted: () => {
      if (!!getCompany) setLabelState(getCompany[0]?.Name)
    },
  })

  // ==== Table Pagination =====
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  /*
  ==========================================
  =================FUNCTION=================
  ==========================================
  */

  const totalDocAmt = listData?.ConsolidateDetail?.reduce(
    (total, current) => total + Number(current?.DocAmt),
    0
  )

  const totalTaxAmt = listData?.ConsolidateDetail?.reduce(
    (total, current) => total + Number(current?.TaxAmt),
    0
  )

  return (
    <>
      <MainHeader
        mainBtn={'close'}
        onClick={() =>
          history.push({
            pathname: `/account-receivable/${CompanyID}/consolidate-einvoice`,
          })
        }
        smTitle={smTitle.ACCOUNT_RECEIVABLE}
        title={labelState}
        currency={user?.companyCurrencyCode}
        routeSegments={[
          { name: 'Account Receivable' },
          { name: `Consolidate e-Invoice`, current: true },
        ]}
        rightRouteSegments={[{ name: 'View', current: true }]}
      />

      <DynamicSubHeader
        title={
          <>
            <span className="xsTitle" style={{ color: '#ff9800' }}>
              {listData?.DocNo}
            </span>
          </>
        }
        rightText={
          <span className="c-orange">{formatDate(listData?.DocDate)}</span>
        }
      />

      <ContentWrapper singleDynamicInfo float>
        <CardContents>
          <ARConsolidateEInvoiceDetailContent listEl={listData} />
        </CardContents>

        <CardContents
          section={{
            header: {
              title: `Consolidated Detail(s)`,
              customFontSizeClass: 'xsTitle',
            },
          }}
        >
          <ConsolidateTable
            data={listData?.eInvoiceConsolidationDetail}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </CardContents>
      </ContentWrapper>

      <TotalAmountFooter
        taxInfoDetailWithRecord={true}
        docAmt={totalDocAmt}
        totalTaxAmt={totalTaxAmt}
      />
    </>
  )
}
