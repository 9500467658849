import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import {
  StyledMenu,
  StyledMenuItem,
} from '@ifca-root/react-component/src/components/others/Menu'
import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  useMediaQuery,
} from '@material-ui/core'
import { KeyboardArrowRight } from '@material-ui/icons'
import AppContext from 'containers/App/Store/AppContext'
import { AcctPermission } from 'generated/graphql'
import { useMenuOption } from 'helpers/Hooks/useMenuOption'
import { usePermissionChecker } from 'helpers/Hooks/usePermissionChecker'
import React, { useContext, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import theme from '@ifca-root/react-component/src/assets/theme'
import { dropdownOption } from 'containers/DigitalReportModule/function/DigitalReportDropdown'

export const GLDigitalReportSubmenu = (props: any) => {
  const { globalState, dispatch }: any = useContext(AppContext as any)
  let history = useHistory()
  const { CompanyID }: any = useParams()
  const [labelState, setLabelState] = useState<string>('General Ledger Reports')
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const { handlePermHidden } = usePermissionChecker()
  const { anchorEl, menu, handleClick, handleClose } = useMenuOption()
  const location = useLocation()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  // ACCOUNTX API CALLS
  const submenuList = [
    {
      path: `/digital-reports/general-ledger/gl-journal-entity-report/parameters`,
      title: `GL Journal By Account`,
      permission: AcctPermission.GeneralLedgerDigitalReportsGlJournalView,
    },
    {
      path: `/digital-reports/general-ledger/gl-journal-costcentre-report/parameters`,
      title: `GL Journal by Department`,
      permission:
        AcctPermission.GeneralLedgerDigitalReportsGlJournalCostCentreView,
    },
    {
      path: `/digital-reports/general-ledger/trial-balance-entity-report/parameters`,
      title: `Trial Balance by Entity`,
      permission: AcctPermission.GeneralLedgerDigitalReportsTrialBalanceView,
    },
    {
      path: `/digital-reports/general-ledger/trial-balance-costcentre-report/parameters`,
      title: `Trial Balance by Department`,
      permission:
        AcctPermission.GeneralLedgerDigitalReportsTrialBalanceCostCentreView,
    },
    {
      path: `/digital-reports/general-ledger/mastercoa-listing-report/parameters`,
      title: `MasterCOA Listing`,
      deployed: process.env.REACT_APP_LOCAL_DB === 'local' ? true : false,
      // permission:
      //   AcctPermission.GeneralLedgerDigitalReportsTrialBalanceCostCentreView,
    },
    {
      path: `/digital-reports/general-ledger/entitycoa-department-listing-report/parameters`,
      title: `Entity COA by Department Listing`,
      // deployed: process.env.REACT_APP_LOCAL_DB === 'local' ? true : false,
      // permission:
      //   AcctPermission.GeneralLedgerDigitalReportsTrialBalanceCostCentreView,
    },
    // {
    //   path: `/general-ledger/${CompanyID}/cash-ledger-listing-report`,
    //   title: `Cash Ledger Listing`,
    // },
  ]

  const handleChange = value => {
    history.push({ pathname: value })
  }

  return (
    <>
      <MainHeader
        mainBtn="back"
        onClick={() => history.push(``)}
        smTitle={'General Ledger'}
        title={user?.companyName}
        routeSegments={[
          { name: 'Main Menu' },
          // { name: 'Submenu' },
          { name: 'Digital Reports', current: true },
        ]}
      />
      <DynamicSubHeader
        title={labelState}
        handleClick={e => {
          handleClick(e)
        }} // onClick function for dropdown
        dropDownOptions={
          // dropdown component place here
          <StyledMenu
            id="customized-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {dropdownOption?.map(x => {
              if (handlePermHidden({ permEnum: x?.permission }))
                return (
                  <StyledMenuItem
                    onClick={e => {
                      setLabelState(x?.label)
                      handleChange(x?.path)
                      handleClose()
                    }}
                    selected={location.pathname.includes(x.path)}
                  >
                    <ListItemText primary={x.title} />
                  </StyledMenuItem>
                )
            })}
          </StyledMenu>
        }
      />
      <ContentWrapper
        float
        style={{
          marginTop: isDesktop ? '100px' : '40px',
          top: isDesktop ? '47px' : null,
        }}
      >
        <List className="core-list">
          {submenuList.map((el, index) => {
            if (
              handlePermHidden({
                // companyID: CompanyID,
                permEnum: el?.permission,
              })
            )
              return (
                <ListItem key={index} onClick={() => history.push(el?.path)}>
                  <ListItemText
                    style={{
                      textAlign: 'right',
                      marginLeft: '5px',
                    }}
                    primary={<span className="xsTitle">{el?.title}</span>}
                  />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete">
                      <KeyboardArrowRight
                        onClick={() =>
                          history.push({
                            pathname: el.path,
                          })
                        }
                      />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              )
          })}
        </List>
      </ContentWrapper>

      {/* FOOTER */}
    </>
  )
}
