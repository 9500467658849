import { yupResolver } from '@hookform/resolvers'
import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { SearchHeader } from '@ifca-root/react-component/src/components/Header/SearchHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
} from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'
import { AccCodeDropdownFullWidth } from 'components/Dropdown/AccCodeDropdown'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  GetBillItemDocument,
  RecordStatus,
  useCreateBillItemMutation,
  useDeleteBillItemMutation,
  useGetClassificationCodeQuery,
  useGetBillItemLazyQuery,
  useGetHomeSummaryQuery,
  useGetMasterCoaForFormQuery,
  useGetTaxSchemeQuery,
  useUpdateBillItemMutation,
  useGetMsicCodeQuery,
} from 'generated/graphql'
import { useMenuOption } from 'helpers/CustomHooks/useMenuOption'
import { useFuseSearch } from 'helpers/Hooks/useSearch'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { CommonYupValidation } from 'helpers/YupSchema/yup'
import React, { useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useHistory, useParams } from 'react-router'
import * as yup from 'yup'

export interface BillItemProps {
  Name: string
  Description: string
  TaxSchemeID: string
  MasterCOAID: string
  TaxRate: number
  einv_integrated: boolean
  einvoice_msic_code_id: string
  einvoice_classification_code_id: string
}

export const BillItemListing = (props: any) => {
  const getSearch = localStorage?.getItem('searchFilter')
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  let history = useHistory()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const { filteredList, handleSearch, setOriginalListing } = useFuseSearch()
  const {
    anchorEl,
    menu,
    handleClick,
    handleClose,
    resetMenu,
  } = useMenuOption()
  const [billItemDia, setBillItemDia] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any
  )
  const { CompanyID }: any = useParams()

  const [TotalLength, setTotalLength] = useState(0)

  const BillItemSchema = yup.object().shape({
    Name: CommonYupValidation.requireField(SystemMsgs.name()),
    Description: CommonYupValidation.requireField(SystemMsgs.description()),
    TaxSchemeID: CommonYupValidation.requireField('Tax Code is required'),
    MasterCOAID: CommonYupValidation.requireField(
      'GL Account Code is required'
    ),
    einvoice_msic_code_id: yup.string().when('einv_integrated', {
      is: true,
      then: yup.string().required(SystemMsgs?.MSICCode()),
      otherwise: null,
    }),
    einvoice_classification_code_id: yup.string().when('einv_integrated', {
      is: true,
      then: yup.string().required(SystemMsgs.ClassificationCode()),
      otherwise: null,
    }),
  })

  const {
    handleSubmit,
    control,
    register,
    setValue,
    watch,
    setError,
    clearErrors,
    errors,
    reset,
  } = useForm<BillItemProps>({
    mode: 'onSubmit',
    resolver: yupResolver(BillItemSchema),
  })
  //.........Query............//
  const {
    loading: SummaryLoading,
    data: {
      loggedInUserProfile,
      getAccountPermission,
      getCompany: curCompany,
      getRolePermission,
    } = {
      loggedInUserProfile: null,
      getAccountPermission: [],
      getCompany: [],
      getRolePermission: [],
    },
  } = useGetHomeSummaryQuery({
    fetchPolicy: 'network-only',
    variables: {
      CompanyID,
    },
  })

  const [
    loadData,
    {
      fetchMore,
      loading: BillItemLoading,
      data: { getBillItem, getBillItemCount } = {
        getBillItem: [],
        getBillItemCount: null,
      },
    },
  ] = useGetBillItemLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ getBillItem, getBillItemCount }) => {
      if (getBillItem?.length > 0) {
        setOriginalListing([...filteredList, ...getBillItem])
      }
      if (!!getBillItemCount) {
        setTotalLength(getBillItemCount)
      }
    },
  })

  useEffect(() => {
    loadData({
      variables: {
        take: 30,
        skip: 0,
      },
    })
  }, [])

  const {
    loading: MasterCOALoading,
    data: { getMasterCOAForForm } = { getMasterCOAForForm: [] },
  } = useGetMasterCoaForFormQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID },
  })

  const {
    loading: TaxLoading,
    data: { getTaxScheme } = { getTaxScheme: [] },
  } = useGetTaxSchemeQuery({
    onCompleted: () => {},
    variables: {
      RecordStatus: RecordStatus.Active,
      AccTaxClass: 'OUTPUT',
      orderByAsc: 'Code',
    },
  })

  //.........Mutation............//

  const [
    createBillItem,
    { loading: CreateLoading },
  ] = useCreateBillItemMutation({
    onCompleted: data => {
      setBillItemDia(false)
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.createNewRecord())
      setOriginalListing([])
      loadData({
        variables: {
          skip: 0,
          take: 30,
        },
      })
    },
  })

  const [
    updateBillItem,
    { loading: UpdateLoading },
  ] = useUpdateBillItemMutation({
    onError: error => {},
    //fetchPolicy: 'network-only',
    onCompleted: data => {
      setBillItemDia(false)
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.updateRecord())
      setOriginalListing([])
      loadData({
        variables: {
          skip: 0,
          take: 30,
        },
      })
    },
  })

  const [
    deleteBillItem,
    { loading: DeleteBillItemLoading },
  ] = useDeleteBillItemMutation({
    onError: error => {
      console.log('error')
    },
    onCompleted: () => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.deleteRecord())
      setOriginalListing([])
      loadData({
        variables: {
          skip: 0,
          take: 30,
        },
      })
    },
  })

  const {
    loading: msicCodeLoading,
    data: { getMSICCode } = { getMSICCode: [] },
  } = useGetMsicCodeQuery({
    fetchPolicy: 'network-only',
  })

  const {
    loading: classificationCodeLoading,
    data: { getClassificationCode } = { getClassificationCode: [] },
  } = useGetClassificationCodeQuery({
    fetchPolicy: 'network-only',
  })

  const [deleteError, setDeleteError] = useState(false)

  const [TaxRate, setTaxRate] = useState(0)

  const [selectedTax, setSelectedTax] = useState(null)

  const taxEffectiveDate: any = new Date(
    new Date().getTime() + 24 * 60 * 60 * 1000
  )
    .toISOString()
    .slice(0, 10)

  const taxSchemeInput: any = getTaxScheme?.filter(
    el =>
      // el?.AccTaxClass === 'OUTPUT' &&
      el?.TaxEffective.map(y => y?.Date) <= taxEffectiveDate &&
      el?.RecordStatus === 'ACTIVE'
  )

  const handleTaxChange = (TaxSchemeID, taxEffectiveDate) => {
    setSelectedTax(taxSchemeInput.find(x => x?.TaxSchemeID === TaxSchemeID))
    setTaxRate(
      taxSchemeInput.filter(x => x?.TaxSchemeID === TaxSchemeID)[0]
        .LatestTax === null
        ? taxSchemeInput
            .filter(x => x?.TaxSchemeID === TaxSchemeID)[0]
            ?.TaxEffective.reduce((a, b) => {
              return new Date(a.Date) > new Date(b.Date) ? a : b
            })?.Rate
        : taxSchemeInput
            .filter(x => x?.TaxSchemeID === TaxSchemeID)[0]
            ?.TaxEffective?.filter(
              x => new Date(x?.Date) <= new Date(taxEffectiveDate)
            )
            .reduce((a, b) => {
              return new Date(a.Date) > new Date(b.Date) ? a : b
            })?.Rate
    )
  }

  const onSubmit = data => {
    isEdit === false
      ? createBillItem({
          variables: {
            input: {
              Name: data.Name,
              Description: data.Description,
              TaxSchemeID: data.TaxSchemeID,
              MasterCOAID: data.MasterCOAID,
              einv_integrated: data.einv_integrated,
              einvoice_msic_code_id: data.einvoice_msic_code_id,
              einvoice_classification_code_id:
                data.einvoice_classification_code_id,
              TaxRate: TaxRate,
            },
          },
          refetchQueries: [
            {
              query: GetBillItemDocument,
              variables: { skip: 0, take: 30 },
            },
          ],
        })
      : updateBillItem({
          variables: {
            input: {
              BillItemID: menu?.ID,
              Name: data.Name,
              Description: data.Description,
              TaxSchemeID: data.TaxSchemeID,
              MasterCOAID: data.MasterCOAID,
              einv_integrated: data.einv_integrated,
              einvoice_msic_code_id: data.einvoice_msic_code_id,
              einvoice_classification_code_id:
                data.einvoice_classification_code_id,
              TaxRate: TaxRate,
            },
          },
          refetchQueries: [
            {
              query: GetBillItemDocument,
              variables: { skip: 0, take: 30 },
            },
          ],
        })
    clearErrors()
  }

  const InactiveReactive = status => {
    updateBillItem({
      variables: {
        input: {
          BillItemID: menu?.ID,
          Name: menu?.obj.Name,
          Description: menu?.obj.Description,
          TaxSchemeID: menu?.obj.TaxSchemeID,
          MasterCOAID: menu?.obj.MasterCOAID,
          einv_integrated: menu?.obj.einv_integrated,
          einvoice_msic_code_id: menu?.obj.einvoice_msic_code_id,
          einvoice_classification_code_id:
            menu?.obj.einvoice_classification_code_id,
          RecordStatus: status,
          TaxRate: TaxRate,
        },
      },
      refetchQueries: [
        {
          query: GetBillItemDocument,
          variables: { skip: 0, take: 30 },
        },
      ],
    })
  }

  const handleDelete = () => {
    deleteBillItem({
      variables: { input: { BillItemID: menu?.obj?.BillItemID } },
      refetchQueries: [
        {
          query: GetBillItemDocument,
          variables: { skip: 0, take: 30 },
        },
      ],
    })
    setOpenDeleteDialog(false)
  }

  //to make delay for search function for the query to load

  const [timer, setTimer] = useState(null)
  function changeDelay(change) {
    if (timer) {
      clearTimeout(timer)
      setTimer(null)
    }

    setTimer(
      setTimeout(() => {
        setOriginalListing([])
        loadData({
          variables: {
            //  CompanyID: CompanyID,
            skip: 0,
            take: 30,
            searchValue: change,
          },
        })
      }, 1000)
    )
  }

  return (
    <>
      {TaxLoading && <Loading />}
      {CreateLoading && <Loading />}
      {UpdateLoading && <Loading />}
      {SummaryLoading && <Loading />}
      {BillItemLoading && <Loading />}
      {MasterCOALoading && <Loading />}
      {DeleteBillItemLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() => history.push(`/account-receivable/general-setting`)} //<- back button action
        smTitle={'Account Receivable'}
        title={user?.accountName}
        routeSegments={[{ name: 'AR' }, { name: 'Bill Items', current: true }]}
      />

      <div className="search-filter with-dropdown-filter ">
        <SearchHeader
          title="Bill Items"
          value={`${TotalLength}`}
          search
          onChangeAction={e => {
            // handleSearch(e?.target?.value, ['Name', 'Description'])
            localStorage.setItem('searchFilter', e.target.value)
            changeDelay(e.target.value)
          }}
          onCloseAction={() => {
            handleSearch('', [])
            localStorage.removeItem('searchFilter')
            setOriginalListing([])
            loadData({
              variables: {
                skip: 0,
                take: 30,
              },
            })
          }}
        />
      </div>

      <ContentWrapper footer overflow>
        <List className="core-list">
          {filteredList === undefined || filteredList?.length === 0 ? (
            <EmptyList
              title="No Record found"
              subtitle="Add a new record now."
            />
          ) : (
            <InfiniteScroll
              dataLength={filteredList?.length}
              next={() => {
                const currentLength = filteredList?.length
                loadData({
                  variables: {
                    // CompanyID: CompanyID,
                    skip: currentLength,
                    take: 30,
                    searchValue: getSearch === '' ? undefined : getSearch,
                  },
                })
              }}
              hasMore={true}
              className=""
              style={{ padding: '8px 6px' }}
              loader={
                BillItemLoading && (
                  <div style={{ textAlign: 'center' }}>
                    {' '}
                    <CircularProgress />{' '}
                  </div>
                )
              }
              endMessage={
                <p style={{ textAlign: 'center' }}>
                  <b>-</b>
                </p>
              }
            >
              {filteredList
                .sort((a, b) => {
                  return (
                    Object.values(RecordStatus).indexOf(a?.RecordStatus) -
                    Object.values(RecordStatus).indexOf(b?.RecordStatus)
                  )
                })
                ?.map((el, index) => {
                  return (
                    <ListItem selected={el.RecordStatus === 'INACTIVE'}>
                      <ListItemText
                        primary={
                          <>
                            <span className="xsTitle flex-space">
                              {el?.Name}{' '}
                            </span>
                          </>
                        }
                        secondary={
                          <>
                            <Grid container>
                              <Grid item xs={12}>
                                <span className="desc ">
                                  {el?.MasterCOA?.Code} | {el?.MasterCOA?.Name}
                                </span>
                              </Grid>
                              <Grid item xs={12}>
                                <span className="desc">
                                  {el?.TaxScheme?.Code}{' '}
                                  {el?.TaxScheme?.LatestTax?.Rate}%
                                  {!!el?.einv_integrated
                                    ? ` | ${
                                        getMSICCode?.find(
                                          x =>
                                            x?.id === el?.einvoice_msic_code_id
                                        )?.description
                                      } (${
                                        getMSICCode?.find(
                                          x =>
                                            x?.id === el?.einvoice_msic_code_id
                                        )?.msicCode
                                      }) | ${
                                        getClassificationCode?.find(
                                          x =>
                                            x?.id ===
                                            el?.einvoice_classification_code_id
                                        )?.description
                                      } (${
                                        getClassificationCode?.find(
                                          x =>
                                            x?.id ===
                                            el?.einvoice_classification_code_id
                                        )?.classificationCode
                                      })`
                                    : null}
                                </span>
                              </Grid>
                            </Grid>
                          </>
                        }
                      />
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          aria-label="more"
                          aria-controls="menu-list"
                          aria-haspopup="true"
                          onClick={e => {
                            handleClick(e, el.BillItemID, index, el)
                            register('Name')
                            register('Description')
                            register('MasterCOAID')
                            register('TaxSchemeID')
                            register('einv_integrated')
                            register('einvoice_classification_code_id')
                            register('einvoice_msic_code_id')
                            setValue('Name', el?.Name)
                            setValue('Description', el?.Description)
                            setValue('MasterCOAID', el?.MasterCOAID)
                            setValue('TaxSchemeID', el?.TaxSchemeID)
                            setValue('einv_integrated', el?.einv_integrated)
                            setValue(
                              'einvoice_classification_code_id',
                              el?.einvoice_classification_code_id
                            )
                            setValue(
                              'einvoice_msic_code_id',
                              el?.einvoice_msic_code_id
                            )
                          }}
                        >
                          <MoreVert />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  )
                })}
            </InfiniteScroll>
          )}
        </List>
        <FloatButton
          onClick={() => {
            setIsEdit(false)
            resetMenu()
            setBillItemDia(true)
          }}
        />
      </ContentWrapper>
      <Menu
        id="menu-list"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
      >
        <MenuItem
          onClick={() => {
            setIsEdit(true)
            setBillItemDia(true)
          }}
        >
          Edit
        </MenuItem>
        {menu?.obj?.RecordStatus === RecordStatus.Active ? (
          <MenuItem onClick={() => InactiveReactive(RecordStatus.Inactive)}>
            Inactive
          </MenuItem>
        ) : (
          <MenuItem onClick={() => InactiveReactive(RecordStatus.Active)}>
            Reactive
          </MenuItem>
        )}

        <MenuItem
          onClick={() => setOpenDeleteDialog(true)}
          disabled={menu?.obj?.IsUsed ? true : false}
        >
          <span className="">Delete</span>
        </MenuItem>
      </Menu>
      <Box mt={1}>
        <CommonDialog
          fullWidth={true}
          open={billItemDia}
          onClose={() => {
            reset()
            setBillItemDia(false)
          }}
          sections={{
            header: {
              dynamic: (
                <div className="">
                  <div className="dialog-dynamic-content">
                    <div className="session">
                      <div className="flex session">
                        <div
                          className="dialog-title flex-space"
                          style={{
                            fontSize: '12px',
                            fontWeight: 'bold',
                            color: 'orange',
                          }}
                        >
                          {'Bill Item'}
                        </div>
                        {isEdit === false ? (
                          <div
                            className="dialog-title right-text"
                            style={{
                              fontSize: '12px',
                              fontWeight: 'bold',
                              color: 'orange',
                            }}
                          >
                            {'New'}
                          </div>
                        ) : (
                          <div
                            className="dialog-title right-text"
                            style={{
                              fontSize: '12px',
                              fontWeight: 'bold',
                              color: 'orange',
                            }}
                          >
                            {'Edit'}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ),
            },
            body: () => (
              <div className="content-container">
                <Controller
                  as={TextField}
                  name="Name"
                  required
                  label="Name"
                  control={control}
                  ref={register}
                  defaultValue={menu?.obj?.Name}
                  helperText={errors?.Name?.message}
                  error={errors?.Name ? true : false}
                />
                <Controller
                  as={TextField}
                  name="Description"
                  required
                  label="Description"
                  control={control}
                  ref={register}
                  defaultValue={menu?.obj?.Description}
                  helperText={errors?.Description?.message}
                  error={errors?.Description ? true : false}
                />

                <Controller
                  control={control}
                  name="TaxSchemeID"
                  ref={register()}
                  defaultValue={menu?.obj?.TaxSchemeID}
                  onChange={({ value, newValue }) => {
                    return newValue?.TaxSchemeID
                  }}
                  helperText={errors?.TaxSchemeID?.message}
                  error={errors?.TaxSchemeID ? true : false}
                  render={({ onChange, onBlur, value }) => {
                    const defVal = getTaxScheme?.filter(
                      x => x?.TaxSchemeID === menu?.obj?.TaxSchemeID
                    )[0]
                    return (
                      //  {
                      // return
                      <Autocomplete
                        className="mat-new"
                        defaultValue={defVal}
                        options={
                          getTaxScheme?.sort((a, b) => {
                            return a.Code.localeCompare(b.Code)
                          }) || []
                        }
                        getOptionLabel={option =>
                          `${option?.Code} ${option?.LatestTax?.Rate}%`
                        }
                        fullWidth
                        renderOption={option => (
                          <div>
                            <div>{`${option?.Code} ${option?.LatestTax?.Rate}%`}</div>
                          </div>
                        )}
                        onChange={(value, newValue: any) => {
                          handleTaxChange(
                            newValue?.TaxSchemeID,
                            taxEffectiveDate
                          )
                          onChange(newValue?.TaxSchemeID)
                        }}
                        PopperComponent={AccCodeDropdownFullWidth}
                        renderInput={(params: any) => {
                          return (
                            <TextField
                              {...params}
                              InputLabelProps={{ shrink: true }}
                              label="Tax Code"
                              helperText={errors?.TaxSchemeID?.message}
                              error={errors?.TaxSchemeID ? true : false}
                              required
                            />
                          )
                        }}
                      />
                    )
                  }}
                  // }
                />

                <Controller
                  control={control}
                  name="MasterCOAID"
                  ref={register()}
                  defaultValue={menu?.obj?.MasterCOAID}
                  onChange={({ value, newValue }) => {
                    return newValue?.MasterCOAID
                  }}
                  helperText={errors?.MasterCOAID?.message}
                  error={errors?.MasterCOAID ? true : false}
                  render={({ onChange, onBlur, value }) => {
                    const defVal = getMasterCOAForForm?.filter(
                      x => x?.MasterCOAID === menu?.obj?.MasterCOAID
                    )[0]

                    return (
                      <Autocomplete
                        className="mat-new"
                        defaultValue={defVal}
                        options={
                          getMasterCOAForForm?.sort((a, b) => {
                            return a.Code.localeCompare(b.Code)
                          }) || []
                        }
                        getOptionLabel={option =>
                          `${option?.Code} | ${option?.Name}`
                        }
                        fullWidth
                        renderOption={(props, option) => (
                          <div>
                            <div>
                              <span className="xsTitle">{props?.Code}</span>
                            </div>
                            <div className="desc">{props?.Name}</div>
                          </div>
                        )}
                        onChange={(value, newValue: any) => {
                          onChange(newValue?.MasterCOAID)
                        }}
                        PopperComponent={AccCodeDropdownFullWidth}
                        renderInput={(params: any) => {
                          return (
                            <TextField
                              {...params}
                              InputLabelProps={{ shrink: true }}
                              label="GL Account  Code"
                              //defaultValue={defVal?.Code}
                              helperText={errors?.MasterCOAID?.message}
                              error={errors?.MasterCOAID ? true : false}
                              required
                            />
                          )
                        }}
                      />
                    )
                  }}
                  // }
                />
              </div>
            ),
            footer: {
              actions: [
                {
                  displayText: 'Cancel',
                  props: {
                    onClick: () => {
                      reset()
                      setBillItemDia(false)
                    },
                    variant: 'contained',
                    color: 'primary',
                  },
                },
                {
                  displayText: 'Confirm',
                  props: {
                    onClick: () => {
                      handleSubmit(onSubmit)()
                    },
                    variant: 'contained',
                    color: 'primary',
                    form: 'createUOM-form',
                    type: 'submit',
                  },
                },
              ],
            },
          }}
        />
      </Box>
      <CommonDialog
        fullWidth={true}
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        sections={{
          header: {
            title: `Item`,
            rightText: 'Delete',
            infoLine: menu?.obj?.Name,
          },

          body: () => (
            <div className="content-wrap full">
              <span className="mdDesc full">Confirm to delete?</span>
            </div>
          ),
          footer: {
            actions: [
              {
                displayText: 'Cancel',
                props: {
                  onClick: () => setOpenDeleteDialog(false),
                  variant: 'contained',
                  color: 'primary',
                },
              },
              {
                displayText: 'Confirm',
                props: {
                  onClick: () => handleDelete(),
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />

      <CommonDialog
        fullWidth={true}
        open={deleteError}
        onClose={() => setDeleteError(false)}
        sections={{
          header: {
            children: (
              <ListItem className="remove-padding">
                <ListItemText
                  primary={
                    <>
                      <span
                        className="xsTitle flex-space"
                        style={{ color: 'red' }}
                      >
                        Error!
                      </span>
                    </>
                  }
                />
              </ListItem>
            ),
          },
          body: () => (
            <div>
              <span className="text-noflow mdDesc">{`You are not allowed to delete this bill item as it's already been used`}</span>
            </div>
          ),
          footer: {
            actions: [
              {
                displayText: 'Close',
                props: {
                  onClick: () => {
                    setDeleteError(false)
                  },
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />
    </>
  )
}
