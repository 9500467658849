import theme from '@ifca-root/react-component/src/assets/theme'
import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import IconText from '@ifca-root/react-component/src/components/Typography/IconText'
import {
  Avatar,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  useMediaQuery,
  withStyles,
} from '@material-ui/core'
import { KeyboardArrowRight, MoreVert } from '@material-ui/icons'
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks'
import PersonIcon from '@material-ui/icons/Person'
import SubmitterLog from 'assets/icons/GeneralLedger/submitter-log.svg'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'
import { DeleteDialog } from 'components/Dialog/DeleteDialog'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  AcctPermission,
  useDeleteBatchPaymentMutation,
} from 'generated/graphql'
import { useMenuOption } from 'helpers/Hooks/useMenuOption'
import { usePermissionChecker } from 'helpers/Hooks/usePermissionChecker'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { formatDate, formatTime } from 'helpers/StringNumberFunction/FormatDate'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import React, { useContext, useState } from 'react'
import { useHistory, useParams } from 'react-router'

export const BatchPaymentEntryList = (props: any) => {
  const { CompanyID }: any = useParams()
  const { filteredList, listLoading, listStatus, userList, fetchMore } = props
  const { anchorEl, menu, handleClick, handleClose } = useMenuOption()
  const [errorDia, setErrorDia] = useState<boolean>(false)
  const [errMsg, setErrMsg] = useState<string>('')

  let history = useHistory()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const GreyTooltip = withStyles({
    tooltip: { color: 'white', backgroundColor: '#9e9e9e' },
  })(Tooltip)
  const { setOpenSnackBar, setSnackBarMsg } = useContext(SnackBarContext) as any

  /** This is for permission purposes */
  const { handlePermDisabled } = usePermissionChecker()
  /**ACL */

  const [deleteBP, setDeleteBP] = useState(false)
  const [deleteError, setDeleteErrorPayment] = useState(false)

  const [
    deleteAPPayment,
    { loading: deleteAPPaymentLoading, error: deleteAPPaymentError },
  ] = useDeleteBatchPaymentMutation({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    onCompleted: () => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.deleteRecord())
      fetchMore({ variables: { CompanyID: CompanyID, StatusArr: 'ACTIVE' } })
    },
  })

  const handleDelete = data => {
    deleteAPPayment({ variables: { BatchPaymentID: data } })
    setDeleteBP(false)
  }
  /* DELETION ENDS*/

  return (
    <>
      {deleteAPPaymentLoading && <Loading />}
      <ContentWrapper advSearch float>
        <List className="core-list">
          {filteredList === undefined || filteredList?.length === 0 ? (
            <EmptyList
              title="No Record found"
              subtitle="Add a new record now."
            />
          ) : (
            filteredList?.map((el, index) => {
              return (
                <ListItem>
                  <ListItemText
                    primary={
                      <>
                        <span
                          className="flex-space mdLabel"
                          style={{ fontSize: '12px' }}
                        >
                          {el?.RefNo}
                        </span>
                        <LibraryBooksIcon
                          style={{
                            fontSize: 'small',
                            width: '20px',
                            marginLeft: '5px',
                          }}
                        />
                        <span
                          className="flex-space mdLabel c-orange"
                          style={{ fontSize: '12px' }}
                        >
                          {el?.APPayment?.filter(x => x?.DocAmt !== 0)?.length}
                        </span>
                        <span
                          className="desc"
                          style={{
                            fontSize: '12px',
                            color: listStatus === 'ACTIVE' ? '#FF0000' : null,
                          }}
                        >
                          {formatDate(el?.DocDate)}
                        </span>
                      </>
                    }
                    secondary={
                      <>
                        <span
                          className="desc"
                          style={{
                            whiteSpace: 'break-spaces',
                            lineBreak: 'auto',
                          }}
                        >
                          <GreyTooltip
                            disableFocusListener
                            title={
                              <React.Fragment>
                                <Grid container wrap="nowrap" spacing={2}>
                                  <Grid item>
                                    <Avatar
                                      style={{
                                        width: '20px',
                                        height: '20px',
                                      }}
                                    >
                                      <PersonIcon
                                        style={{ fontSize: 'small' }}
                                      />
                                    </Avatar>
                                  </Grid>
                                  <Grid item xs zeroMinWidth>
                                    <div style={{ fontSize: '12px' }}>
                                      {listStatus === 'COMPLETED'
                                        ? userList?.find(
                                            x => x?.ID === el?.approvedBy
                                          )?.name
                                        : listStatus === 'SUBMIT'
                                        ? userList?.find(
                                            x => x?.ID === el?.submittedBy
                                          )?.name
                                        : userList?.find(
                                            x =>
                                              x?.ID === el?.modBy ??
                                              el?.createdBy
                                          )?.name}
                                    </div>
                                    <div style={{ fontSize: '10px' }}>
                                      {formatDate(
                                        el?.approvedTs ??
                                          el?.modTs ??
                                          el?.submttedTs ??
                                          el?.createdTs
                                      )}
                                      &nbsp;
                                      {formatTime(
                                        el?.approvedTs ??
                                          el?.modTs ??
                                          el?.submttedTs ??
                                          el?.createdTs
                                      )}
                                    </div>
                                  </Grid>
                                </Grid>
                              </React.Fragment>
                            }
                            arrow
                            enterTouchDelay={0}
                          >
                            <IconText
                              icon={
                                <img
                                  src={SubmitterLog}
                                  style={{
                                    width: '12px',
                                    marginRight: '3px',
                                  }}
                                />
                              }
                              font="desc"
                            />
                          </GreyTooltip>
                        </span>
                        <span
                          className="desc flex-space text-overflow"
                          style={{ marginBottom: '-5px' }}
                        >
                          {el?.Description}
                        </span>
                        <span className="xxTitle">
                          <span className="c-orange">
                            <span style={{ fontSize: '12px' }}>
                              {amtStr(el?.DocAmt)}
                            </span>
                          </span>
                        </span>
                      </>
                    }
                  />

                  <ListItemSecondaryAction>
                    {listStatus === 'ACTIVE' ? (
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        aria-controls="menu-list"
                        aria-haspopup="true"
                        onClick={e =>
                          handleClick(e, el?.BatchPaymentID, index, el)
                        }
                      >
                        <MoreVert />
                      </IconButton>
                    ) : (
                      <IconButton edge="end" aria-label="arrow">
                        <KeyboardArrowRight
                          onClick={() =>
                            history.push({
                              pathname:
                                listStatus === 'SUBMIT'
                                  ? `/account-payable/${CompanyID}/batch-payment/${el?.BatchPaymentID}/submit`
                                  : `/account-payable/${CompanyID}/batch-payment/${el?.BatchPaymentID}/detail`,
                              state: el,
                            })
                          }
                        />
                      </IconButton>
                    )}
                  </ListItemSecondaryAction>
                </ListItem>
              )
            })
          )}
        </List>
        {listStatus === 'ACTIVE' && (
          <FloatButton
            disabled={handlePermDisabled({
              companyID: CompanyID,
              permEnum: AcctPermission.AccPayableBatchPaymentCreate,
            })}
            onClick={() => {
              history.push(`/account-payable/${CompanyID}/batch-payment/add`)
              localStorage.removeItem('searchFilter')
            }}
          />
        )}
      </ContentWrapper>

      <Menu
        id="menu-list"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
      >
        <MenuItem
          disabled={handlePermDisabled({
            companyID: CompanyID,
            permEnum: AcctPermission.AccPayableBatchPaymentUpdate,
          })}
          onClick={() => {
            history.push({
              pathname: `/account-payable/${CompanyID}/batch-payment/${menu?.obj?.BatchPaymentID}/edit`,
              state: menu?.obj,
            })
          }}
        >
          <span className="">Edit</span>
        </MenuItem>
        <MenuItem
          disabled={handlePermDisabled({
            companyID: CompanyID,
            permEnum: AcctPermission.AccPayableBatchPaymentDelete,
          })}
          onClick={() => setDeleteBP(true)}
        >
          <span className="">Delete</span>
        </MenuItem>
      </Menu>

      <DeleteDialog
        ID={menu?.ID}
        openDeleteDialog={deleteBP}
        setOpenDeleteDialog={setDeleteBP}
        title={'Batch Payment'}
        menu={menu?.obj}
        handleDelete={handleDelete}
        passID
      />

      <ErrorDialog
        errorDia={errorDia}
        setErrorDia={setErrorDia}
        errorMsg={errMsg}
        errorHeaderMsg={'Error!'}
      />
    </>
  )
}
