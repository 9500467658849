import DateFnsUtils from '@date-io/date-fns'
import { yupResolver } from '@hookform/resolvers'
import { UploadPreview } from '@ifca-root/react-component/src/components/Avatar/UploadPreview'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { VoiceTextField } from '@ifca-root/react-component/src/components/Input/CustomTextField'
import { FileUploadInput } from '@ifca-root/react-component/src/components/Input/FileUploadInput'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import useUploadAttachment from '@ifca-root/react-component/src/utils/hooks/useUploadAttachment'
import { Menu, MenuItem, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { ExitConfirmationDialog } from 'components/Dialog/ExitConfirmationDialog'
import { TooltipAmountFooter } from 'components/Footer/TooltipAmountFooter'
import { IsEinvoiceSelfBilled } from 'containers/APModule/EInvoiceComponent/SelfBilledComponent'
import { useAPCreateUpdateMutation } from 'containers/APModule/Hooks/useAPCreateUpdateMutation'
import { ItemDialog } from 'containers/APModule/components/ItemDialog'
import { ItemDetail } from 'containers/ARAPModule/Components/Detail/ItemDetail'
import {
  findDuplicates,
  statusInput,
} from 'containers/ARAPModule/Function/DocumentChecker'
import { dueDateChecker } from 'containers/ARAPModule/Helper/DateCalculation'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  DocDateValidationAfter,
  DocDateValidationBefore,
} from 'containers/CashBookModule/DocDateValidation'
import {
  AcctPermission,
  RecordStatus,
  useDocumentListingLazyQuery,
  useGetCompanyNameQuery,
  useGetCostCentreLazyQuery,
  useGetCreditorAccountCompanyAssignmentNameQuery,
  useGetDocNumTitleQuery,
  useGetDocumentDateValidationQuery,
  useGetEInvoiceCompanyStartLazyQuery,
  useGetExpenseItemLazyQuery,
  useGetMasterCoaCheckingQuery,
  useGetMsicCodeLazyQuery,
  useGetSelfBilledInvoiceQuery,
  useGetTaxEffectiveDateQuery,
  useGetTaxSchemeQuery,
  useLatestOpenPeriodCheckingDateQuery,
} from 'generated/graphql'
import { handleExitConfirmation } from 'helpers/Form/ExitConfirmation'
import { CommonYupValidation } from 'helpers/Form/YupValidation'
import { useMenuOption } from 'helpers/Hooks/useMenuOption'
import { usePermissionChecker } from 'helpers/Hooks/usePermissionChecker'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { formatDashDate } from 'helpers/StringNumberFunction/FormatDate'
import React, { useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import * as yup from 'yup'

interface DebitNoteProps {
  DocDate: string
  TrxDate: string
  DocAmt: number
  Remark: string
  Description: string
  approval: string
  user: string
  CreditorAccountID: string
  CreditDebitID: string
  ExpenseItemID: string
  RefNo: string
  DocRef: string
  UIN: string
  isSelfBilled: boolean
  MSICCodeID: string
  DocRefID: string
  CompanyID: string
  ID: string
  TaxRate: number
  TaxSchemeID: string
  DocNo: string
  TrxType: string
}

export const APDebitNoteStateForm = (props: any) => {
  const { formMode, docType } = props
  const { CompanyID, ID }: any = useParams()
  const [taxEffectiveList, setTaxEffectiveList] = useState<any>()
  const [detailMode, setDetailMode] = useState('')

  /* -------------------------------------------- */
  /*                   VARIABLES                  */
  /* -------------------------------------------- */

  let form, mode
  switch (formMode) {
    case 'add':
      form = 'New'
      mode = 'add'
      break
    case 'edit':
      form = 'Draft'
      mode = 'edit'
      break
    case 'approve-reject':
      form = 'Approve'
      mode = 'approve-reject'
      break
    case 'resubmit':
      form = 'Resubmit'
      mode = 'edit'
      break
  }

  let history = useHistory()
  let location = useLocation()
  const editData = location?.state as any

  /* -------------------------------------------- */
  /*                     STATE                    */
  /* -------------------------------------------- */
  const [selectedTax, setSelectedTax] = useState(null)

  const { setOpenSnackBar, setSnackBarMsg } = useContext(SnackBarContext) as any

  const [openItemDialog, setOpenItemDialog] = useState<boolean>(false)
  const [openExitConf, setOpenExitConf] = useState(null)
  const [voiceRemark, setVoiceRemark] = useState('')

  const [initDocs, setInitDocs] = useState([])
  useEffect(() => {
    if (editData?.Attachment) {
      setInitDocs(editData?.Attachment)
    }
  }, [editData])
  const [debitNoteItemData, setDebitNoteItemData] = useState([])
  const [record, setRecord] = useState(false)
  const [docNoChecker, setDocNoChecker] = useState(false)
  //this is function to call einvoice
  //e-invoice setup for transaction
  const [isSelfBilled, setIsSelfBilled] = useState(
    editData ? editData?.einvoice_self_billed : false
  )
  const [isEinvoice, setIsEinvoice] = useState<boolean>(false)
  const [msicCodeID, setMsicCodeID] = useState(
    editData ? editData?.einvoice_msic_code_id : null
  )

  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  //For Default Department
  const [defaultCostCentre, loadDefaultCostCentre] = useState(null)
  const [isSubmit, setIsSubmit] = useState(false)

  /* -------------------------------------------- */
  /*                   USE FORM                   */
  /* -------------------------------------------- */
  // checking the document number setup
  let refTable,
    routeSegments,
    itemRefTable,
    itemRefTableKey,
    title,
    selfBilledChecker
  switch (docType) {
    case 'debit-note':
      routeSegments = 'Debit Note'
      refTable = 'AP_DebitNote'
      itemRefTable = 'APDebitNoteItem'
      itemRefTableKey = 'DebitNoteItemID'
      title = 'Debit Note'
      selfBilledChecker = isSelfBilled
      break
    case 'creditor-debit-note':
      routeSegments = 'Debit Note from Creditor'
      refTable = 'AP_DebitNote'
      itemRefTable = 'APCreditorDebitNoteItem'
      itemRefTableKey = 'CreditorDebitNoteItemID'
      title = 'Debit Note'
      selfBilledChecker = isSelfBilled
      break

    default:
      break
  }
  const {
    loading: docNumHeaderLoading,
    error: docNumHeaderError,
    data: { getDocumentNumberHeader } = { getDocumentNumberHeader: [] },
  } = useGetDocNumTitleQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID: CompanyID, RefTable: refTable },
    onCompleted: ({ getDocumentNumberHeader }) => {
      if (
        (getDocumentNumberHeader?.length > 0 &&
          getDocumentNumberHeader?.find(x => x?.IsAutoDocNo === true)) ||
        getDocumentNumberHeader?.find(x => x?.IsAutoDocNo === true)
      ) {
        setDocNoChecker(true)
      }
    },
  })

  const {
    loading: openPeriodCheckDateLoading,
    data: { latestOpenPeriodCheckingDate } = {
      latestOpenPeriodCheckingDate: {} as any,
    },
  } = useLatestOpenPeriodCheckingDateQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID },
  })

  const FormSchema = yup.object().shape({
    CreditorAccountID: CommonYupValidation.requireField(
      'Creditor Name is required'
    ),
    DocDate: yup.string().required('Doc Date is Required'),
    TrxDate: yup.string().required('Transaction Date is Required'),
    Description: CommonYupValidation.requireField(SystemMsgs.description()),
    RefNo: yup.string().required('Reference No is Required'),
    ...(docNoChecker === false || docType === 'creditor-debit-note'
      ? { DocNo: yup.string().required('Document No. is Required') }
      : null),
    ...(isEinvoice && selfBilledChecker && docType !== 'self-billed'
      ? { DocRefID: yup.string().required('Document Ref. is Required') }
      : null),
    ...(isSelfBilled
      ? {
          MSICCodeID: yup.string().when('isSelfBilled'.toString(), {
            is: true,
            then: yup.string().required(SystemMsgs.MSICCode()),
            otherwise: null,
          }),
        }
      : null),
  })

  const {
    handleSubmit,
    register,
    setValue,
    control,
    errors,
    reset,
    setError,
    getValues,
    formState,
    watch,
    clearErrors,
    unregister,
  } = useForm<DebitNoteProps>({
    defaultValues: {
      RefNo: editData ? editData?.RefNo : '',
      DocDate: editData ? editData?.DocDate : new Date(),
      Remark: editData ? editData?.Remark : '',
      Description: editData ? editData?.Description : '',
      CreditorAccountID: editData ? editData?.CreditorAccountID : null,
      UIN: editData ? editData?.einvoice_unique_identification_no : '',
      MSICCodeID: editData ? editData?.einvoice_msic_code_id : null,
      DocRefID: editData ? editData?.einvoice_doc_ref_invoice_id : null,
      isSelfBilled: editData
        ? editData?.einvoice_self_billed
        : !editData?.einvoice_self_billed,
    },
    mode: 'onSubmit',
    resolver: yupResolver(FormSchema),
  })

  const {
    anchorEl,
    menu,
    handleClick,
    handleClose,
    resetMenu,
  } = useMenuOption()

  // Upload Document
  const {
    files,
    setFiles,
    setPreviewFiles,
    handleUploadChange,
    previewFiles,
    remove: removeFile,
    handleEditUpload,
  } = useUploadAttachment()

  /* -------------------------------------------- */
  /*                     QUERY                    */
  /* -------------------------------------------- */
  let docDate = watch('DocDate') ? new Date(watch('DocDate')) : null

  // Company Name
  const {
    loading: companyLoading,
    error: companyError,
    data: { getCompany: curCompany } = { getCompany: [] },
  } = useGetCompanyNameQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID: CompanyID, RecordStatus: RecordStatus.Active },
    onCompleted: ({ getCompany }) => {
      loadDefaultCostCentre(getCompany[0]?.DefaultCostCentre)
      fetchEinvComp({
        variables: {
          CompanyID: CompanyID,
          RecordStatus: RecordStatus.Active,
          DocDate: !!watch('DocDate') ? docDate : new Date(),
        },
      })
    },
  })

  const [
    fetchEinvComp,
    {
      loading: EinvCompStartLoading,
      error: EinvCompStartError,
      data: { getCompany: curEinvCompStart } = { getCompany: [] },
    },
  ] = useGetEInvoiceCompanyStartLazyQuery({
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
      RecordStatus: RecordStatus.Active,
      DocDate: !!watch('DocDate') ? docDate : new Date(),
    },
    onCompleted: ({ getCompany }) => {
      setIsEinvoice(curEinvCompStart[0]?.EInvoiceStarted)
      setStartDate(curEinvCompStart[0]?.eInvStartDate)
      setEndDate(curEinvCompStart[0]?.eInvEndDate)
      if (!editData) {
        setIsSelfBilled(curEinvCompStart[0]?.EInvoiceStarted)
      }
    },
  })

  const {
    loading: masterCOALoading,
    error: masterCOAError,
    data: { getMasterCOA } = { getMasterCOA: [] },
  } = useGetMasterCoaCheckingQuery({
    fetchPolicy: 'network-only',
    variables: { IsLastNode: true, orderByAsc: 'Name' },
  })

  // Tax
  const {
    loading: taxLoading,
    error: taxError,
    data: { getTaxScheme } = { getTaxScheme: [] },
  } = useGetTaxSchemeQuery({
    fetchPolicy: 'network-only',
  })

  const {
    loading: docDateValidationLoading,
    error: docDateValidationError,
    data: { getDocumentDateValidation } = { getDocumentDateValidation: [] },
  } = useGetDocumentDateValidationQuery({
    fetchPolicy: 'network-only',
  })

  // Creditor Account Company Assignment//
  const {
    loading: credAcctCompAssgtLoading,
    error: credAcctCompAssgtError,
    data: { getCreditorAccountCompanyAssignment } = {
      getCreditorAccountCompanyAssignment: [],
    },
  } = useGetCreditorAccountCompanyAssignmentNameQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID: CompanyID },
  })

  let creditorAcc

  if (docType !== 'self-billed') {
    creditorAcc = getCreditorAccountCompanyAssignment?.map(x => {
      return x?.CreditorAccount
    })
  } else {
    creditorAcc = getCreditorAccountCompanyAssignment
      ?.filter(x => x?.CreditorAccount?.einvoice_self_billed)
      ?.map(y => {
        return y?.CreditorAccount
      })
  }

  let selectedCreditor = creditorAcc?.filter(
    x => x?.CreditorAccountID === watch('CreditorAccountID')
  )

  const [term, setTerm] = useState(
    mode === 'edit' ? editData?.CreditorAccount?.CreditTerm : 0
  )

  //e-invoice setup for transaction

  let defaultMsicCodeID = creditorAcc?.find(
    x => x?.CreditorAccountID === watch('CreditorAccountID')
  )?.einvoice_msic_code_id

  const [
    fetchMsicCode,
    { loading: msicCodeLoading, data: { getMSICCode } = { getMSICCode: [] } },
  ] = useGetMsicCodeLazyQuery({
    fetchPolicy: 'network-only',
  })

  const {
    loading: selfBilledInvLoading,
    data: { getSelfBilledInvoice } = { getSelfBilledInvoice: [] },
  } = useGetSelfBilledInvoiceQuery({
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
      CreditorAccountID: !!watch('CreditorAccountID')
        ? watch('CreditorAccountID')
        : null,
    },
  })

  //CostCentre
  const [
    fetchCostCentre,
    {
      loading: CostCentreLoading,
      error: costCentreErrors,
      data: { getCostCentre } = { getCostCentre: [] },
    },
  ] = useGetCostCentreLazyQuery({
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
      orderByAsc: 'CostCentreID',
      IsLastNode: true,
    },
  })

  // Item
  const [
    fetchExpenseItem,
    {
      loading: ExpenseItemLoading,
      error: ExpenseItemError,
      data: { getItemByCompany } = { getItemByCompany: [] },
    },
  ] = useGetExpenseItemLazyQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID },
  })

  const {
    loading: taxEffectiveDateLoading,
    error: taxEffectiveDateError,
    data: { getTaxEffectiveDate } = { getTaxEffectiveDate: [] },
  } = useGetTaxEffectiveDateQuery({
    fetchPolicy: 'network-only',
    onCompleted: () => {
      if (getTaxEffectiveDate?.length > 0) {
        let temp = getTaxEffectiveDate?.filter(
          tax =>
            Number(new Date(tax?.Date)) <
              DocDateValidationAfter(
                getDocumentDateValidation?.map(x => x?.MonthsAfter)
              ) &&
            Number(new Date(tax?.Date)) >
              DocDateValidationBefore(
                getDocumentDateValidation?.map(x => x?.MonthsBefore)
              )
        )
        setTaxEffectiveList(temp)
      }
    },
  })

  const [
    fetchDocuments,
    { loading: documentLoading, error: DocError, data: DocData },
  ] = useDocumentListingLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ DocumentListing }) => {
      // only applies when first opening edit form, when editData still does not have "files" but only Attachment from field resolver
      if (!editData?.files) {
        handleEditUpload(
          DocumentListing?.filter(doc => doc?.description !== 'document_pdf')
        )
        previewFiles?.push(
          ...DocumentListing?.filter(
            doc => doc?.description !== 'document_pdf'
          )?.map(x => x?.fileURL)
        )
      }
    },
  })

  // only applies when first opening edit form, when editData still does not have "files" but only Attachment from field resolver
  // call fetchDocuments to get existing attachment from db
  useEffect(() => {
    if (
      formMode === 'edit' ||
      formMode === 'approve-reject' ||
      formMode === 'resubmit'
    )
      if (!!!editData?.files) {
        fetchDocuments({
          variables: {
            refID: ID,
          },
        })
      }
  }, [formMode])

  // applies after navigating out from item form, where "Attachments" is passed as "files" in location.state
  useEffect(() => {
    //to fetch msic code
    fetchMsicCode()
    if (editData?.files) {
      setFiles(editData?.files)
      setPreviewFiles(
        editData?.files?.map(file => {
          return URL.createObjectURL(file)
        })
      )
    }
  }, [editData])

  /* -------------------------------------------- */
  /*                  MUTATION                    */
  /* -------------------------------------------- */

  const {
    handleMutation,
    mutationLoading,
    mutationCalled,
  } = useAPCreateUpdateMutation({
    docType: docType,
    mode: mode,
    CompanyID: CompanyID,
    isSubmit: isSubmit,
  })

  /* -------------------------------------------- */
  /*                   FUNCTION                   */
  /* -------------------------------------------- */

  const checkDocRefID = () => {
    if (
      watch('TrxType') === 'CREDIT_NOTE' ||
      editData?.einvoice_trx_type === 'CREDIT_NOTE'
    )
      return true
    else return false
  }

  const inputData = (data, status) => {
    return {
      ...(docType === 'creditor-debit-note'
        ? {
            CreditorDebitNoteID: ID,
          }
        : docType === 'debit-note'
        ? { DebitNoteID: ID }
        : {
            SelfBilledID: ID,
            einvoice_trx_type: data?.TrxType,
          }),
      CompanyID: CompanyID,
      DocDate: formatDashDate(new Date(data?.DocDate)?.toISOString()),
      TransactionDate: formatDashDate(new Date(data?.TrxDate)?.toISOString()),
      DocAmt: totalAmount ?? 0,
      DocNo: data.DocNo,
      CreditorAccountID: data?.CreditorAccountID,
      ExpenseItemID: data?.ExpenseItemID,
      Remark: data?.Remark,
      Description: data?.Description,
      RefNo: data?.RefNo,
      einvoice_irbm_uniqueID: data?.UIN,
      einvoice_self_billed: selfBilledChecker,
      ...(selfBilledChecker
        ? {
            einvoice_msic_code_id: msicCodeID,
            einvoice_doc_ref_invoice_id: data?.DocRefID,
          }
        : null),

      CreditTerm: term,
      DueDate: formatDashDate(
        new Date(dueDateChecker(data?.DocDate, term)).toISOString()
      ),
      ApprovalStatus: statusInput(status),
      Attachment: files,
    }
  }

  // To calculate Total Amount of Credit Note
  let totalAmount = debitNoteItemData?.reduce(
    (total, currentValue) => (total = total + currentValue.DocAmt),
    0
  )
  // To calculate Total Amount of Credit Note
  let totalTaxAmt = debitNoteItemData?.reduce(
    (total, currentValue) => (total = total + currentValue.TaxAmt),
    0
  )

  // get amount before tax
  let amtBeforeTax = totalAmount - totalTaxAmt
  let calcTotal = items => {
    return items?.reduce(
      (total, currentValue) => (total = total + currentValue.DocAmt),
      0
    )
  }

  /////////////////////////////////////////////////

  //checking for existing docNo
  const watchDocNo = watch('DocNo')

  const existingDocNo = creditorAcc?.find(
    x => x?.CreditorAccountID === watch('CreditorAccountID')
  )?.DocNoList

  /* -------------------------------------------- */
  /*                USE EFFECT                    */
  /* -------------------------------------------- */

  useEffect(() => {
    if (ID && editData?.Remark) {
      setValue('Remark', editData?.Remark)
    }
  }, [editData])

  useEffect(() => {
    if (editData) {
      localStorage.getItem('attachment')
    }
  }, [])

  //   this is where i get the data for item when editing the form

  useEffect(() => {
    if (formMode === 'edit' && editData) {
      const debitNoteItems = editData?.[itemRefTable]?.map(el => {
        return {
          Sequence: el?.Sequence,
          ...(docType === 'creditor-debit-note'
            ? { CreditorDebitNoteItemID: el?.CreditorDebitNoteItemID }
            : docType === 'debit-note'
            ? { DebitNoteItemID: el?.DebitNoteItemID }
            : { SelfBilledItemID: el?.SelfBilledItemID }),
          ExpenseItemName: el?.ExpenseItem?.ItemName,
          ExpenseItemID: el?.ExpenseItemID,
          CostCentreCode: el?.CostCentre?.Code,
          CostCentreID: el?.CostCentreID,
          Quantity: Number(parseFloat(el?.Quantity).toFixed(4)),
          UnitPrice: Number(parseFloat(el?.UnitPrice).toFixed(4)),
          UOMID: el?.UOMID,
          Amount: el?.Amount,
          TaxSchemeID: el?.TaxSchemeID,
          TaxCode: el?.TaxScheme?.Code,
          TaxAmt: el?.TaxAmt,
          TaxRate: el?.TaxRate,
          Remark: el?.Remark,
          DocAmt: el?.DocAmt,
          einvoice_classification_code_id: el?.einvoice_classification_code_id,
          ClassificationCode: el?.ClassificationCode?.classificationCode,
          ClassificationDescription: el?.ClassificationCode?.description,
        }
      })

      debitNoteItemData.push(...debitNoteItems)
    }
  }, [formMode, editData])

  //this is to check self bill setup from creditor account
  const handleCreditorChange = event => {
    fetchMsicCode()
    let selfBilled = creditorAcc?.find(x => x?.CreditorAccountID === event)
      ?.einvoice_self_billed
    if (
      new Date(watch('DocDate')).toISOString() <
      curEinvCompStart[0]?.eInvStartDate
    )
      setIsSelfBilled(false)
    else {
      register('isSelfBilled')
      setValue('isSelfBilled', selfBilled)
      setIsSelfBilled(selfBilled)
    }
    let defaultMsicCodeID = creditorAcc?.find(
      x => x?.CreditorAccountID === event
    )?.einvoice_msic_code_id
    register('MSICCodeID')
    setValue('MSICCodeID', defaultMsicCodeID)
    setMsicCodeID(defaultMsicCodeID)
  }

  let creditorSelfBilled = creditorAcc?.find(
    x => x?.CreditorAccountID === watch('CreditorAccountID')
  )?.einvoice_self_billed

  //this is for toggle self bill e-invoice
  const handleToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue('isSelfBilled', e.target.checked)
    setIsSelfBilled(e.target.checked)
  }

  /* -------------------------------------------- */
  /*                   DELETE                     */
  /* -------------------------------------------- */

  const onDeleteDebitNoteItem = (delInd: number) => {
    debitNoteItemData.splice(delInd, 1)
  }

  /* ------------------------------------------- */
  /*                ON SUBMIT                    */
  /* ------------------------------------------- */
  // updated tax effective date

  const updateNewDNItem = (newDate, tED) => {
    const arr = []
    debitNoteItemData?.map(x => {
      let newTax =
        getTaxEffectiveDate?.filter(
          j =>
            j?.TaxSchemeID === x?.TaxSchemeID &&
            new Date(j?.Date) <= new Date(newDate)
        )?.length > 0
          ? getTaxEffectiveDate
              ?.filter(
                j =>
                  j?.TaxSchemeID === x?.TaxSchemeID &&
                  new Date(j?.Date) <= new Date(newDate)
              )
              .reduce((a, b) => {
                return new Date(a.Date) > new Date(b.Date) ? a : b
              })
          : null

      if (newTax !== null) {
        arr.push({
          DebitNoteItemID: x?.DebitNoteItemID,
          ExpenseItemID: x?.ExpenseItemID,
          RevenueCode: x?.RevenueCode,
          RevenueName: x?.RevenueName,
          Amount: x?.Amount,
          TaxSchemeID: x?.ExpenseItem?.TaxSchemeID,
          TaxCode: x?.TaxCode,
          TaxRate: newTax?.Rate,
          TaxAmt: x?.Amount * (newTax?.Rate / 100),
          DocAmt: x?.Amount + x?.Amount * (newTax?.Rate / 100),
          CostCentreID: x?.CostCentreID,
          CostCentreCode: x?.CostCentreCode,
          Remark: x?.Remark,
          einvoice_classification_code_id: x?.einvoice_classification_code_id,
        })
      }
    })
  }

  const onSubmit = (data, status) => {
    if (
      docType === 'creditor-debit-note' &&
      findDuplicates(existingDocNo, watchDocNo, formMode, editData?.DocNo)
    ) {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.duplicateDocNo())
    } else {
      handleMutation({
        variables: {
          input: inputData(data, status),
          allocationInput: [],
          itemInput: debitNoteItemData?.map((x, i) => {
            return {
              ExpenseItemID: x?.ExpenseItemID,
              Amount: parseFloat(x?.Amount),
              Quantity: Number(parseFloat(x?.Quantity).toFixed(4)),
              UnitPrice: Number(parseFloat(x?.UnitPrice).toFixed(4)),
              UOMID: x?.UOMID,
              TaxSchemeID: x?.TaxSchemeID,
              TaxRate: parseFloat(x?.TaxRate),
              TaxAmt: parseFloat(x?.TaxAmt),
              DocAmt: parseFloat(x?.DocAmt),
              CostCentreID: x?.CostCentreID,
              Remark: x?.Remark,
              Sequence: x?.Sequence ?? i + 1,
              einvoice_classification_code_id:
                x?.einvoice_classification_code_id,
            }
          }),
        },
      })
    }
  }

  /** This is for permission purposes */
  const { handlePermDisabled } = usePermissionChecker()

  const docDateTimestamp = new Date(watch('DocDate'))
  const yearDocDate = docDateTimestamp.getFullYear()
  const monthDocDate = (docDateTimestamp.getMonth() + 1)
    .toString()
    .padStart(2, '0')
  const dayDocDate = docDateTimestamp
    .getDate()
    .toString()
    .padStart(2, '0')

  const transferDate = `${yearDocDate}-${monthDocDate}-${dayDocDate}`

  const trxDateTimestamp = new Date(watch('TrxDate'))
  const yearTrxDate = trxDateTimestamp.getFullYear()
  const monthTrxDate = (trxDateTimestamp.getMonth() + 1)
    .toString()
    .padStart(2, '0')
  const dayTrxDate = trxDateTimestamp
    .getDate()
    .toString()
    .padStart(2, '0')

  const trxDate = `${yearTrxDate}-${monthTrxDate}-${dayTrxDate}`

  const openPeriod1 =
    transferDate <= latestOpenPeriodCheckingDate?.StartDate ||
    transferDate >= latestOpenPeriodCheckingDate?.EndDate

  const openPeriod2 =
    trxDate >= latestOpenPeriodCheckingDate?.StartDate &&
    trxDate <= latestOpenPeriodCheckingDate?.EndDate

  const checkingYearClose1 = openPeriod1 ? true : false
  const checkingYearClose2 = openPeriod2 ? false : true

  /* -------------------------------------------- */
  /*                    FOOTER                    */
  /* -------------------------------------------- */
  const draftFooterOption = {
    name: 'Save as Draft',
    onClick: () => {
      handleSubmit(data => !mutationCalled && onSubmit(data, 'draft'))()
    },
    color: 'primary',
    props: {
      type: 'submit',
    },
    disabled:
      checkingYearClose2 ||
      handlePermDisabled({
        companyID: CompanyID,
        permEnum: AcctPermission.AccPayableDebitNotesDraft,
      })
        ? true
        : false,
  }

  const rejectFooterOption = {
    name: 'Save',
    onClick: () => {
      handleSubmit(data => !mutationCalled && onSubmit(data, 'reject'))()
    },
    color: 'primary',
    props: {
      type: 'submit',
    },
    disabled:
      !!errors?.DocDate ||
      !!errors?.TrxDate ||
      !!errors?.RefNo ||
      !!errors?.CreditorAccountID ||
      !!errors?.Description ||
      checkingYearClose2
        ? true
        : handlePermDisabled({
            companyID: CompanyID,
            permEnum: AcctPermission.AccPayableDebitNotesUpdate,
          })
        ? true
        : false,
  }

  const submitFooterOption = {
    name: 'Submit',
    onClick: e => {
      handleSubmit(data => !mutationCalled && onSubmit(data, 'submit'))()
      setIsSubmit(true)
    },
    color: 'primary',
    props: {
      type: 'submit',
    },
    disabled:
      checkingYearClose2 === true ||
      calcTotal(debitNoteItemData) === 0 ||
      calcTotal(debitNoteItemData) === undefined
        ? true
        : false,
  }

  let footerOptions: any[]
  if (editData?.mode === 'resubmit') {
    footerOptions = [rejectFooterOption, submitFooterOption]
  } else {
    footerOptions = [draftFooterOption, submitFooterOption]
  }

  /* -------------------------------------------- */
  /*               EXIT CONFIRMATION              */
  /* -------------------------------------------- */

  const hasChanges = () =>
    handleExitConfirmation({
      watch: watch,
      editData: editData,
      formMode: formMode,
      // for item
      itemSuffixID: 'DebitNoteItemID',
      itemTableName: itemRefTable,
      // for attachment
      initFiles: initDocs,
      currFiles: files?.map(file => file?.name),
    })

  return (
    <>
      {taxLoading && <Loading />}
      {companyLoading && <Loading />}
      {documentLoading && <Loading />}
      {CostCentreLoading && <Loading />}
      {ExpenseItemLoading && <Loading />}
      {docNumHeaderLoading && <Loading />}
      {taxEffectiveDateLoading && <Loading />}
      {docDateValidationLoading && <Loading />}
      {credAcctCompAssgtLoading && <Loading />}
      {mutationLoading && <Loading />}
      {openPeriodCheckDateLoading && <Loading />}
      {selfBilledInvLoading && <Loading />}
      {EinvCompStartLoading && <Loading />}

      <MainHeader
        mainBtn="close"
        onClick={() => {
          {
            if (hasChanges() === true) {
              setOpenExitConf(true)
            } else {
              history.push({
                pathname: `/account-payable/${CompanyID}/${docType}`,
              })
            }
          }
        }}
        smTitle={'Account Payable'}
        title={curCompany[0]?.Name}
        routeSegments={[
          { name: 'Account Payable Main Menu' },
          { name: 'Account Payable Submenu' },
          { name: routeSegments, current: true },
        ]}
        rightRouteSegments={[
          {
            name:
              formMode === 'add'
                ? 'New'
                : formMode === 'approve-reject'
                ? 'Approve/Reject'
                : formMode === 'edit'
                ? 'Edit'
                : 'Draft',
            current: true,
          },
        ]}
      />
      <ContentWrapper footer float>
        <CardContents>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              required
              as={KeyboardDatePicker}
              name="DocDate"
              label="Date"
              control={control}
              onChange={(date: Date | null) => {
                //date
              }}
              onAccept={(date: Date | null) => {
                updateNewDNItem(date, taxEffectiveList)
                fetchEinvComp({
                  variables: {
                    CompanyID: CompanyID,
                    RecordStatus: RecordStatus.Active,
                    DocDate: date,
                  },
                })
                let selfBilled = creditorAcc?.find(
                  x => x?.CreditorAccountID === watch('CreditorAccountID')
                )?.einvoice_self_billed
                let defaultMsicCodeID = creditorAcc?.find(
                  x => x?.CreditorAccountID === watch('CreditorAccountID')
                )?.einvoice_msic_code_id
                if (
                  new Date(date).toISOString() <
                  curEinvCompStart[0]?.eInvStartDate
                )
                  setIsSelfBilled(false)
                else {
                  register('isSelfBilled')
                  setValue('isSelfBilled', selfBilled)
                  setIsSelfBilled(selfBilled)
                  setValue('MSICCodeID', defaultMsicCodeID)
                  setMsicCodeID(defaultMsicCodeID)
                }
              }}
              format="dd/MM/yyyy"
              value={watch(formMode === 'add' ? new Date() : editData?.DocDate)}
              margin="dense"
              allowKeyboardControl
              ref={register}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              defaultValue={editData ? editData?.DocDate : new Date()}
              minDate={
                docType === 'self-billed' ? new Date(startDate) : undefined
              }
              maxDate={
                docType === 'self-billed' ? new Date(endDate) : undefined
              }
              showTodayButton
              className={'left'}
              style={{
                paddingTop: '10px',
              }}
            />
          </MuiPickersUtilsProvider>

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              as={KeyboardDatePicker}
              name="TrxDate"
              required
              label="Transaction Date"
              control={control}
              format="dd/MM/yyyy"
              margin="normal"
              allowKeyboardControl
              onChange={(date: Date | null) => {}}
              ref={register}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              showTodayButton
              className="right"
              value={new Date()}
              defaultValue={editData ? editData?.TransactionDate : new Date()}
              minDate={
                docType === 'self-billed'
                  ? new Date(startDate) ||
                    new Date(latestOpenPeriodCheckingDate?.StartDate)
                  : new Date(latestOpenPeriodCheckingDate?.StartDate)
              }
              maxDate={
                docType === 'self-billed'
                  ? new Date(endDate) ||
                    new Date(latestOpenPeriodCheckingDate?.EndDate)
                  : new Date(latestOpenPeriodCheckingDate?.EndDate)
              }
              helperText={
                checkingYearClose2
                  ? 'Financial Period already closed'
                  : errors?.TrxDate?.message
              }
              error={errors?.TrxDate || checkingYearClose2 ? true : false}
            />
          </MuiPickersUtilsProvider>

          {docType === 'creditor-debit-note' ||
          docNoChecker === false ||
          getDocumentNumberHeader[0]?.IsAutoDocNo === false ? (
            <Controller
              as={TextField}
              name="DocNo"
              label="Document No"
              margin="dense"
              required
              id="standard-basic"
              defaultValue={editData?.DocNo}
              autoComplete="off"
              control={control}
              className="left"
              ref={register}
              helperText={errors?.DocNo?.message}
              error={errors?.DocNo ? true : false}
            />
          ) : null}

          <Controller
            as={TextField}
            id="standard-basic"
            name="RefNo"
            label="Reference No."
            required
            autoComplete="off"
            control={control}
            className={
              docType === 'creditor-debit-note' ||
              docNoChecker === false ||
              getDocumentNumberHeader[0]?.IsAutoDocNo === false
                ? 'right'
                : ''
            }
            fullWidth={
              docType === 'creditor-debit-note' ||
              docNoChecker === false ||
              getDocumentNumberHeader[0]?.IsAutoDocNo === false
                ? true
                : false
            }
            margin="dense"
            ref={register}
            helperText={errors?.RefNo?.message}
            error={errors?.RefNo ? true : false}
            defaultValue={editData ? editData?.RefNo : ''}
            disabled={mode == 'approve-reject'}
          />

          {docType !== 'self-billed' && (
            <Controller
              as={TextField}
              id="standard-basic"
              name="UIN"
              label="UIN"
              // required
              autoComplete="off"
              control={control}
              fullWidth
              margin="dense"
              ref={register}
              helperText={errors?.UIN?.message}
              error={errors?.UIN ? true : false}
              defaultValue={editData ? editData?.einvoice_irbm_uniqueID : ''}
              disabled={mode == 'approve-reject'}
            />
          )}

          {!credAcctCompAssgtLoading && (
            <Controller
              render={({ value, onChange }) => {
                const defVal = creditorAcc?.filter(
                  x => x?.CreditorAccountID === editData?.CreditorAccountID
                )[0]

                return (
                  <Autocomplete
                    options={
                      creditorAcc?.sort((a, b) => {
                        return a.CompanyName.localeCompare(b.CompanyName)
                      }) || []
                    }
                    getOptionLabel={option => `${option?.CompanyName}`}
                    fullWidth
                    onChange={(value, newValue: any) => {
                      handleCreditorChange(newValue?.CreditorAccountID)
                      setValue('CreditorAccountID', newValue?.CreditorAccountID)
                      clearErrors('CreditorAccountID')
                      setTerm(newValue?.CreditTerm)
                    }}
                    renderOption={(props, option) => {
                      return <span>{props?.CompanyName}</span>
                    }}
                    defaultValue={defVal}
                    disabled={mode === 'approve-reject'}
                    renderInput={(params: any) => {
                      return (
                        <div>
                          <TextField
                            {...params}
                            helperText={errors?.CreditorAccountID?.message}
                            error={errors?.CreditorAccountID ? true : false}
                            label="Creditor Name"
                            style={{ width: '100%' }}
                            margin="dense"
                            required
                          />
                        </div>
                      )
                    }}
                  />
                )
              }}
              label="Creditor Name"
              name="CreditorAccountID"
              autoComplete="off"
              control={control}
              multiline={true}
              fullWidth
              margin="dense"
              ref={register}
              helperText={errors?.CreditorAccountID?.message}
              error={errors?.CreditorAccountID ? true : false}
              defaultValue={editData?.CreditorAccountID}
              required
              disabled={mode === 'approve-reject'}
            />
          )}

          {isEinvoice && (
            <IsEinvoiceSelfBilled
              register={register}
              control={control}
              errors={errors}
              selfBilled={creditorSelfBilled}
              defaultMsicCodeID={defaultMsicCodeID}
              isSelfBilled={isSelfBilled}
              handleToggle={handleToggle}
              msicCodeLoading={msicCodeLoading}
              getMSICCode={getMSICCode}
              msicCodeID={msicCodeID}
              setMsicCodeID={setMsicCodeID}
              selfBilledChecking={!!creditorSelfBilled}
              haveSelfBilled={
                !!creditorSelfBilled &&
                !!watch('isSelfBilled') &&
                !msicCodeLoading
              }
              data={getSelfBilledInvoice}
              dataLoading={selfBilledInvLoading}
              isDocRefID={!!watch('isSelfBilled') && !msicCodeLoading}
              docRefKeyID={'InvoiceID'}
              editData={editData}
            />
          )}

          <Controller
            as={TextField}
            id="standard-basic"
            name="Description"
            label="Description"
            required
            autoComplete="off"
            control={control}
            fullWidth
            margin="dense"
            helperText={errors?.Description?.message}
            error={errors?.Description ? true : false}
            defaultValue={editData?.Description}
            ref={register}
            disabled={mode === 'approve-reject'}
          />

          <VoiceTextField
            mounted={true}
            label="Remark"
            name="Remark"
            value={voiceRemark}
            setValue={setValue}
            record={record}
            setRecord={setRecord}
            control={control}
            controllerProps={{
              error: errors?.Remark ? true : false,
              helperText: errors?.Remark?.message,
              ref: register,
              autoComplete: 'off',
            }}
          />

          <div style={{ width: '100%', marginTop: '24px' }}>
            <FileUploadInput
              placeholder={previewFiles.length === 0 ? 'Attachment' : null}
              label={previewFiles.length > 0 ? 'Attachment' : null}
              name="Attachment"
              files={files}
              onHandleUploadChange={handleUploadChange}
              multiple
              accept={
                '.png, .jpg, .jpeg, .gif, .mp4, .avi, .mkv, .mov, .flv, .3gp, application/msword, application/pdf, application/vnd.ms-excel, application/*, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
              }
              imagePreview={
                <>
                  {previewFiles?.map((v, i) => (
                    <UploadPreview
                      remove
                      key={v}
                      src={v}
                      onClick={() => removeFile(i)}
                      mediaType={
                        files[i]?.type ?? DocData?.DocumentListing[i]?.mediaType
                      }
                    />
                  ))}
                </>
              }
            />
          </div>
        </CardContents>

        <ItemDetail
          titleLabel={title}
          itemData={debitNoteItemData}
          setOpenDialog={setOpenItemDialog}
          fetchExpenseItem={fetchExpenseItem}
          fetchCostCentre={fetchCostCentre}
          resetMenu={resetMenu}
          setSelectedTax={setSelectedTax}
          setDetailMode={setDetailMode}
          formMode={formMode}
          detailMode={detailMode}
          getItemByCompany={getItemByCompany}
          getCostCentre={getCostCentre}
          handleClick={handleClick}
          keyItemID={itemRefTableKey}
          isSelfBilled={isSelfBilled}
          creditorID={selectedCreditor}
        />
      </ContentWrapper>

      <Menu
        id="menu-list"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
      >
        <MenuItem
          onClick={() => {
            setOpenItemDialog(true)
            setDetailMode('edit')
            fetchExpenseItem()
            // fetchCostCentre()
          }}
        >
          <span className="">Edit</span>
        </MenuItem>
        <MenuItem
          onClick={() => {
            onDeleteDebitNoteItem(menu?.index)
          }}
        >
          <span className="">Delete</span>
        </MenuItem>
      </Menu>

      {!ExpenseItemLoading && (
        <ItemDialog
          companyID={CompanyID}
          title={title}
          fromAP={true}
          openItemDialog={openItemDialog}
          setOpenItemDialog={setOpenItemDialog}
          watch={watch}
          menu={menu}
          ExpenseItemLoading={ExpenseItemLoading}
          fetchExpenseItem={fetchExpenseItem}
          getItemByCompany={getItemByCompany}
          //CostCentreLoading={CostCentreLoading}
          //fetchCostCentre={fetchCostCentre}
          //getCostCentre={getCostCentre}
          itemData={debitNoteItemData}
          selectedTax={selectedTax}
          setSelectedTax={setSelectedTax}
          detailMode={detailMode}
          resetMenu={resetMenu}
          primaryItemKey="DebitNoteItemID"
          defaultCostCentre={defaultCostCentre}
          getMasterCOA={getMasterCOA}
          masterCOALoading={masterCOALoading}
          selfBillStatus={selfBilledChecker}
          EInvoiceStarted={isEinvoice}
          creditorAcc={selectedCreditor}
        />
      )}

      <ExitConfirmationDialog
        openExitConf={openExitConf}
        setOpenExitConf={setOpenExitConf}
        onConfirm={() => {
          history.push(`/account-payable/${CompanyID}/${docType}`)
          localStorage.removeItem('debitNote')
          localStorage.removeItem('debitNoteAllocation')
          localStorage.removeItem('attachment')
        }}
      />
      {footerOptions?.length > 0 ? (
        <Footer options={[...footerOptions]} />
      ) : null}

      <TooltipAmountFooter
        data={debitNoteItemData}
        module={`${docType}-draft`}
      />
    </>
  )
}
