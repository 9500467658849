import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  usePostApAdvanceMutation,
  usePostApCreditNoteMutation,
  usePostApCreditorCreditNoteMutation,
  usePostApCreditorDebitNoteMutation,
  usePostApDebitNoteMutation,
  usePostApInvoiceMutation,
  usePostApPaymentMutation,
  usePostApRefundMutation,
  usePostApSelfBilledMutation,
  useRejectApAdvanceMutation,
  useRejectApCreditNoteMutation,
  useRejectApCreditorCreditNoteMutation,
  useRejectApCreditorDebitNoteMutation,
  useRejectApDebitNoteMutation,
  useRejectApInvoiceMutation,
  useRejectApPaymentMutation,
  useRejectApRefundMutation,
  useRejectApSelfBilledMutation,
} from 'generated/graphql'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router'

interface APPostMutation {
  apSubmenu: string
  loadData: any
  setPostIDs: any
  CompanyID: string
  setOriginalListing: any
}

export const useAPPostMutation = ({
  apSubmenu,
  loadData,
  setPostIDs,
  CompanyID,
  setOriginalListing,
}: APPostMutation) => {
  const getSearch = JSON.parse(localStorage.getItem('searchFilter'))

  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any
  )
  const [errorDia, setErrorDia] = useState<boolean>(false)
  const [errMsg, setErrMsg] = useState<string>('')

  /* -------------------------------------------- */
  /*                POST MUTATION               */
  /* -------------------------------------------- */

  const [
    postAPAdvance,
    {
      loading: postAPAdvanceLoading,
      called: postAPAdvanceCalled,
      error: postAPAdvanceError,
    },
  ] = usePostApAdvanceMutation({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    onCompleted: () => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.postNewRecord())
      setPostIDs(new Set([]))
      setOriginalListing([])

      loadData({
        variables: {
          CompanyID: CompanyID,
          StatusArr: 'SUBMIT',
          take: 30,
          skip: 0,
          searchValue:
            getSearch?.name !== '' || getSearch?.name === undefined
              ? undefined
              : getSearch?.name,
        },
      })
    },
  })

  const [
    rejectAPAdvance,
    {
      loading: rejectAPAdvanceLoading,
      called: rejectAPAdvanceCalled,
      error: rejectAPAdvanceError,
    },
  ] = useRejectApAdvanceMutation({
    onError: error => {
      console.log('ERROR', error)
    },
    onCompleted: () => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.rejectNewRecord())
      setPostIDs(new Set([]))
      setOriginalListing([])

      loadData({
        variables: {
          CompanyID: CompanyID,
          StatusArr: 'SUBMIT',
          take: 30,
          skip: 0,
          searchValue:
            getSearch?.name !== '' || getSearch?.name === undefined
              ? undefined
              : getSearch?.name,
        },
      })
    },
  })

  const [
    postAPInvoice,
    {
      loading: postAPInvoiceLoading,
      called: postAPInvoiceCalled,
      error: postAPInvoiceError,
    },
  ] = usePostApInvoiceMutation({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    onCompleted: () => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.postNewRecord())
      setPostIDs(new Set([]))
      setOriginalListing([])

      loadData({
        variables: {
          CompanyID: CompanyID,
          StatusArr: 'SUBMIT',
          take: 30,
          skip: 0,
          searchValue:
            getSearch?.name !== '' || getSearch?.name === undefined
              ? undefined
              : getSearch?.name,
        },
      })
    },
  })

  const [
    rejectAPInvoice,
    {
      loading: rejectAPInvoiceLoading,
      called: rejectAPInvoiceCalled,
      error: rejectAPInvoiceError,
    },
  ] = useRejectApInvoiceMutation({
    onError: error => {
      console.log('ERROR', error)
    },
    onCompleted: () => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.rejectNewRecord())
      setPostIDs(new Set([]))
      setOriginalListing([])

      loadData({
        variables: {
          CompanyID: CompanyID,
          StatusArr: 'SUBMIT',
          take: 30,
          skip: 0,
          searchValue:
            getSearch?.name !== '' || getSearch?.name === undefined
              ? undefined
              : getSearch?.name,
        },
      })
    },
  })

  const [
    postAPRefund,
    {
      loading: postAPRefundLoading,
      called: postAPRefundCalled,
      error: postAPRefundError,
    },
  ] = usePostApRefundMutation({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    onCompleted: () => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.postNewRecord())
      setPostIDs(new Set([]))
      setOriginalListing([])

      loadData({
        variables: {
          CompanyID: CompanyID,
          StatusArr: 'SUBMIT',
          take: 30,
          skip: 0,
          searchValue:
            getSearch?.name !== '' || getSearch?.name === undefined
              ? undefined
              : getSearch?.name,
        },
      })
    },
  })

  const [
    rejectAPRefund,
    {
      loading: rejectAPRefundLoading,
      called: rejectAPRefundCalled,
      error: rejectAPRefundError,
    },
  ] = useRejectApRefundMutation({
    onError: error => {
      console.log('ERROR', error)
    },
    onCompleted: () => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.rejectNewRecord())
      setPostIDs(new Set([]))
      setOriginalListing([])

      loadData({
        variables: {
          CompanyID: CompanyID,
          StatusArr: 'SUBMIT',
          take: 30,
          skip: 0,
          searchValue:
            getSearch?.name !== '' || getSearch?.name === undefined
              ? undefined
              : getSearch?.name,
        },
      })
    },
  })

  const [
    postAPDebitNote,
    {
      loading: postAPDebitNoteLoading,
      called: postAPDebitNoteCalled,
      error: postAPDebitNoteError,
    },
  ] = usePostApDebitNoteMutation({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    onCompleted: () => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.postNewRecord())
      setPostIDs(new Set([]))
      setOriginalListing([])

      loadData({
        variables: {
          CompanyID: CompanyID,
          StatusArr: 'SUBMIT',
          take: 30,
          skip: 0,
          searchValue:
            getSearch?.name !== '' || getSearch?.name === undefined
              ? undefined
              : getSearch?.name,
        },
      })
    },
  })

  const [
    rejectAPDebitNote,
    {
      loading: rejectAPDebitNoteLoading,
      called: rejectAPDebitNoteCalled,
      error: rejectAPDebitNoteError,
    },
  ] = useRejectApDebitNoteMutation({
    onError: error => {
      console.log('ERROR', error)
    },
    onCompleted: () => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.rejectNewRecord())
      setPostIDs(new Set([]))
      setOriginalListing([])

      loadData({
        variables: {
          CompanyID: CompanyID,
          StatusArr: 'SUBMIT',
          take: 30,
          skip: 0,
          searchValue:
            getSearch?.name !== '' || getSearch?.name === undefined
              ? undefined
              : getSearch?.name,
        },
      })
    },
  })
  const [
    postAPCreditorDebitNote,
    {
      loading: postAPCreditorDebitNoteLoading,
      called: postAPCreditorDebitNoteCalled,
      error: postAPCreditorDebitNoteError,
    },
  ] = usePostApCreditorDebitNoteMutation({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    onCompleted: () => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.postNewRecord())
      setPostIDs(new Set([]))
      setOriginalListing([])

      loadData({
        variables: {
          CompanyID: CompanyID,
          StatusArr: 'SUBMIT',
          take: 30,
          skip: 0,
          searchValue:
            getSearch?.name !== '' || getSearch?.name === undefined
              ? undefined
              : getSearch?.name,
        },
      })
    },
  })

  const [
    rejectAPCreditorDebitNote,
    {
      loading: rejectAPCreditorDebitNoteLoading,
      called: rejectAPCreditorDebitNoteCalled,
      error: rejectAPCreditorDebitNoteError,
    },
  ] = useRejectApCreditorDebitNoteMutation({
    onError: error => {
      console.log('ERROR', error)
    },
    onCompleted: () => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.rejectNewRecord())
      setPostIDs(new Set([]))
      setOriginalListing([])

      loadData({
        variables: {
          CompanyID: CompanyID,
          StatusArr: 'SUBMIT',
          take: 30,
          skip: 0,
          searchValue:
            getSearch?.name !== '' || getSearch?.name === undefined
              ? undefined
              : getSearch?.name,
        },
      })
    },
  })

  const [
    postAPPayment,
    {
      loading: postAPPaymentLoading,
      called: postAPPaymentCalled,
      error: postAPPaymentError,
    },
  ] = usePostApPaymentMutation({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    onCompleted: () => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.postNewRecord())
      setPostIDs(new Set([]))
      setOriginalListing([])

      loadData({
        variables: {
          CompanyID: CompanyID,
          StatusArr: 'SUBMIT',
          take: 30,
          skip: 0,
          searchValue:
            getSearch?.name !== '' || getSearch?.name === undefined
              ? undefined
              : getSearch?.name,
        },
      })
    },
  })

  const [
    rejectAPPayment,
    {
      loading: rejectAPPaymentLoading,
      called: rejectAPPaymentCalled,
      error: rejectAPPaymentError,
    },
  ] = useRejectApPaymentMutation({
    onError: error => {
      console.log('ERROR', error)
    },
    onCompleted: () => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.rejectNewRecord())
      setPostIDs(new Set([]))
      setOriginalListing([])

      loadData({
        variables: {
          CompanyID: CompanyID,
          StatusArr: 'SUBMIT',
          take: 30,
          skip: 0,
          searchValue:
            getSearch?.name !== '' || getSearch?.name === undefined
              ? undefined
              : getSearch?.name,
        },
      })
    },
  })

  const [
    postAPCreditNote,
    {
      loading: postAPCreditNoteLoading,
      called: postAPCreditNoteCalled,
      error: postAPCreditNoteError,
    },
  ] = usePostApCreditNoteMutation({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.postNewRecord())
      setPostIDs(new Set([]))
      setOriginalListing([])

      loadData({
        variables: {
          CompanyID: CompanyID,
          StatusArr: 'SUBMIT',
          take: 30,
          skip: 0,
          searchValue:
            getSearch?.name !== '' || getSearch?.name === undefined
              ? undefined
              : getSearch?.name,
        },
      })
    },
  })

  const [
    rejectAPCreditNote,
    {
      loading: rejectAPCreditNoteLoading,
      called: rejectAPCreditNoteCalled,
      error: rejectAPCreditNoteError,
    },
  ] = useRejectApCreditNoteMutation({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.rejectNewRecord())
      setPostIDs(new Set([]))
      setOriginalListing([])

      loadData({
        variables: {
          CompanyID: CompanyID,
          StatusArr: 'SUBMIT',
          take: 30,
          skip: 0,
          searchValue:
            getSearch?.name !== '' || getSearch?.name === undefined
              ? undefined
              : getSearch?.name,
        },
      })
    },
  })

  const [
    postAPCreditorCreditNote,
    {
      loading: postAPCCreditNoteLoading,
      called: postAPCCreditNoteCalled,
      error: postAPCCreditNoteError,
    },
  ] = usePostApCreditorCreditNoteMutation({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.postNewRecord())
      setPostIDs(new Set([]))
      setOriginalListing([])

      loadData({
        variables: {
          CompanyID: CompanyID,
          StatusArr: 'SUBMIT',
          take: 30,
          skip: 0,
          searchValue:
            getSearch?.name !== '' || getSearch?.name === undefined
              ? undefined
              : getSearch?.name,
        },
      })
    },
  })

  const [
    rejectAPCreditorCreditNote,
    {
      loading: rejectAPCCreditNoteLoading,
      called: rejectAPCCreditNoteCalled,
      error: rejectAPCCreditNoteError,
    },
  ] = useRejectApCreditorCreditNoteMutation({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.rejectNewRecord())
      setPostIDs(new Set([]))
      setOriginalListing([])

      loadData({
        variables: {
          CompanyID: CompanyID,
          StatusArr: 'SUBMIT',
          take: 30,
          skip: 0,
          searchValue:
            getSearch?.name !== '' || getSearch?.name === undefined
              ? undefined
              : getSearch?.name,
        },
      })
    },
  })
  const [
    postAPSelfBilled,
    {
      loading: postAPSelfBilledLoading,
      called: postAPSelfBilledCalled,
      error: postAPSelfBilledError,
    },
  ] = usePostApSelfBilledMutation({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.postNewRecord())
      setPostIDs(new Set([]))
      setOriginalListing([])

      loadData({
        variables: {
          CompanyID: CompanyID,
          StatusArr: 'SUBMIT',
          take: 30,
          skip: 0,
          searchValue:
            getSearch?.name !== '' || getSearch?.name === undefined
              ? undefined
              : getSearch?.name,
        },
      })
    },
  })

  const [
    rejectAPSelfBilled,
    {
      loading: rejectAPSelfBilledLoading,
      called: rejectAPSelfBilledCalled,
      error: rejectAPSelfBilledError,
    },
  ] = useRejectApSelfBilledMutation({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.rejectNewRecord())
      setPostIDs(new Set([]))
      setOriginalListing([])

      loadData({
        variables: {
          CompanyID: CompanyID,
          StatusArr: 'SUBMIT',
          take: 30,
          skip: 0,
          searchValue:
            getSearch?.name !== '' || getSearch?.name === undefined
              ? undefined
              : getSearch?.name,
        },
      })
    },
  })

  const handlePost =
    apSubmenu === 'advance'
      ? postAPAdvance
      : apSubmenu === 'invoice'
      ? postAPInvoice
      : apSubmenu === 'refund'
      ? postAPRefund
      : apSubmenu === 'debit-note'
      ? postAPDebitNote
      : apSubmenu === 'payment'
      ? postAPPayment
      : apSubmenu === 'credit-note'
      ? postAPCreditNote
      : apSubmenu === 'creditor-credit-note'
      ? postAPCreditorCreditNote
      : apSubmenu === 'creditor-debit-note'
      ? postAPCreditorDebitNote
      : postAPSelfBilled

  const handleReject =
    apSubmenu === 'advance'
      ? rejectAPAdvance
      : apSubmenu === 'invoice'
      ? rejectAPInvoice
      : apSubmenu === 'refund'
      ? rejectAPRefund
      : apSubmenu === 'debit-note'
      ? rejectAPDebitNote
      : apSubmenu === 'payment'
      ? rejectAPPayment
      : apSubmenu === 'credit-note'
      ? rejectAPCreditNote
      : apSubmenu === 'creditor-credit-note'
      ? rejectAPCreditorCreditNote
      : apSubmenu === 'creditor-debit-note'
      ? rejectAPCreditorDebitNote
      : rejectAPSelfBilled

  const mutationLoading =
    apSubmenu === 'advance'
      ? postAPAdvanceLoading || rejectAPAdvanceLoading
      : apSubmenu === 'invoice'
      ? postAPInvoiceLoading || rejectAPInvoiceLoading
      : apSubmenu === 'refund'
      ? postAPRefundLoading || rejectAPRefundLoading
      : apSubmenu === 'debit-note'
      ? postAPDebitNoteLoading || rejectAPDebitNoteLoading
      : apSubmenu === 'payment'
      ? postAPPaymentLoading || rejectAPPaymentLoading
      : apSubmenu === 'credit-note'
      ? postAPCreditNoteLoading || rejectAPCreditNoteLoading
      : apSubmenu === 'creditor-credit-note'
      ? postAPCCreditNoteLoading || rejectAPCCreditNoteLoading
      : apSubmenu === 'creditor-debit-note'
      ? postAPCreditorDebitNoteLoading || rejectAPCreditorDebitNoteLoading
      : postAPSelfBilledLoading || rejectAPSelfBilledLoading

  const postCalled =
    apSubmenu === 'advance'
      ? postAPAdvanceCalled || rejectAPAdvanceCalled
      : apSubmenu === 'invoice'
      ? postAPInvoiceCalled || rejectAPInvoiceCalled
      : apSubmenu === 'refund'
      ? postAPRefundCalled || rejectAPRefundCalled
      : apSubmenu === 'debit-note'
      ? postAPDebitNoteCalled || rejectAPDebitNoteCalled
      : apSubmenu === 'payment'
      ? postAPPaymentCalled || rejectAPPaymentCalled
      : apSubmenu === 'credit-note'
      ? postAPCreditNoteCalled || rejectAPCreditNoteCalled
      : apSubmenu === 'creditor-credit-note'
      ? postAPCCreditNoteCalled || rejectAPCCreditNoteCalled
      : apSubmenu === 'creditor-debit-note'
      ? postAPCreditorDebitNoteCalled || rejectAPCreditorDebitNoteCalled
      : postAPSelfBilledCalled || rejectAPSelfBilledCalled

  return {
    handlePost,
    handleReject,
    mutationLoading,
    postCalled,
    setErrorDia,
    errorDia,
    errMsg,
  }
}
