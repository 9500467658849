import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import OrangeDollarIcon from 'assets/icons/Money/orange-dollar.svg'
import { AccountFooter } from 'components/Footer/AccountFooter'
import { CopyrightFooter } from 'components/Footer/Copyright'
import { TotalAmountFooter } from 'components/Footer/TotalAmountFooter'
import { FormAllocationTableContent } from 'components/Table/FormAllocationTableContent'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  GetAllocationDocumentDocument,
  RecordStatus,
  useCreateAllocationMutation,
  useGetApAllocationDocumentLazyQuery,
  useGetApDebitNoteQuery,
} from 'generated/graphql'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { formatDate } from 'helpers/StringNumberFunction/FormatDate'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import React, { useContext, useEffect, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useHistory, useLocation, useParams } from 'react-router'

export const APDebitNoteAllocation = props => {
  let history = useHistory()
  const { CompanyID, ID }: any = useParams()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  let location = useLocation()
  const editData = location?.state as any
  const [allocatedObj, setAllocatedObj] = useState({})
  const [val, setVal] = useState(false)
  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any
  )

  //----------------USE FORM---------------------//
  const {
    handleSubmit,
    register,
    setValue,
    watch,
    control,
    errors,
    setError,
    clearErrors,
  } = useForm<any>()

  const { fields, append, remove, prepend } = useFieldArray({
    control,
    name: 'Allocation',
  })

  //-----------------QUERY------------------------//

  const {
    loading: APDebitNoteLoading,
    error: APDebitNoteError,
    data: { getAPDebitNote } = {
      getAPDebitNote: [],
    },
  } = useGetApDebitNoteQuery({
    fetchPolicy: 'network-only',
    variables: {
      DebitNoteID: ID,
    },
    onCompleted: ({ getAPDebitNote }) => {
      if (getAPDebitNote?.length > 0) {
        loadAllocationDocument({
          variables: {
            entityID: [ID],
            companyID: CompanyID,
            refTable: 'AP_DebitNote',
            creditorDebtorID: getAPDebitNote[0]?.CreditorAccountID,
          },
        })
      }
    },
  })

  const [
    loadAllocationDocument,
    {
      loading: getAllocationDocumentLoading,
      error: getAllocationDocumentError,
      data: { getAllocationDocument } = { getAllocationDocument: [] },
    },
  ] = useGetApAllocationDocumentLazyQuery({
    fetchPolicy: 'network-only',

    onError: error => {},
    onCompleted: ({ getAllocationDocument }) => {
      let arr = []
      if (getAllocationDocument?.length > 0) {
        getAllocationDocument
          // ?.filter(
          //   alloc =>
          //     Number(alloc?.BalanceAmt) +
          //       Number(allocatedObj[alloc[`${alloc?.CreditOrDebit}ID`]] ?? 0) >
          //       0 && (!alloc?.CanAllocate ? alloc?.AllocationID : true)
          // )
          // ?.sort((x, y) => {
          //   return y?.CanAllocate - x?.CanAllocate
          // })
          ?.map((alloc, index) => {
            arr.push({
              AllocationID: alloc?.AllocationID,
              CanAllocate: alloc?.CanAllocate,
              CreditOrDebit: alloc?.CreditOrDebit,
              DocDate: alloc?.DocDate,
              DocNo: alloc?.DocNo,
              Description: alloc?.Description,
              // for previous allocated amt on the same doc
              AllocationAmt: Number(
                allocatedObj[alloc[`${alloc?.CreditOrDebit}ID`]] ?? 0
              ),
              // balance amount + previous allocated amt on the same doc
              BalanceAmt:
                Number(alloc?.BalanceAmt) +
                Number(allocatedObj[alloc[`${alloc?.CreditOrDebit}ID`]] ?? 0),
              // debit or debit id
              [`${alloc?.CreditOrDebit}ID`]: alloc[`${alloc?.CreditOrDebit}ID`],
              // debit or debit ref table
              [`${alloc?.CreditOrDebit}RefTable`]: alloc[
                `${alloc?.CreditOrDebit}RefTable`
              ],
              // debit or debit doc no
              [`${alloc?.CreditOrDebit}DocNo`]: alloc[
                `${alloc?.CreditOrDebit}DocNo`
              ],
            })
            return alloc
          })
        append(arr)
      }
    },
  })

  //-------------------------MUTATION----------------------------------//
  const [
    createAllocation,
    {
      loading: CreateAllocationLoading,
      called: CreateAllocationCalled,
      error: CreateAllocationError,
    },
  ] = useCreateAllocationMutation({
    onError: error => {},
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.createNewRecord())
      setTimeout(() => {
        history.goBack()
      }, 500)
    },
  })

  //-----------------------------ONSUBMIT-------------------------------//

  const onSubmit = (data, status) => {
    if (val == false) {
      setVal(true)
      createAllocation({
        variables: {
          input: watch('Allocation')
            ?.filter(
              alloc => Number(alloc?.AllocationAmt) !== 0 || alloc?.AllocationID
            )
            ?.map(alloc => {
              const mainDebitOrDebit =
                alloc?.CreditOrDebit === 'Debit' ? 'Credit' : 'Debit'
              return {
                ...(alloc?.AllocationID
                  ? { AllocationID: alloc?.AllocationID }
                  : null),
                AllocationAmt: Number(alloc?.AllocationAmt),
                CreditOrDebit: alloc?.CreditOrDebit,
                CompanyID: CompanyID,
                // for allocated table
                [`${alloc?.CreditOrDebit}RefTable`]: alloc?.[
                  `${alloc?.CreditOrDebit}RefTable`
                ],
                [`${alloc?.CreditOrDebit}ID`]: alloc?.[
                  `${alloc?.CreditOrDebit}ID`
                ],
                // main table
                [`${mainDebitOrDebit}RefTable`]: 'AP_DebitNote',
              }
            }),
          entityID: ID,
        },
        refetchQueries: [
          {
            query: GetAllocationDocumentDocument,
            variables: {
              entityID: ID,
              companyID: CompanyID,
              refTable: 'AP_DebitNote',
              creditorDebtorID: getAPDebitNote[0]?.CreditorAccountID,
            },
          },
        ],
      })
      // }
    }
  }

  //----------------------------FOOTER---------------------------//

  const docAmt = getAPDebitNote[0]?.DocAmt

  const allocationTotal = watch('Allocation')?.reduce(
    (total, currentValue) => (total = total + currentValue.AllocationAmt),
    0
  )

  const minusamt = docAmt - allocationTotal

  const createUpdateCalled = CreateAllocationCalled

  const checkExceedAllocation = watch('Allocation')?.map((alloc, index) => {
    const allocation = `Allocation[${index}]`

    if (watch(`Allocation[${index}].AllocationAmt`) > alloc?.BalanceAmt)
      return true
    else return false
  })

  const submitFooterOption = {
    name: 'Save',
    onClick: e => {
      handleSubmit(data => !createUpdateCalled && onSubmit(data, 'submit'))()
    },
    color: 'primary',
    props: {
      type: 'submit',
    },
    disabled:
      parseFloat(allocationTotal) > docAmt
        ? true
        : !!checkExceedAllocation?.find(x => x === true)
        ? true
        : false,
  }

  const footerOptions: any[] = [submitFooterOption]

  //----------------------------USEEFFECT-------------------------------//
  useEffect(() => {
    if (editData?.Allocation) {
      let allocatedArr = editData?.Allocation
      allocatedArr?.map(allocated => {
        if (!(allocated[`${allocated?.CreditOrDebit}ID`] in allocatedObj)) {
          allocatedObj[allocated[`${allocated?.CreditOrDebit}ID`]] =
            allocated?.AllocationAmt
        } else {
          allocatedObj[allocated[`${allocated?.CreditOrDebit}ID`]] +=
            allocated?.AllocationAmt
        }
      })
    }
  }, [editData])

  return (
    <>
      {APDebitNoteLoading && <Loading />}
      {CreateAllocationLoading && <Loading />}

      <MainHeader
        onClick={() => history.goBack()}
        mainBtn="back"
        smTitle={'Account Payable'}
        title={user?.companyName}
        routeSegments={[
          { name: 'Account Payable' },
          { name: 'AP Submenu' },
          { name: 'AP Submenu' },
          { name: 'Allocation', current: true },
        ]}
        currency={user?.companyCurrencyCode}
      />

      <DynamicSubHeader
        title={
          <>
            <span className="xsTitle" style={{ color: '#ff9800' }}>
              {getAPDebitNote[0]?.DocNo}
            </span>
          </>
        }
        rightText={
          <span className="desc" style={{ color: 'red' }}>
            {formatDate(getAPDebitNote[0]?.DocDate)}
          </span>
        }
        infoLine={
          <>
            <div
              className="text-overflow"
              style={{ fontSize: '11px', width: '270px' }}
            >
              {getAPDebitNote[0]?.CreditorAccount?.CompanyName}
            </div>
          </>
        }
        rightInfoLine={
          <span className="desc flex-space">
            <img
              src={OrangeDollarIcon}
              style={{
                width: '12px',
                marginBottom: '-2px',
                marginRight: '3px',
              }}
            />
            <span className="desc flex-space c-orange">
              {amtStr(getAPDebitNote[0]?.DocAmt)}
            </span>
          </span>
        }
      />

      <ContentWrapper multiDynamicInfo float>
        <FormAllocationTableContent
          fields={fields}
          watch={watch}
          errors={errors}
          register={register}
          control={control}
          setValue={setValue}
          clearErrors={clearErrors}
          setError={setError}
          allocatedObj={allocatedObj}
          docAmt={docAmt}
          totalAmt={allocationTotal}
          headerStyle={{
            backgroundColor: '#faf2e8',
            color: '#ff9800',
            fontWeight: '700',
          }}
          headerRightTitle={'Allocate Amt'}
          hasCheckbox={true}
        />
      </ContentWrapper>

      <TotalAmountFooter
        // docAmt={calcTotal(debitNoteItems)}
        balanceInfo={true}
        balanceAmt={minusamt}
        TotalAllocationAmt={allocationTotal}
      />

      {footerOptions?.length > 0 ? (
        <AccountFooter options={[...footerOptions]} />
      ) : null}
    </>
  )
}
