import theme from '@ifca-root/react-component/src/assets/theme'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import {
  Grid,
  IconButton,
  useMediaQuery,
  List,
  ListItem,
  ListItemSecondaryAction,
} from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import AddIcon from '@material-ui/icons/Add'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import React from 'react'

export const ARItemDetail = (props: any) => {
  const {
    titleLabel,
    itemData,
    setOpenDialog,
    resetMenu,
    setSelectedTax,
    setTaxAmt,
    setCost,
    handleClick,
    keyItemID,
    getClassificationCode,
    watch,
  } = props

  const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  })

  return (
    <CardContents
      section={{
        header: {
          title: `${titleLabel} Detail (${itemData?.length})`,
          onClickAction: () => {
            setOpenDialog(true)
            resetMenu()
            setSelectedTax(null)
            setTaxAmt(null)
          },
          icon: (
            <AddIcon
              htmlColor="white"
              fontSize="small"
              style={{
                width: '20px',
                height: '20px',
                margin: '0',
                background: theme.palette.primary.main,
                borderRadius: '3px',
                color: 'rgba(224,234,254,100)',
                marginTop: '10px',
                marginRight: '10px',
              }}
            />
          ),
        },
      }}
    >
      <div className="rm-padding table-wrap ">
        {itemData === undefined || itemData?.length === 0 ? (
          <EmptyList title="No Record Found" subtitle="Add New Record now." />
        ) : (
          itemData
            ?.sort((a, b) => {
              return a?.Sequence < b?.Sequence ? -1 : 1
            })
            ?.map((v, index) => {
              return (
                <>
                  <List className="borderless-list content-wrap full text-noflow">
                    <ListItem
                      divider={index < itemData?.length - 1 ? true : false}
                    >
                      <Grid spacing={1} container className="table-content">
                        <Grid
                          item
                          xs={1}
                          style={{
                            placeSelf: 'start',
                          }}
                        >
                          <span className="xxTitle">{`${index + 1}.`}</span>
                        </Grid>

                        <Grid
                          item
                          xs={5}
                          style={{
                            placeSelf: 'start',
                            marginTop: '8px',
                          }}
                        >
                          <div>
                            <div className="xxTitle text-noflow">
                              <span className="desc"> {v?.BillItemName}</span>
                            </div>
                            <div className="desc text-noflow">
                              {v?.CostCentreName}{' '}
                              {watch('is_einvoice') === true
                                ? ` | ${
                                    getClassificationCode?.filter(
                                      x =>
                                        x?.id ===
                                        v?.einvoice_classification_code_id
                                    )[0]?.classificationCode
                                  } (${
                                    getClassificationCode?.filter(
                                      x =>
                                        x?.id ===
                                        v?.einvoice_classification_code_id
                                    )[0]?.description
                                  })`
                                : null}
                            </div>
                            <div className="desc text-noflow">{v?.Remark}</div>
                          </div>
                        </Grid>

                        <Grid
                          item
                          xs={2}
                          style={{
                            placeSelf: 'start',
                            marginTop: '32px',
                            textAlign: 'right',
                            flexWrap: 'wrap',
                            display: 'flex',
                            justifyContent: 'end',
                          }}
                        >
                          <div>
                            <div className="desc">
                              {` ${v?.TaxCode} | ${v?.TaxRate}%`}
                            </div>
                          </div>
                        </Grid>

                        <Grid
                          item
                          xs={3}
                          style={{
                            placeSelf: 'start',
                            marginTop: '8px',
                            marginRight: 'auto',
                            textAlign: 'right',
                            flexWrap: 'wrap',
                            display: 'flex',
                            justifyContent: 'end',
                          }}
                        >
                          <div>
                            <div className="xxTitle">{amtStr(v?.DocAmt)}</div>
                            <div className="desc">{amtStr(v?.Amount)}</div>
                            <div className="desc">{amtStr(v?.TaxAmt)}</div>
                          </div>
                        </Grid>

                        <Grid
                          item
                          xs={1}
                          style={{
                            marginTop: '0px',
                          }}
                        ></Grid>
                      </Grid>

                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          aria-controls="menu-list"
                          aria-haspopup="true"
                          onClick={e => {
                            handleClick(e, v[`${keyItemID}`], index, v)
                            setCost(v?.Amount)
                          }}
                        >
                          <MoreVert />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  </List>
                </>
              )
            })
        )}
      </div>
    </CardContents>
  )
}
