import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core'
import { KeyboardArrowRight } from '@material-ui/icons'
import AppContext from 'containers/App/Store/AppContext'
import {
  useGetCompanySummaryLazyQuery,
  useGetStandardLetterLazyQuery,
} from 'generated/graphql'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'

export const StandardLetterListing = (props: any) => {
  const { mode } = props
  const { pageMode, CompanyID }: any = useParams()
  let history = useHistory()
  let location: any = useLocation()
  const { globalState, dispatch }: any = useContext(AppContext as any)
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const isHomeDefault = localStorage.getItem('isDefault')

  const [
    loadCompany,
    {
      loading: getCompanySummaryLoading,
      error: getCompanySummaryError,
      data: { getCompany: curCompany } = { getCompany: [] },
    },
  ] = useGetCompanySummaryLazyQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID: CompanyID },
  })

  const [
    loadStandardLetter,
    {
      loading: getStandardLetterLoading,
      error: getStandardLetterError,
      data: { getStandardLetter } = {
        getStandardLetter: [],
      },
    },
  ] = useGetStandardLetterLazyQuery({
    fetchPolicy: 'network-only',
  })

  let headerTitle

  switch (pageMode) {
    case 'general-ledger':
      headerTitle = 'General Ledger'
      break
    case 'cash-book':
      headerTitle = 'Cash Book'
      break
    case 'account-receivable':
      headerTitle = 'Account Receivable'
      break
    case 'account-payable':
      headerTitle = 'Account Payable'
      break
    case 'inventory-control':
      headerTitle = 'Inventory Control'
      break
    case 'general-purchase':
      headerTitle = 'General Purchase'
      break
  }

  useEffect(() => {
    if (pageMode === 'general-ledger') {
      loadStandardLetter({
        variables: {
          LetterCategory: 'GENERAL_LEDGER',
        },
      })
    } else if (pageMode === 'cash-book') {
      loadStandardLetter({
        variables: {
          LetterCategory: 'CASH_BOOK',
        },
      })
    } else if (pageMode === 'account-receivable') {
      loadStandardLetter({
        variables: {
          LetterCategory: 'ACC_RECEIVABLE',
        },
      })
    } else if (pageMode === 'account-payable') {
      loadStandardLetter({
        variables: {
          LetterCategory: 'ACC_PAYABLE',
        },
      })
    } else if (pageMode === 'inventory-control') {
      loadStandardLetter({
        variables: {
          LetterCategory: 'INVENTORY_CONTROL',
        },
      })
    } else if (pageMode === 'general-purchase') {
      loadStandardLetter({
        variables: {
          LetterCategory: 'GENERAL_PURCHASE',
        },
      })
    }

    if (mode === 'company-setting') {
      loadCompany({ variables: { CompanyID: CompanyID } })
    }
  }, [pageMode])

  return (
    <>
      {getCompanySummaryLoading && <Loading />}
      {getStandardLetterLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() => {
          history.push({
            pathname:
              mode === 'general-setting'
                ? `/${pageMode}/general-setting`
                : `/${pageMode}/${CompanyID}/company-setting`,
          })
        }}
        smTitle={headerTitle}
        title={user?.companyName}
        routeSegments={
          mode === 'general-setting'
            ? [
                { name: 'System Admin' },
                { name: 'System Admin' },
                { name: 'Standard Letter', current: true },
              ]
            : [
                { name: 'System Admin' },
                { name: 'Company Standard Letter', current: true },
              ]
        }
      />
      {mode === 'company-setting' ? (
        <DynamicSubHeader title={curCompany[0]?.Name} />
      ) : null}

      <ContentWrapper
        singleDynamicInfo={mode === 'company-setting' ? true : false}
      >
        {getStandardLetter?.length === 0 ? (
          <EmptyList
            title="No Standard Letter"
            subtitle="Please create new Standard Letter"
          />
        ) : (
          <List className="core-list">
            {getStandardLetter?.map((v, i) => (
              <ListItem
                onClick={() =>
                  history.push({
                    pathname:
                      mode === 'general-setting'
                        ? `/${pageMode}/general-setting/standard-letter/${v?.StandardLetterID}/letter-template`
                        : `/${pageMode}/${CompanyID}/company-setting/company-standard-letter/${v?.StandardLetterID}/company-letter-template`,
                  })
                }
              >
                <ListItemText
                  primary={<div className="xsTitle">{v?.Name}</div>}
                  secondary={<span className="desc">{v?.LetterType}</span>}
                />
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="delete">
                    <KeyboardArrowRight
                      onClick={() =>
                        history.push({
                          pathname:
                            mode === 'general-setting'
                              ? `/${pageMode}/general-setting/standard-letter/${v?.StandardLetterID}/letter-template`
                              : `/${pageMode}/${CompanyID}/company-setting/company-standard-letter/${v?.StandardLetterID}/company-letter-template`,
                        })
                      }
                    />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        )}
      </ContentWrapper>
    </>
  )
}
