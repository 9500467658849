import React from 'react'
import advancesRoutes from './ARAdvance/ARAdvanceRoutes'
import arCreditNoteRoute from './ARCreditNote/ARCreditNoteRoutes'
import debitNoteRoutes from './ARDebitNote/ARDebitNoteRoutes'
import arDigitalReportRoutes from './ARDigitalReport/ARDigitalReportRoutes'
import arGLExportRoutes from './ARGLExport/ARGLExportRoutes'
import arInvoiceRoutes from './ARInvoice/ARInvoiceRoutes'
import receiptRoutes from './AROfficialReceipt/AROfficialReceiptRoutes'
import refundRoutes from './ARRefund/ARRefundRoutes'
import { ARSubmenu } from './ARSubmenu'
import billItemRoutes from './BillItem/BillItemRoutes'
import debtorAccountRoutes from './DebtorAccount/DebtorAccountRoutes'
import arConsolidatedEInvoiceRoutes from './ARConsolidateEInvoice/ARConsolidateEInvoiceRoutes'

const ARRoutes = [
  {
    props: { exact: true, path: '/account-receivable/:CompanyID' },
    component: <ARSubmenu accountType="account-receivable" />,
  },
  ...debtorAccountRoutes,
  ...arInvoiceRoutes,
  ...debitNoteRoutes,
  ...refundRoutes,
  ...advancesRoutes,
  ...receiptRoutes,
  ...arCreditNoteRoute,
  ...billItemRoutes,
  ...arDigitalReportRoutes,
  ...arGLExportRoutes,
  ...arConsolidatedEInvoiceRoutes,

  // ...creditorProfileRoutes,
  // ...creditordebtorRoutes,
  // ...generalPostingListingRoutes,
]

export default ARRoutes
