import React from 'react'
import { APSubmenu } from '../APSubmenu'
import { CreditorAccountDetail } from './CreditorAccountDetail'
import { CreditorAccountListing } from './CreditorAccountListing'
import { CreditorAccountTrxListing } from './CreditorAccountTrxListing'
import { CreditorProfileForm } from 'containers/GeneralSettingModule/CreditorProfile/CreditorProfileForm'
import { CreditorProfileDetail } from 'containers/GeneralSettingModule/CreditorProfile/CreditorProfileDetail'

export const creditorAccountRoutes = [
  {
    props: {
      exact: true,
      path: `/account-payable/:CompanyID/creditor-account`,
    },
    component: <CreditorAccountListing />,
  },

  {
    props: {
      exact: true,
      path: `/account-payable/:CompanyID/creditor-account/creditor-profile/:CreditorAccountID/detail`,
    },
    component: <CreditorProfileDetail page="creditor-account" />,
  },

  {
    props: {
      exact: true,
      path:
        '/account-payable/:CompanyID/creditor-account/creditor-profile/:CreditorAccountID/edit',
    },
    component: <CreditorProfileForm mode="edit" page="creditor-account" />,
  },

  {
    props: {
      exact: true,
      path: '/account-payable/:CompanyID/creditor-account/creditor-profile/add',
    },
    component: <CreditorProfileForm mode="add" page="creditor-account" />,
  },

  /* -------------------------------------------- */
  /*               CREDITOR SUBMENU               */
  /* -------------------------------------------- */
  {
    props: {
      exact: true,
      path:
        '/account-payable/:CompanyID/creditor-account/creditor-profile/:CreditorAccountID',
    },
    component: <APSubmenu />,
  },

  {
    props: {
      exact: true,
      path:
        '/account-payable/:CompanyID/creditor-account/creditor-profile/:CreditorAccountID/invoice',
    },
    component: <CreditorAccountTrxListing docType="invoice" />,
  },
  {
    props: {
      exact: true,
      path:
        '/account-payable/:CompanyID/creditor-account/creditor-profile/:CreditorAccountID/credit-note',
    },
    component: <CreditorAccountTrxListing docType="credit-note" />,
  },
  {
    props: {
      exact: true,
      path:
        '/account-payable/:CompanyID/creditor-account/creditor-profile/:CreditorAccountID/creditor-credit-note',
    },
    component: <CreditorAccountTrxListing docType="creditor-credit-note" />,
  },
  {
    props: {
      exact: true,
      path:
        '/account-payable/:CompanyID/creditor-account/creditor-profile/:CreditorAccountID/debit-note',
    },
    component: <CreditorAccountTrxListing docType="debit-note" />,
  },
  {
    props: {
      exact: true,
      path:
        '/account-payable/:CompanyID/creditor-account/creditor-profile/:CreditorAccountID/creditor-debit-note',
    },
    component: <CreditorAccountTrxListing docType="creditor-debit-note" />,
  },
  {
    props: {
      exact: true,
      path:
        '/account-payable/:CompanyID/creditor-account/creditor-profile/:CreditorAccountID/payment',
    },
    component: <CreditorAccountTrxListing docType="payment" />,
  },
  {
    props: {
      exact: true,
      path:
        '/account-payable/:CompanyID/creditor-account/creditor-profile/:CreditorAccountID/refund',
    },
    component: <CreditorAccountTrxListing docType="refund" />,
  },
  {
    props: {
      exact: true,
      path:
        '/account-payable/:CompanyID/creditor-account/creditor-profile/:CreditorAccountID/advance',
    },
    component: <CreditorAccountTrxListing docType="advance" />,
  },
]

export default creditorAccountRoutes
