import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { TablePagination } from '@material-ui/core'
import { dateFormat } from 'helpers/StringNumberFunction/FormatDate'
import { amtStr } from '@ifca-root/react-component/src/helpers/StringNumberFunction/numFormatter'

export const ConsolidateTable = (props: any) => {
  const {
    data,
    rowsPerPage,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
  } = props

  return (
    <>
      <TableContainer component={Paper}>
        <Table style={{ minWidth: '700' }} size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell
                style={{
                  whiteSpace: 'nowrap',
                  backgroundColor: '#ff9800',
                  width: '10%',
                }}
              >
                Doc Date
              </StyledTableCell>
              <StyledTableCell
                style={{
                  whiteSpace: 'nowrap',
                  backgroundColor: '#ff9800',
                  width: '15%',
                }}
              >
                Doc No
              </StyledTableCell>
              <StyledTableCell
                style={{
                  whiteSpace: 'nowrap',
                  backgroundColor: '#ff9800',
                  width: '40%',
                }}
              >
                Debtor Name
              </StyledTableCell>
              <StyledTableCell
                style={{
                  whiteSpace: 'nowrap',
                  backgroundColor: '#ff9800',
                  textAlign: 'right',
                  width: '15%',
                }}
              >
                Doc Amount
              </StyledTableCell>
              <StyledTableCell
                style={{
                  whiteSpace: 'nowrap',
                  backgroundColor: '#ff9800',
                  textAlign: 'right',
                  width: '15%',
                }}
              >
                Tax Amount
              </StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {data?.map((v, i) => {
              return (
                <TableRow>
                  <StyledTableCell size="small">
                    {dateFormat(v?.DocDate)}
                  </StyledTableCell>
                  <StyledTableCell size="small">{v?.DocNo}</StyledTableCell>
                  <StyledTableCell size="small">
                    {v?.DebtorName}
                  </StyledTableCell>
                  <StyledTableCell size="small" style={{ textAlign: 'right' }}>
                    {amtStr(v?.DocAmt)}
                  </StyledTableCell>
                  <StyledTableCell size="small" style={{ textAlign: 'right' }}>
                    {amtStr(v?.TaxAmt)}
                  </StyledTableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100, 200]}
          component="div"
          count={data?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  )
}

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#2F53E6',
    color: theme.palette.common.white,
    fontWeight: 600,
    fontSize: 12,
  },
  body: {
    color: theme.palette.common.black,
    fontWeight: 600,
    fontSize: 12,
  },
}))(TableCell)
