import { AcctPermission } from 'generated/graphql'

export const dropdownOption = [
  {
    path: `/digital-reports/general-ledger`,
    defaultPath: `/digital-reports/general-ledger`,
    // icon: GeneralLedger,
    title: `General Ledger`,
    label: `General Ledger Reports`,
    // data1: ``,
    // data2: ``,
    // data3: ``,
    permission: AcctPermission.GeneralLedgerDigitalReportsView,
    disabled: false,
  },
  {
    path: `/digital-reports/cash-book`,
    defaultPath: `/digital-reports/cash-book`,
    // icon: CashBook,
    title: `Cash Book`,
    label: `Cash Book Reports`,
    permission: AcctPermission.CashBookDigitalReportView,
    disabled: false,
    isDefault: false,
    deployed: true,
  },
  {
    path: `/digital-reports/account-receivable`,
    defaultPath: `/digital-reports/account-receivable`,
    // icon: AcctReceivable,
    title: `Account Receivable`,
    label: `Account Receivable Reports`,
    permission: AcctPermission.AccReceivableDigitalReportsView,
    deployed: true,
    disabled: false,
  },
  {
    path: `/digital-reports/account-payable`,
    defaultPath: `/digital-reports/account-payable`,
    // icon: AcctPayable,
    title: `Account Payable`,
    label: `Account Payable Reports`,
    permission: AcctPermission.AccPayableDigitalReportView,
    deployed: true,
    disabled: false,
  },
  {
    path: `/digital-reports/inventory-control`,
    defaultPath: `/digital-reports/inventory-control`,
    // icon: InvControl,
    title: `Inventory Control`,
    label: `Inventory Control Reports`,
    permission: AcctPermission.InventoryControlDigitalReportsView,
    disabled: false,
    isDefault: false,
    deployed: true,
  },
  {
    path: `/digital-reports/general-purchase`,
    defaultPath: `/digital-reports/general-purchase`,
    // icon: GenPurchases,
    title: `General Purchase`,
    label: `General Purchase Reports`,
    deployed: process.env.REACT_APP_LOCAL_DB === 'prod' ? false : true,
    disabled: false,
    permission: AcctPermission.GeneralPurchaseDigitalReportsView,
  },
]
