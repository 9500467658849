import {
  ModuleType,
  DocumentType,
  useGetApAdvanceLazyQuery,
  useGetApCreditNoteLazyQuery,
  useGetApCreditorCreditNoteLazyQuery,
  useGetApDebitNoteLazyQuery,
  useGetApPaymentLazyQuery,
  useGetApRefundLazyQuery,
  useGetArAdvanceLazyQuery,
  useGetArCreditNoteLazyQuery,
  useGetArDebitNoteLazyQuery,
  useGetArInvoiceLazyQuery,
  useGetArOfficialReceiptLazyQuery,
  useGetArRefundLazyQuery,
  useGetLetterTemplateByModuleLazyQuery,
} from 'generated/graphql'
import { useEffect } from 'react'

export const useQueries: any = ({
  accountType,
  CreditorDebtorAccountID,
  docType,
  CompanyID,
  ReceiptID,
  DocumentID,
}) => {
  //----------AR Queries----------//
  const [
    fetchARAdvance,
    {
      loading: arAdvanceLoading,
      called: arAdvanceCalled,
      data: { getARAdvance } = { getARAdvance: [] },
    },
  ] = useGetArAdvanceLazyQuery({
    fetchPolicy: 'network-only',
  })

  const [
    fetchARCreditNote,
    {
      loading: arCreditNoteLoading,
      called: arCreditNoteCalled,
      data: { getARCreditNote } = { getARCreditNote: [] },
    },
  ] = useGetArCreditNoteLazyQuery({
    fetchPolicy: 'network-only',
  })

  const [
    fetchARDebitNote,
    {
      loading: arDebitNoteLoading,
      called: arDebitNoteCalled,
      data: { getARDebitNote } = { getARDebitNote: [] },
    },
  ] = useGetArDebitNoteLazyQuery({
    fetchPolicy: 'network-only',
  })

  const [
    fetchARInvoice,
    {
      loading: ArInvoiceLoading,
      called: ArInvoiceCalled,
      data: { getARInvoice } = { getARInvoice: [] },
    },
  ] = useGetArInvoiceLazyQuery({
    fetchPolicy: 'network-only',
  })

  const [
    fetchAROfficialReceipt,
    {
      loading: AROfficialReceiptLoading,
      called: AROfficialReceiptCalled,
      data: { getAROfficialReceipt } = { getAROfficialReceipt: [] },
    },
  ] = useGetArOfficialReceiptLazyQuery({
    fetchPolicy: 'network-only',
  })

  const [
    fetchARRefund,
    {
      loading: ARRefundLoading,
      called: ARRefundCalled,
      data: { getARRefund } = { getARRefund: [] },
    },
  ] = useGetArRefundLazyQuery({
    fetchPolicy: 'network-only',
  })

  //----------AP Queries----------//
  const [
    fetchAPAdvance,
    {
      loading: apAdvanceLoading,
      called: apAdvanceCalled,
      data: { getAPAdvance } = { getAPAdvance: [] },
    },
  ] = useGetApAdvanceLazyQuery({
    fetchPolicy: 'network-only',
  })

  const [
    fetchAPCreditNote,
    {
      loading: apCreditNoteLoading,
      called: apCreditNoteCalled,
      data: { getAPCreditNote } = { getAPCreditNote: [] },
    },
  ] = useGetApCreditNoteLazyQuery({
    fetchPolicy: 'network-only',
  })

  const [
    fetchAPCreditorCreditNote,
    {
      loading: apCreditorCreditNoteLoading,
      called: apCreditorCreditNoteCalled,
      data: { getAPCreditorCreditNote } = { getAPCreditorCreditNote: [] },
    },
  ] = useGetApCreditorCreditNoteLazyQuery({
    fetchPolicy: 'network-only',
  })

  const [
    fetchAPDebitNote,
    {
      loading: apDebitNoteLoading,
      called: apDebitNoteCalled,
      data: { getAPDebitNote } = { getAPDebitNote: [] },
    },
  ] = useGetApDebitNoteLazyQuery({
    fetchPolicy: 'network-only',
  })

  const [
    fetchAPPayment,
    {
      loading: apPaymentLoading,
      called: apPaymentCalled,
      data: { getAPPayment } = { getAPPayment: [] },
    },
  ] = useGetApPaymentLazyQuery({
    fetchPolicy: 'network-only',
  })

  const [
    fetchAPRefund,
    {
      loading: apRefundLoading,
      called: apRefundCalled,
      data: { getAPRefund } = { getAPRefund: [] },
    },
  ] = useGetApRefundLazyQuery({
    fetchPolicy: 'network-only',
  })

  let loading, called, listData, subMenuName, primaryKey, templateBody

  const [
    fetchLetterTemplate,
    {
      loading: LetterTemplateLoading,
      called: LetterTemplateCalled,
      data: { getLetterTemplateByModule } = { getLetterTemplateByModule: '' },
    },
  ] = useGetLetterTemplateByModuleLazyQuery({
    fetchPolicy: 'network-only',
    // onCompleted: ({ getLetterTemplate }) => {},
  })

  switch (accountType) {
    //----------AR----------//
    case 'account-receivable':
      switch (docType) {
        case 'advance':
          loading = arAdvanceLoading
          called = arAdvanceCalled
          listData = getARAdvance
          subMenuName = 'Advance'
          primaryKey = 'AdvanceID'
          break

        case 'credit-note':
          loading = arCreditNoteLoading
          called = arCreditNoteCalled
          listData = getARCreditNote
          subMenuName = 'Credit Note'
          primaryKey = 'CreditNoteID'
          templateBody = getLetterTemplateByModule
          break

        case 'debit-note':
          loading = arDebitNoteLoading
          called = arDebitNoteCalled
          listData = getARDebitNote
          subMenuName = 'Debit Note'
          primaryKey = 'DebitNoteID'
          templateBody = getLetterTemplateByModule
          break

        case 'invoice':
          loading = ArInvoiceLoading
          called = ArInvoiceCalled
          listData = getARInvoice
          subMenuName = 'Invoice'
          primaryKey = 'InvoiceID'
          templateBody = getLetterTemplateByModule
          break
        case 'official-receipt':
          loading = AROfficialReceiptLoading
          called = AROfficialReceiptCalled
          listData = getAROfficialReceipt
          subMenuName = 'Official Receipt'
          primaryKey = 'ReceiptID'
          templateBody = getLetterTemplateByModule
          break
        case 'refund':
          loading = ARRefundLoading
          called = ARRefundCalled
          listData = getARRefund
          subMenuName = 'Refund'
          primaryKey = 'RefundID'
          templateBody = getLetterTemplateByModule
          break
      }
      break
    //----------AP----------//
    case 'account-payable':
      switch (docType) {
        case 'advance':
          loading = apAdvanceLoading
          called = apAdvanceCalled
          listData = getAPAdvance
          subMenuName = 'Advance'
          primaryKey = 'AdvanceID'
          templateBody = getLetterTemplateByModule
          break

        // case 'invoice':
        //   loading = apInvoiceLoading
        //   called = apInvoiceCalled
        //   listData = getAPInvoice
        //   subMenuName = 'Invoices'
        //   primaryKey = 'APInvoiceID'
        //   break

        case 'credit-note':
          loading = apCreditNoteLoading
          called = apCreditNoteCalled
          listData = getAPCreditNote
          subMenuName = 'Credit Note'
          primaryKey = 'CreditNoteID'
          templateBody = getLetterTemplateByModule
          break

        case 'debit-note':
          loading = apDebitNoteLoading
          called = apDebitNoteCalled
          listData = getAPDebitNote
          subMenuName = 'Debit Note'
          primaryKey = 'DebitNoteID'
          templateBody = getLetterTemplateByModule
          break

        case 'payment':
          loading = apPaymentLoading
          called = apPaymentCalled
          listData = getAPPayment
          subMenuName = 'Payment'
          primaryKey = 'PaymentID'
          templateBody = getLetterTemplateByModule
          break

        case 'refund':
          loading = apRefundLoading
          called = apRefundCalled
          listData = getAPRefund
          subMenuName = 'Refund'
          primaryKey = 'RefundID'
          templateBody = getLetterTemplateByModule
          break
      }
      break
  }

  useEffect(() => {
    switch (accountType) {
      //----------AR----------//
      case 'account-receivable':
        switch (docType) {
          case 'advance':
            if (CreditorDebtorAccountID) {
              fetchARAdvance({
                variables: {
                  CompanyID: CompanyID,
                  orderByDesc: 'DocDate',
                  DebtorAccountID: CreditorDebtorAccountID,
                  AdvanceID: DocumentID,
                },
              })
            } else {
              fetchARAdvance({
                variables: {
                  CompanyID: CompanyID,
                  orderByDesc: 'DocDate',
                  AdvanceID: DocumentID,
                },
              })
            }
            break

          case 'credit-note':
            if (CreditorDebtorAccountID) {
              fetchARCreditNote({
                variables: {
                  CompanyID: CompanyID,
                  orderByDesc: 'DocDate',
                  DebtorAccountID: CreditorDebtorAccountID,
                  CreditNoteID: DocumentID,
                },
              })
            } else {
              fetchARCreditNote({
                variables: {
                  CompanyID: CompanyID,
                  orderByDesc: 'DocDate',
                  CreditNoteID: DocumentID,
                },
              })
              fetchLetterTemplate({
                variables: {
                  LetterCategory: ModuleType.Ar,
                  LetterType: DocumentType.CreditNote,
                  CompanyID: CompanyID,
                },
              })
            }

            break

          case 'debit-note':
            if (CreditorDebtorAccountID) {
              fetchARDebitNote({
                variables: {
                  CompanyID: CompanyID,
                  orderByDesc: 'DocDate',
                  DebtorAccountID: CreditorDebtorAccountID,
                  DebitNoteID: DocumentID,
                },
              })
            } else {
              fetchARDebitNote({
                variables: {
                  CompanyID: CompanyID,
                  orderByDesc: 'DocDate',
                  DebitNoteID: DocumentID,
                },
              })
              fetchLetterTemplate({
                variables: {
                  LetterCategory: ModuleType.Ar,
                  LetterType: DocumentType.DebitNote,
                  CompanyID: CompanyID,
                },
              })
            }
            break

          case 'invoice':
            if (CreditorDebtorAccountID) {
              fetchARInvoice({
                variables: {
                  CompanyID: CompanyID,
                  orderByDesc: 'DocDate',
                  DebtorAccountID: CreditorDebtorAccountID,
                  InvoiceID: DocumentID,
                },
              })
            } else {
              fetchARInvoice({
                variables: {
                  CompanyID: CompanyID,
                  orderByDesc: 'DocDate',
                  InvoiceID: DocumentID,
                },
              })
              fetchLetterTemplate({
                variables: {
                  LetterCategory: ModuleType.Ar,
                  LetterType: DocumentType.Invoice,
                  CompanyID: CompanyID,
                },
              })
            }
            break

          case 'official-receipt':
            fetchAROfficialReceipt({
              variables: {
                CompanyID: CompanyID,
                orderByDesc: 'DocDate',
                ReceiptID: ReceiptID,
              },
            })
            fetchLetterTemplate({
              variables: {
                LetterCategory: ModuleType.Ar,
                LetterType: DocumentType.OfficialReceipt,
                CompanyID: CompanyID,
              },
            })
            break

          case 'refund':
            if (CreditorDebtorAccountID) {
              fetchARRefund({
                variables: {
                  CompanyID: CompanyID,
                  orderByDesc: 'DocDate',
                  DebtorAccountID: CreditorDebtorAccountID,
                  RefundID: DocumentID,
                },
              })
            } else {
              fetchARRefund({
                variables: {
                  CompanyID: CompanyID,
                  orderByDesc: 'DocDate',
                  RefundID: DocumentID,
                },
              })
              fetchLetterTemplate({
                variables: {
                  LetterCategory: ModuleType.Ar,
                  LetterType: DocumentType.Refund,
                  CompanyID: CompanyID,
                },
              })
            }
            break
        }
        break
      //----------AP----------//
      case 'account-payable':
        switch (docType) {
          case 'advance':
            if (CreditorDebtorAccountID)
              fetchAPAdvance({
                variables: {
                  CompanyID: CompanyID,
                  orderByDesc: 'DocDate',
                  CreditorAccountID: CreditorDebtorAccountID,
                  AdvanceID: DocumentID,
                },
              })
            else
              fetchAPAdvance({
                variables: {
                  CompanyID: CompanyID,
                  orderByDesc: 'DocDate',
                  AdvanceID: DocumentID,
                },
              })
            fetchLetterTemplate({
              variables: {
                LetterCategory: ModuleType.Ap,
                LetterType: DocumentType.Advance,
                CompanyID: CompanyID,
              },
            })
            break

          case 'credit-note':
            if (CreditorDebtorAccountID) {
              fetchAPCreditNote({
                variables: {
                  CompanyID: CompanyID,
                  orderByDesc: 'DocDate',
                  CreditorAccountID: CreditorDebtorAccountID,
                  CreditNoteID: DocumentID,
                },
              })
            } else
              fetchAPCreditNote({
                variables: {
                  CompanyID: CompanyID,
                  orderByDesc: 'DocDate',
                  CreditNoteID: DocumentID,
                },
              })
            fetchLetterTemplate({
              variables: {
                LetterCategory: ModuleType.Ap,
                LetterType: DocumentType.CreditNote,
                CompanyID: CompanyID,
              },
            })
            break

          case 'creditor-credit-note':
            if (CreditorDebtorAccountID)
              fetchAPCreditorCreditNote({
                variables: {
                  CompanyID: CompanyID,
                  orderByDesc: 'DocDate',
                  CreditorAccountID: CreditorDebtorAccountID,
                  CreditorCreditNoteID: DocumentID,
                },
              })
            else
              fetchAPCreditorCreditNote({
                variables: {
                  CompanyID: CompanyID,
                  orderByDesc: 'DocDate',
                  CreditorCreditNoteID: DocumentID,
                },
              })
            break

          case 'debit-note':
            if (CreditorDebtorAccountID) {
              fetchAPDebitNote({
                variables: {
                  CompanyID: CompanyID,
                  orderByDesc: 'DocDate',
                  CreditorAccountID: CreditorDebtorAccountID,
                  DebitNoteID: DocumentID,
                },
              })
            } else {
              fetchAPDebitNote({
                variables: {
                  CompanyID: CompanyID,
                  orderByDesc: 'DocDate',
                  DebitNoteID: DocumentID,
                },
              })
              fetchLetterTemplate({
                variables: {
                  LetterCategory: ModuleType.Ap,
                  LetterType: DocumentType.DebitNote,
                  CompanyID: CompanyID,
                },
              })
            }
            break

          case 'payment':
            fetchAPPayment({
              variables: {
                CompanyID: CompanyID,
                orderByDesc: 'DocDate',
                PaymentID: DocumentID,
              },
            })
            fetchLetterTemplate({
              variables: {
                LetterCategory: ModuleType.Ap,
                LetterType: DocumentType.Payment,
                CompanyID: CompanyID,
              },
            })
            break

          case 'refund':
            fetchAPRefund({
              variables: {
                CompanyID: CompanyID,
                orderByDesc: 'DocDate',
                RefundID: DocumentID,
              },
            })
            break
        }
        break
    }
  }, [accountType, docType])

  return {
    loading,
    called,
    listData,
    subMenuName,
    primaryKey,
    templateBody,
  }
}
