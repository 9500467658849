import { yupResolver } from '@hookform/resolvers'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import { ItemVoiceTextField } from '@ifca-root/react-component/src/components/Input/CustomItemTextField'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { Grid, MenuItem, TextField } from '@material-ui/core'
import { Autocomplete, createFilterOptions } from '@material-ui/lab'
import BigNumber from 'bignumber.js'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'
import {
  RecordStatus,
  useGetClassificationCodeLazyQuery,
  useGetCostCentreCodeLazyQuery,
  useGetDocumentDateValidationQuery,
  useGetExpenseClassCoaidQuery,
  useGetParentExpenseItemCategoryCoaAccountTypeLazyQuery,
  useGetTaxEffectiveDateQuery,
  useGetTaxSchemeQuery,
  useGetUomExchangeListLazyQuery,
} from 'generated/graphql'
import { CommonYupValidation } from 'helpers/Form/YupValidation'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { amtNumStr, amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import * as yup from 'yup'
import {
  baseAmount,
  getTotalAmtWithQty,
} from '../../ARAPModule/Helper/AmountCalculation'

interface ItemProps {
  Quantity: number
  ExpenseItemID: string
  UOMID: string
  Amount: number
  UnitPrice: number
  DocAmt: number
  TaxSchemeID: string
  TaxAmt: number
  TaxRate: number
  CostCentreID: string
  Remark: string
  einvoice_classification_code_id: string
}

const defaultVal: any = {
  ExpenseItemID: '',
  Amount: 0,
  DocAmt: 0,
  UnitPrice: 0,
  TaxSchemeID: '',
  TaxAmt: 0,
  TaxRate: 0,
  CostCentreID: '',
  Remark: '',
  UOMID: '',
  Quantity: 0,
  einvoice_classification_code_id: '',
}

export const ItemDialog = (props: any) => {
  const {
    companyID,
    openItemDialog,
    setOpenItemDialog,
    itemData,
    ExpenseItemLoading,
    fetchExpenseItem,
    getItemByCompany,
    title,
    watch,
    detailMode,
    menu,
    selectedTax,
    setSelectedTax,
    primaryItemKey,
    defaultCostCentre,
    masterCOALoading,
    getMasterCOA,
    selfBillStatus,
    EInvoiceStarted,
    creditorAcc,
  } = props

  const ItemSchema = yup.object().shape({
    ExpenseItemID: CommonYupValidation.requireField('Item is required'),
    TaxSchemeID: CommonYupValidation.requireField('Tax Code is required'),
    UOMID: yup.string().required(SystemMsgs.uom()),
    CostCentreID: CommonYupValidation.requireField('Department is required'),
    Quantity: yup
      .number()
      .transform((originalValue, originalObject) => {
        if (originalValue?.length > 3) {
          return parseInt(originalObject.replace(/,/g, ''))
        } else {
          return parseFloat(originalObject)
        }
      })
      .min(1, SystemMsgs.quantity())
      .required('Qty cannot be less than 1'),
    UnitPrice: yup
      .number()
      .transform((originalValue, originalObject) => {
        if (originalValue?.length > 3) {
          return parseInt(originalObject.replace(/,/g, ''))
        } else {
          return parseFloat(originalObject)
        }
      })
      .min(0.0001, 'Quantity must be at least 0.0001')
      .required('Unit Price is required'),
    Remark: CommonYupValidation.requireField('Remark is required'),
    ...(!!selfBillStatus && EInvoiceStarted
      ? {
          einvoice_classification_code_id: CommonYupValidation.requireField(
            SystemMsgs.ClassificationCode()
          ),
        }
      : null),
  })

  const {
    handleSubmit: handleItemSubmit,
    register: itemRegister,
    setValue: setValueItem,
    watch: itemWatch,
    errors: itemErrors,
    control: itemControl,
    clearErrors: clearErrorsItem,
    getValues: getValuesItem,
    reset: itemReset,
  } = useForm<ItemProps>({
    defaultValues: {
      ExpenseItemID: !!menu ? menu?.obj?.ExpenseItemID : '',
      CostCentreID: !!menu ? menu?.obj?.CostCentreID : '',
      Amount: detailMode === 'edit' ? menu?.obj?.Amount : 0,
      UnitPrice: detailMode === 'edit' ? menu?.obj?.UnitPrice : 0,
      DocAmt: detailMode === 'edit' ? menu?.obj?.DocAmt : 0,
      TaxSchemeID: !!menu ? menu?.obj?.TaxSchemeID : '',
      TaxAmt: 0,
      TaxRate: 0,
      Remark: !!menu ? menu?.obj?.Remark : '',
      einvoice_classification_code_id: !!menu
        ? menu?.obj?.einvoice_classification_code_id
        : '',
    },
    mode: 'onSubmit',
    resolver: yupResolver(ItemSchema),
  })

  const itemEditData =
    !!menu && menu?.obj?.ExpenseItemID
      ? getItemByCompany?.filter(
          item => item?.ExpenseItemID === menu?.obj?.ExpenseItemID
        )[0]
      : null

  const [cost, setCost] = useState<any>(
    detailMode === 'edit' ? menu?.obj?.UnitPrice : 0
  )

  const [qty, setQty] = useState(
    detailMode === 'edit' ? menu?.obj?.Quantity : 0
  )

  const [uomID, setUomID] = useState('')

  const [taxAmt, setTaxAmt] = useState(
    detailMode === 'edit' ? menu?.obj?.TaxAmt : 0
  )

  const [errorDia, setErrorDia] = useState<boolean>(false)
  const [errMsg, setErrMsg] = useState<string>('')

  const [itemRecord, setItemRecord] = useState(false)
  const [itemVoiceRemark, setItemVoiceRemark] = useState('')

  const [itemColor, setItemColor] = useState(
    itemEditData !== null
      ? itemEditData?.IsInventory === true
        ? 'darkblue'
        : ''
      : ''
  )

  // Account Type for Default Department
  const [accountType, setAccountType] = useState('')

  const docAmt = getTotalAmtWithQty(qty, cost, taxAmt)

  const [classificationCodeID, setClassificationCodeID] = useState(
    !!menu ? menu?.obj?.einvoice_classification_code_id : null
  )

  // e-Invoice Query
  const [
    loadClassificationCode,
    {
      loading: classificationCodeLoading,
      data: { getClassificationCode } = { getClassificationCode: [] },
    },
  ] = useGetClassificationCodeLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: data => {
      if (!!classificationCodeID) {
        itemRegister('einvoice_classification_code_id')
        setValueItem(
          'einvoice_classification_code_id',
          data.getClassificationCode.filter(
            x => x?.id === classificationCodeID
          )[0].id
        )
      }
    },
  })

  const classificationCode = !!classificationCodeID
    ? getClassificationCode?.filter(x => x?.id === classificationCodeID)[0]
        ?.classificationCode
    : getClassificationCode?.filter(
        x => x?.id === menu?.obj?.einvoice_classification_code_id
      )[0]?.classificationCode

  const classificationDescription = !!classificationCodeID
    ? getClassificationCode?.filter(x => x?.id === classificationCodeID)[0]
        ?.description
    : getClassificationCode?.filter(
        x => x?.id === menu?.obj?.einvoice_classification_code_id
      )[0]?.description

  // Tax
  const {
    loading: taxSchemeLoading,
    data: { getTaxScheme } = { getTaxScheme: [] },
  } = useGetTaxSchemeQuery({
    variables: { RecordStatus: RecordStatus.Active },
    onCompleted: () => {
      if (detailMode === 'edit') {
        setSelectedTax(
          taxScheme?.find(x => x?.TaxSchemeID === menu?.obj?.TaxSchemeID)
        )
      }
    },
  })

  const [
    fetchUOM,
    {
      loading: uomExcLoading,
      data: { getUOMExchangeList } = { getUOMExchangeList: [] },
    },
  ] = useGetUomExchangeListLazyQuery({
    fetchPolicy: 'network-only',
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
      setValueItem('UOMID', null)
    },
    onCompleted: data => {
      if (!!uomID) {
        itemRegister('UOMID')
        setValueItem(
          'UOMID',
          data.getUOMExchangeList.filter(x => x?.uomid === uomID)[0].uomid
        )
      }
    },
  })

  const [
    fetchParentExpenseItemCOAAccountType,
    {
      loading: ParentItemCategoryLoading,
      data: { getParentExpenseItemCategoryCOAAccountType } = {
        getParentExpenseItemCategoryCOAAccountType: [],
      },
    },
  ] = useGetParentExpenseItemCategoryCoaAccountTypeLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ getParentExpenseItemCategoryCOAAccountType }) => {
      if (!!getParentExpenseItemCategoryCOAAccountType) {
        handleDefaultCostCentre(
          getParentExpenseItemCategoryCOAAccountType[0]?.MasterCOAID
        )
      }
    },
  })

  const {
    loading: docDateValidationLoading,
    error: DocDateValidationError,
    data: { getDocumentDateValidation } = { getDocumentDateValidation: [] },
  } = useGetDocumentDateValidationQuery({
    fetchPolicy: 'network-only',
  })

  //TaxEffectiveDate
  const {
    loading: taxEffectiveDateLoading,
    error: TaxEffectiveDateError,
    data: { getTaxEffectiveDate } = { getTaxEffectiveDate: [] },
  } = useGetTaxEffectiveDateQuery({
    fetchPolicy: 'network-only',
  })

  //Expense Item Class
  const {
    loading: ExpenseClassLoading,
    error: ExpenseClassError,
    data: { getExpenseClass } = { getExpenseClass: [] },
  } = useGetExpenseClassCoaidQuery({
    fetchPolicy: 'network-only',
  })

  //CostCentre
  const [
    fetchCostCentre,
    {
      loading: CostCentreLoading,
      error: CostCentreErrors,
      data: { getCostCentre } = { getCostCentre: [] },
    },
  ] = useGetCostCentreCodeLazyQuery({
    fetchPolicy: 'network-only',
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(`${errorMessage}"`)
    },
  })

  const taxScheme: any = getTaxScheme
  // To get list of Tax Code that has been effective //
  const taxEffectiveDate: any = new Date(
    new Date().getTime() + 24 * 60 * 60 * 1000
  )
    .toISOString()
    .slice(0, 10)

  const taxSchemeInput: any = getTaxScheme?.filter(
    el =>
      el?.AccTaxClass === 'INPUT' &&
      el?.TaxEffective.map(y => y?.Date) <= taxEffectiveDate
  )

  const taxCodeName = getTaxScheme?.filter(
    x => x?.TaxSchemeID === selectedTax?.TaxSchemeID
  )[0]?.Code

  const taxCodeRate = amtStr(
    getTaxScheme?.filter(x => x?.TaxSchemeID === selectedTax?.TaxSchemeID)[0]
      ?.LatestTax?.Rate
  )

  const costCentreCode =
    itemWatch('CostCentreID') !== undefined
      ? getCostCentre.filter(
          x => x?.CostCentreID === itemWatch('CostCentreID')
        )[0]?.Name
      : getCostCentre.filter(
          x => x?.CostCentreID === menu?.obj?.CostCentreID
        )[0]?.Name

  const itemName =
    itemWatch('ExpenseItemID') !== undefined
      ? getItemByCompany.filter(
          x => x?.ExpenseItemID === itemWatch('ExpenseItemID')
        )[0]?.ItemName
      : getItemByCompany.filter(
          x => x?.ExpenseItemID === menu?.obj?.ExpenseItemID
        )[0]?.ItemName

  const onSubmit = (data, docAmt, qty, unitPrice, amount, index, isSaveNew) => {
    if (detailMode === 'add') {
      itemData.push({
        Sequence: itemData?.length + 1,
        ExpenseItemID: data?.ExpenseItemID,
        CostCentreID: data?.CostCentreID,
        UnitPrice: unitPrice,
        Amount: amount,
        Quantity: qty,
        UOMID: uomID,
        TaxSchemeID: data?.TaxSchemeID,
        TaxCode: taxCodeName,
        TaxRate: taxCodeRate,
        ExpenseItemName: itemName,
        CostCentreCode: costCentreCode ?? defaultCostCentre?.Code,
        TaxAmt: data?.TaxAmt,
        DocAmt: docAmt,
        Remark: data?.Remark,
        einvoice_classification_code_id:
          !!selfBillStatus && EInvoiceStarted ? classificationCodeID : null,
        ClassificationCode:
          !!selfBillStatus && EInvoiceStarted ? classificationCode : null,
        ClassificationDescription:
          !!selfBillStatus && EInvoiceStarted
            ? classificationDescription
            : null,
      })
    } else {
      let existingItemData = itemData[index]

      itemData[index] = {
        Sequence: itemData?.Sequence ?? index + 1,
        [`${primaryItemKey}`]: itemData[index]?.[`${primaryItemKey}`],
        ...(!!menu?.obj?.DOItemID
          ? { DOItemID: menu?.obj?.DOItemID }
          : !!menu?.obj?.GRTNItemID
          ? { GRTNItemID: menu?.obj?.GRTNItemID }
          : null),
        ExpenseItemID: data?.ExpenseItemID,
        CostCentreID: data?.CostCentreID,
        UnitPrice: unitPrice,
        Amount: amount ?? docAmt - existingItemData?.TaxAmt,
        ExpenseItemName: itemName,
        CostCentreCode: costCentreCode ?? defaultCostCentre?.Code,
        Quantity: qty,
        UOMID: uomID,
        TaxRate: taxCodeRate ?? existingItemData?.TaxRate,
        TaxCode: taxCodeName,
        TaxSchemeID: data?.TaxSchemeID,
        TaxAmt: !!data?.TaxAmt ? data?.TaxAmt : existingItemData?.TaxAmt,
        DocAmt: docAmt,
        Remark: data?.Remark,
        einvoice_classification_code_id:
          !!selfBillStatus && EInvoiceStarted
            ? data?.einvoice_classification_code_id
            : null,
        ClassificationCode:
          !!selfBillStatus && EInvoiceStarted ? classificationCode : null,
        ClassificationDescription:
          !!selfBillStatus && EInvoiceStarted
            ? classificationDescription
            : null,
      }
    }

    if (!isSaveNew) {
      setOpenItemDialog(false)
    } else {
      fetchExpenseItem()
      fetchCostCentre()
      itemReset(defaultVal)
      setOpenItemDialog(true)
      setAccountType('')
      setOpenItemDialog(true)
    }
  }

  const readOnly = () => {
    if (menu?.obj?.DOItemID || menu?.obj?.GRTNItemID) return true
    else return false
  }

  // ======== Functions for Expense Item ========= //
  const handleExpenseItemChange = ExpenseItemID => {
    setValueItem('ExpenseItemID', ExpenseItemID)
    // this is to get tax after select the expense item
    let defTax = getItemByCompany?.filter(
      x => x?.ExpenseItemID === ExpenseItemID
    )[0]?.TaxSchemeID

    const uom = getItemByCompany?.find(x => x?.ExpenseItemID === ExpenseItemID)
      ?.UOMID
    setUomID(uom)

    fetchUOM({
      variables: {
        UOMID: uom,
      },
    })
    let selectTax = taxScheme?.find(x => x?.TaxSchemeID === defTax)
    setValueItem('TaxSchemeID', defTax)
    setSelectedTax(selectTax)
    clearErrorsItem('ExpenseItemID')

    const taxEffectiveDate: any = watch('DocDate')
      ? new Date(new Date(watch('DocDate')).getTime() + 24 * 60 * 60 * 1000)
          .toISOString()
          .slice(0, 10)
      : null

    const taxAmount = new BigNumber(
      selectTax?.LatestTax === null
        ? selectTax?.TaxEffective.reduce((a, b) => {
            return new Date(a.Date) > new Date(b.Date) ? a : b
          })?.Rate
        : selectTax?.TaxEffective?.filter(
            x => new Date(x?.Date) <= new Date(taxEffectiveDate)
          ).reduce((a, b) => {
            return new Date(a.Date) > new Date(b.Date) ? a : b
          }, 0)?.Rate
    )
      .dividedBy(100)
      .multipliedBy(cost)
      .multipliedBy(qty)
      .toNumber()
    setTaxAmt(taxAmount)
    setValueItem('TaxAmt', taxAmount)

    let ccID = creditorAcc[0]?.einvoice_classification_code_id

    if (selfBillStatus && EInvoiceStarted) {
      loadClassificationCode()
      itemRegister('einvoice_classification_code_id')
      setClassificationCodeID(ccID)
      setValueItem('einvoice_classification_code_id', ccID)
    }
  }

  const handleExpenseItemDepartmentChecking = (newValue: any) => {
    const getClassCoa = findItemClassCOA(newValue?.ExpenseClassID)
    setValueItem('CostCentreID', null)
    if (!!newValue?.MasterCOAID && newValue?.MasterCOAID !== null)
      handleDefaultCostCentre(newValue?.MasterCOAID)
    else if (!newValue?.MasterCOAID && !getClassCoa) {
      fetchParentExpenseItemCOAAccountType({
        variables: { ExpenseItemID: newValue?.ExpenseItemID },
      })
    } else handleDefaultCostCentre(getClassCoa)
  }

  const handleQtyChange = event => {
    setQty(event.value)
    setValueItem('Quantity', event?.value)
  }

  // Autoset tax after select tax amount
  useEffect(() => {
    if (!!selectedTax) {
      setValueItem('TaxSchemeID', selectedTax?.TaxSchemeID)
      clearErrorsItem('TaxSchemeID')
    }
  }, [selectedTax])

  useEffect(() => {
    if (!!menu?.obj?.UOMID) {
      setUomID(menu?.obj?.UOMID)
      itemRegister('UOMID')
      fetchUOM({
        variables: {
          UOMID: uomID ?? menu?.obj?.UOMID,
        },
      })
    }
  }, [uomID])

  useEffect(() => {
    //to trigger autocomplete during edit mode followed by the condition stated for self bill
  }, [detailMode])

  //To find coa for item class
  const findItemClassCOA = itemClassID => {
    let expenseClassCoa

    expenseClassCoa = getExpenseClass?.find(x => {
      return x?.ExpenseClassID === itemClassID
    })?.MasterCOAID

    return expenseClassCoa
  }

  //UseEffect for Department
  useEffect(() => {
    let findObjItem = getItemByCompany?.find(x => {
      return x?.ExpenseItemID === menu?.obj?.ExpenseItemID
    })

    let getItemClass = findObjItem?.ExpenseClassID
    let findCoa = findObjItem?.MasterCOAID

    const getClassCoa = findItemClassCOA(getItemClass)

    if (!!menu?.obj?.ExpenseItemID && !getClassCoa && !findCoa) {
      fetchParentExpenseItemCOAAccountType({
        variables: { ExpenseItemID: menu?.obj?.ExpenseItemID },
      })
    } else if (
      !!menu?.obj?.ExpenseItemID &&
      readOnly() === false &&
      !ExpenseClassLoading
    ) {
      let tempCoa
      tempCoa = getMasterCOA?.find(
        x => x?.MasterCOAID === findCoa ?? getClassCoa
      )

      setAccountType(tempCoa?.AccountType)

      if (
        tempCoa?.AccountType === 'ASSET' ||
        tempCoa?.AccountType === 'LIABILITY' ||
        tempCoa?.AccountType === 'EQUITY'
      ) {
        setValueItem('CostCentreID', defaultCostCentre?.CostCentreID)
      } else {
        fetchCostCentre({
          variables: {
            CompanyID: companyID,
            MasterCOAID: findCoa !== null ? findCoa : getClassCoa,
            IsLastNode: true,
          },
        })
      }
    } else if (readOnly() === true) {
      fetchCostCentre({
        variables: {
          CompanyID: companyID,
          IsLastNode: true,
        },
      })
    }
  }, [menu?.obj, ExpenseClassLoading])

  const handleDefaultCostCentre = coaId => {
    const findcoa = getMasterCOA?.find(x => x?.MasterCOAID === coaId)

    setAccountType(findcoa?.AccountType)

    if (
      findcoa?.AccountType === 'ASSET' ||
      findcoa?.AccountType === 'LIABILITY' ||
      findcoa?.AccountType === 'EQUITY'
    ) {
      setValueItem('CostCentreID', defaultCostCentre?.CostCentreID)
    } else {
      fetchCostCentre({
        variables: {
          CompanyID: companyID,
          MasterCOAID: coaId,
          IsLastNode: true,
        },
      })
    }
  }

  const handleAmtChange = event => {
    setCost(parseFloat(event.value))
    setValueItem('UnitPrice', parseFloat(event.value))
    if (selectedTax) {
      setTaxAmt(
        new BigNumber(
          selectedTax?.LatestTax === null
            ? selectedTax?.TaxEffective?.reduce((a, b) => {
                return new Date(a.Date) > new Date(b.Date) ? a : b
              })?.Rate
            : selectedTax?.LatestTax?.Rate
        )
          .dividedBy(100)
          .multipliedBy(event?.floatValue)
          .multipliedBy(qty)
          .toNumber()
      )
      setValueItem('TaxAmt', taxAmt)
    }
  }

  const handleTaxChange = (event, taxEffectiveDate) => {
    setSelectedTax(taxScheme.find(x => x?.TaxSchemeID === event?.target?.value))
    setTaxAmt(
      (new BigNumber(
        taxScheme.filter(x => x?.TaxSchemeID === event?.target?.value)[0]
          .LatestTax === null
          ? taxScheme
              .filter(x => x?.TaxSchemeID === event?.target?.value)[0]
              ?.TaxEffective.reduce((a, b) => {
                return new Date(a.Date) > new Date(b.Date) ? a : b
              })?.Rate
          : taxScheme
              .filter(x => x?.TaxSchemeID === event?.target?.value)[0]
              ?.TaxEffective?.filter(
                x => new Date(x?.Date) <= new Date(taxEffectiveDate)
              )
              .reduce((a, b) => {
                return new Date(a.Date) > new Date(b.Date) ? a : b
              })?.Rate
      ).toNumber() /
        100) *
        cost
    )
  }

  const handleTaxAmtChange = event => {
    setTaxAmt(event?.value)
  }

  useEffect(() => {
    if (!isNaN(taxAmt)) {
      setValueItem('TaxAmt', taxAmt)
      clearErrorsItem('TaxAmt')
    }
  }, [taxAmt])

  useEffect(() => {
    if (detailMode === 'edit') {
      let selectTax = taxScheme.find(
        x => x?.TaxSchemeID === menu?.obj?.TaxSchemeID
      )

      setSelectedTax(selectTax)
    }
    if (selfBillStatus && itemEditData) {
      loadClassificationCode()
      itemRegister('einvoice_classification_code_id')
      setClassificationCodeID(menu?.obj?.einvoice_classification_code_id)
    }
  }, [detailMode])

  return (
    <>
      {masterCOALoading && <Loading />}
      {taxSchemeLoading && <Loading />}
      {CostCentreLoading && <Loading />}
      {ExpenseItemLoading && <Loading />}
      {ExpenseClassLoading && <Loading />}
      {taxEffectiveDateLoading && <Loading />}
      {docDateValidationLoading && <Loading />}
      {classificationCodeLoading && <Loading />}
      {ParentItemCategoryLoading && <Loading />}
      <CommonDialog
        fullWidth={true}
        open={openItemDialog}
        onClose={() => setOpenItemDialog(false)}
        sections={{
          header: {
            title: `${title}`,
            rightText: detailMode === 'edit' ? 'Edit' : 'New',
          },
          body: () => (
            <>
              <div className="content-container" style={{ marginTop: '-20px' }}>
                {!ExpenseItemLoading && (
                  <Controller
                    render={({ onChange, value }) => {
                      const defVal = getItemByCompany?.filter(
                        x => x?.ExpenseItemID === menu?.obj?.ExpenseItemID
                      )[0]

                      return (
                        <Autocomplete
                          options={
                            getItemByCompany?.sort((a, b) => {
                              return a.ItemName.localeCompare(b.ItemName)
                            }) || []
                          }
                          getOptionLabel={option => `${option?.ItemName}`}
                          fullWidth
                          disabled={readOnly() === true ? true : false}
                          onChange={(value, newValue: any) => {
                            handleExpenseItemChange(newValue?.ExpenseItemID)
                            setValueItem(
                              'ExpenseItemID',
                              newValue?.ExpenseItemID
                            )
                            handleExpenseItemDepartmentChecking(newValue)

                            if (newValue?.IsInventory === true) {
                              setItemColor('darkblue')
                            } else setItemColor('')
                          }}
                          renderOption={(props, option) => {
                            return props?.IsInventory === true ? (
                              <span style={{ color: 'darkblue' }}>
                                {props?.ItemName}
                              </span>
                            ) : (
                              <span>{props?.ItemName}</span>
                            )
                          }}
                          defaultValue={defVal}
                          renderInput={(params: any) => {
                            return (
                              <div>
                                <TextField
                                  {...params}
                                  required
                                  label="Item"
                                  variant="standard"
                                  fullWidth
                                  name="ExpenseItemID"
                                  disabled={readOnly() === true ? true : false}
                                  helperText={
                                    itemErrors?.ExpenseItemID?.message
                                  }
                                  error={
                                    itemErrors?.ExpenseItemID ? true : false
                                  }
                                  InputProps={{
                                    ...params?.InputProps,
                                    style: {
                                      color: itemColor,
                                    },
                                  }}
                                />
                              </div>
                            )
                          }}
                        />
                      )
                    }}
                    name="ExpenseItemID"
                    autoComplete="off"
                    control={itemControl}
                    multiline={true}
                    fullWidth
                    margin="dense"
                    ref={itemRegister}
                    required
                    disabled={readOnly() === true ? true : false}
                    defaultValue={menu?.obj?.ExpenseItemID}
                    helperText={itemErrors?.ExpenseItemID?.message}
                    error={itemErrors?.ExpenseItemID ? true : false}
                  />
                )}

                {/* Classification e-Invoice */}

                {!classificationCodeLoading &&
                  selfBillStatus &&
                  EInvoiceStarted && (
                    <Controller
                      render={({ value, onChange }) => {
                        const defVal = getClassificationCode?.find(
                          x =>
                            x?.id ===
                            (classificationCodeID ??
                              menu?.obj?.einvoice_classification_code_id ??
                              menu?.obj?.ClassificationCodeID)
                        )

                        return (
                          <Autocomplete
                            options={
                              getClassificationCode
                                ?.filter(x =>
                                  x?.description.includes('Self-billed')
                                )
                                ?.sort((a, b) => {
                                  return a?.classificationCode.localeCompare(
                                    b.classificationCode
                                  )
                                }) || []
                            }
                            getOptionLabel={option =>
                              `${option?.classificationCode} | ${option?.description}`
                            }
                            fullWidth
                            disabled={!selfBillStatus ? true : false}
                            onChange={(value, newValue: any) => {
                              setClassificationCodeID(newValue?.id)
                              itemRegister('einvoice_classification_code_id')
                              setValueItem(
                                'einvoice_classification_code_id',
                                newValue?.id
                              )
                            }}
                            renderOption={(props, option) => {
                              return (
                                <>
                                  <div>
                                    <div>
                                      <span className="xsTitle">
                                        {props?.classificationCode}
                                      </span>
                                    </div>
                                    <div className="desc">
                                      {props?.description}
                                    </div>
                                  </div>
                                </>
                              )
                            }}
                            defaultValue={defVal}
                            renderInput={(params: any) => {
                              return (
                                <div>
                                  <TextField
                                    {...params}
                                    required
                                    label="Classification Code"
                                    variant="standard"
                                    fullWidth
                                    name="einvoice_classification_code_id"
                                    disabled={!selfBillStatus ? true : false}
                                    helperText={
                                      (itemWatch(
                                        'einvoice_classification_code_id'
                                      ) === '' ||
                                        itemWatch(
                                          'einvoice_classification_code_id'
                                        ) === undefined) &&
                                      itemErrors
                                        ?.einvoice_classification_code_id
                                        ?.message
                                    }
                                    error={
                                      (itemWatch(
                                        'einvoice_classification_code_id'
                                      ) === '' ||
                                        itemWatch(
                                          'einvoice_classification_code_id'
                                        ) === undefined) &&
                                      itemErrors?.einvoice_classification_code_id
                                        ? true
                                        : false
                                    }
                                    defaultValue={
                                      menu
                                        ? menu?.obj
                                            ?.einvoice_classification_code_id
                                        : null
                                    }
                                  />
                                </div>
                              )
                            }}
                          />
                        )
                      }}
                      name="einvoice_classification_code_id"
                      autoComplete="off"
                      control={itemControl}
                      multiline={true}
                      fullWidth
                      margin="dense"
                      ref={itemRegister}
                      required
                      defaultValue={
                        menu
                          ? menu?.obj?.einvoice_classification_code_id ??
                            menu?.obj?.ClassificationCodeID ??
                            classificationCodeID
                          : null
                      }
                      disabled={!selfBillStatus ? true : false}
                    />
                  )}

                {(accountType === 'ASSET' ||
                  accountType === 'LIABILITY' ||
                  accountType === 'EQUITY') &&
                readOnly() === false ? (
                  <Controller
                    render={({ value, onChange }) => {
                      return (
                        <TextField
                          value={`${defaultCostCentre?.Code} | ${defaultCostCentre?.Name}`}
                          label="Department *"
                          fullWidth
                          disabled
                        />
                      )
                    }}
                    value={defaultCostCentre?.CostCentreID}
                    name="CostCentreID"
                    autoComplete="off"
                    control={itemControl}
                    multiline={true}
                    fullWidth
                    margin="dense"
                    ref={itemRegister}
                    required
                    helperText={
                      (itemWatch('CostCentreID') === '' ||
                        itemWatch('CostCentreID') === undefined) &&
                      itemErrors?.CostCentreID?.message
                    }
                    error={
                      (itemWatch('CostCentreID') === '' ||
                        itemWatch('CostCentreID') === undefined) &&
                      itemErrors?.CostCentreID
                        ? true
                        : false
                    }
                  />
                ) : (
                  !CostCentreLoading && (
                    <Controller
                      render={({ value, onChange }) => {
                        const defVal = getCostCentre?.filter(
                          x => x?.CostCentreID === menu?.obj?.CostCentreID
                        )[0]
                        return (
                          <Autocomplete
                            options={
                              getCostCentre?.sort((a, b) => {
                                return a.Code.localeCompare(b.Code)
                              }) || []
                            }
                            getOptionLabel={option =>
                              `${option?.Code} | ${option?.Name}`
                            }
                            fullWidth
                            disabled={readOnly() === true ? true : false}
                            onChange={(value, newValue: any) => {
                              setValueItem(
                                'CostCentreID',
                                newValue?.CostCentreID
                              )
                            }}
                            renderOption={(props, option) => {
                              return (
                                <>
                                  <div>
                                    <div>
                                      <span className="xsTitle">
                                        {props?.Code}
                                      </span>
                                    </div>
                                    <div className="desc">{props?.Name}</div>
                                  </div>
                                </>
                              )
                            }}
                            defaultValue={defVal}
                            renderInput={(params: any) => {
                              return (
                                <div>
                                  <TextField
                                    {...params}
                                    required
                                    label="Department"
                                    variant="standard"
                                    fullWidth
                                    name="CostCentreID"
                                    disabled={
                                      readOnly() === true ? true : false
                                    }
                                    helperText={
                                      (itemWatch('CostCentreID') === '' ||
                                        itemWatch('CostCentreID') ===
                                          undefined) &&
                                      itemErrors?.CostCentreID?.message
                                    }
                                    error={
                                      (itemWatch('CostCentreID') === '' ||
                                        itemWatch('CostCentreID') ===
                                          undefined) &&
                                      itemErrors?.CostCentreID
                                        ? true
                                        : false
                                    }
                                    defaultValue={menu?.obj?.CostCentreID || ''}
                                  />
                                </div>
                              )
                            }}
                          />
                        )
                      }}
                      name="CostCentreID"
                      autoComplete="off"
                      control={itemControl}
                      multiline={true}
                      fullWidth
                      margin="dense"
                      ref={itemRegister()}
                      required
                      defaultValue={menu?.obj?.CostCentreID || ''}
                      disabled={readOnly() === true ? true : false}
                      helperText={
                        (itemWatch('CostCentreID') === '' ||
                          itemWatch('CostCentreID') === undefined) &&
                        itemErrors?.CostCentreID?.message
                      }
                      error={
                        (itemWatch('CostCentreID') === '' ||
                          itemWatch('CostCentreID') === undefined) &&
                        itemErrors?.CostCentreID
                          ? true
                          : false
                      }
                    />
                  )
                )}
                <Grid
                  item
                  xs={6}
                  style={{
                    marginBottom: '5px',
                    paddingRight: '10px',
                    marginTop: '11px',
                  }}
                >
                  <Controller
                    as={<NumberFormat allowNegative={false} />}
                    required
                    value={qty}
                    margin="dense"
                    ref={itemRegister}
                    name="Quantity"
                    label="Qty"
                    decimalScale={4}
                    control={itemControl}
                    autoComplete="off"
                    fixedDecimalScale
                    thousandSeparator
                    id="standard-basic"
                    customInput={TextField}
                    onValueChange={e => {
                      handleQtyChange(e)
                    }}
                    helperText={itemErrors?.Quantity?.message}
                    error={itemErrors?.Quantity ? true : false}
                    disabled={readOnly() === true ? true : false}
                    defaultValue={menu?.obj ? menu?.obj?.Quantity : 0}
                  />
                </Grid>

                <Grid
                  item
                  xs={6}
                  style={{
                    marginBottom: '5px',
                  }}
                >
                  {!uomExcLoading && (
                    <Controller
                      render={({ value, onChange }) => {
                        const defVal = getUOMExchangeList?.filter(
                          x => x?.uomid === uomID
                        )[0]

                        return (
                          <Autocomplete
                            options={getUOMExchangeList || []}
                            getOptionLabel={option => `${option?.uomcode}`}
                            filterOptions={createFilterOptions({
                              stringify: option =>
                                option.uomcode + option.uomname,
                            })}
                            fullWidth
                            onChange={(value, newValue: any) => {
                              setUomID(newValue?.uomid)
                              // onChange(newValue?.uomid)
                              itemRegister('UOMID')
                            }}
                            disabled={readOnly() === true ? true : false}
                            renderOption={(props, option) => {
                              return (
                                <div>
                                  <div>
                                    <span className="xsTitle">
                                      {props?.uomcode}
                                    </span>
                                  </div>
                                  <div className="desc">{props?.uomname}</div>
                                </div>
                              )
                            }}
                            defaultValue={defVal}
                            renderInput={(params: any) => {
                              return (
                                <div>
                                  <TextField
                                    {...params}
                                    label="UOM"
                                    style={{ width: '100%' }}
                                    margin="normal"
                                    required
                                    helperText={itemErrors?.UOMID?.message}
                                    error={itemErrors?.UOMID ? true : false}
                                  />
                                </div>
                              )
                            }}
                          />
                        )
                      }}
                      label="UOM"
                      name="UOMID"
                      autoComplete="off"
                      control={itemControl}
                      multiline={true}
                      fullWidth
                      margin="normal"
                      ref={itemRegister}
                      // helperText={errors?.UOMID?.message}
                      // error={errors?.UOMID ? true : false}
                      defaultValue={menu?.obj?.UOMID}
                      required
                    />
                  )}
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    marginBottom: '5px',
                    paddingRight: '10px',
                  }}
                >
                  <Controller
                    as={
                      <NumberFormat
                        allowNegative={false}
                        value={cost}
                        required
                        customInput={TextField}
                        thousandSeparator
                        decimalScale={4}
                        fixedDecimalScale
                        isNumericString
                        label="Unit Price"
                        disabled={readOnly() === true ? true : false}
                        onValueChange={e => {
                          setCost(e?.floatValue)
                          setValueItem('UnitPrice', e?.floatValue)
                          handleAmtChange(e)
                        }}
                      />
                    }
                    onChange={(value, newValue: any) => {
                      handleAmtChange(newValue)
                    }}
                    name="UnitPrice"
                    control={itemControl}
                    margin="dense"
                    required
                    helperText={itemErrors?.UnitPrice?.message}
                    error={itemErrors?.UnitPrice ? true : false}
                    ref={itemRegister}
                    defaultValue={menu?.obj?.UnitPrice ?? 0}
                  />
                </Grid>
                <Grid item xs={6} style={{ marginBottom: '5px' }}>
                  <TextField
                    name={`Amount`}
                    label="Base Amt"
                    value={amtNumStr(
                      baseAmount(Number(qty), Number(cost))
                    ).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    margin="dense"
                    ref={itemRegister}
                    style={{ color: 'white' }}
                    disabled
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    marginBottom: '5px',
                    paddingRight: '10px',
                  }}
                >
                  <Controller
                    as={
                      <TextField
                        margin="dense"
                        label="Tax Code"
                        required
                        SelectProps={{
                          renderValue: () => `${selectedTax?.Code}`,
                          onChange: event => {
                            handleTaxChange(event, taxEffectiveDate)
                            clearErrorsItem('TaxSchemeID')
                          },
                        }}
                        select
                      >
                        {taxSchemeInput?.map((tax, index) => (
                          <MenuItem
                            id="tax-select"
                            key={index}
                            value={tax?.TaxSchemeID}
                          >
                            <span className="text-noflow">{`${tax?.Code}`}</span>
                          </MenuItem>
                        ))}
                      </TextField>
                    }
                    select
                    name="TaxSchemeID"
                    autoComplete="off"
                    control={itemControl}
                    helperText={itemErrors?.TaxSchemeID?.message}
                    error={itemErrors?.TaxSchemeID ? true : false}
                    defaultValue={menu?.obj?.TaxSchemeID || ''}
                    ref={itemRegister()}
                    // hidden={true}
                    // style={{ display: 'none' }}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    marginBottom: '5px',
                  }}
                >
                  <TextField
                    name="TaxRate"
                    label="Tax Rate %"
                    value={amtStr(taxCodeRate)}
                    disabled
                    // className="right"
                    margin="normal"
                    // ref={register}
                    style={{ marginTop: '5px' }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Controller
                    as={<NumberFormat allowNegative={false} />}
                    thousandSeparator
                    customInput={TextField}
                    ref={itemRegister()}
                    control={itemControl}
                    margin="dense"
                    name="TaxAmt"
                    label="Tax Amount"
                    className="left"
                    onValueChange={e => {
                      handleTaxAmtChange(e?.floatValue)
                      setTaxAmt(e?.floatValue)
                    }}
                    value={taxAmt}
                    defaultValue={menu?.obj?.TaxAmt ?? taxAmt ?? 0}
                    decimalScale={2}
                    fixedDecimalScale
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    render={({ value, onChange }) => {
                      value = { docAmt }
                      return (
                        <TextField
                          label="Doc Amount"
                          value={amtStr(docAmt ?? menu?.obj?.DocAmt)}
                          InputProps={{
                            readOnly: true,
                            disableUnderline: true,
                          }}
                          className="right"
                          disabled
                        />
                      )
                    }}
                    disabled
                    margin="dense"
                    defaultValue={docAmt ?? menu?.obj?.DocAmt}
                    name="DocAmt"
                    ref={itemRegister()}
                    control={itemControl}
                  />
                </Grid>

                <ItemVoiceTextField
                  mounted={true}
                  required
                  label="Remark"
                  name="Remark"
                  value={itemVoiceRemark}
                  setValue={setValueItem}
                  record={itemRecord}
                  setRecord={setItemRecord}
                  control={itemControl}
                  controllerProps={{
                    error: itemErrors?.Remark ? true : false,
                    defaultValue: menu?.obj?.Remark,
                    helperText: itemErrors?.Remark?.message,
                    ref: itemRegister,
                    autoComplete: 'off',
                  }}
                />
              </div>

              <ErrorDialog
                errorDia={errorDia}
                setErrorDia={setErrorDia}
                errorMsg={errMsg}
                errorHeaderMsg={'Error!'}
              />
            </>
          ),
          footer: {
            actions:
              detailMode === 'add'
                ? [
                    {
                      displayText: 'Close',
                      props: {
                        onClick: () => {
                          setOpenItemDialog(false)
                          setAccountType('')
                        },
                        variant: 'contained',
                        color: 'primary',
                      },
                    },
                    {
                      displayText: 'Confirm & New',
                      props: {
                        onClick: () => {
                          handleItemSubmit(data =>
                            onSubmit(
                              data,
                              docAmt,
                              qty,
                              cost,
                              baseAmount(Number(qty), Number(cost)),
                              menu?.index,
                              true
                            )
                          )()
                        },
                        variant: 'contained',
                        color: 'primary',
                      },
                    },
                    {
                      displayText: 'Confirm',
                      props: {
                        onClick: () => {
                          handleItemSubmit(data =>
                            onSubmit(
                              data,
                              docAmt,
                              qty,
                              cost,
                              baseAmount(Number(qty), Number(cost)),
                              menu?.index,
                              false
                            )
                          )()
                        },
                        variant: 'contained',
                        color: 'primary',
                      },
                    },
                  ]
                : [
                    {
                      displayText: 'Close',
                      props: {
                        onClick: () => setOpenItemDialog(false),
                        variant: 'contained',
                        color: 'primary',
                      },
                    },
                    {
                      displayText: 'Confirm',
                      props: {
                        onClick: () => {
                          handleItemSubmit(data =>
                            onSubmit(
                              data,
                              docAmt,
                              qty,
                              cost,
                              baseAmount(Number(qty), Number(cost)),
                              menu?.index,
                              false
                            )
                          )()
                          setAccountType('')
                        },
                        variant: 'contained',
                        color: 'primary',
                      },
                    },
                  ],
          },
        }}
      />
    </>
  )
}
