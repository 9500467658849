import DateFnsUtils from '@date-io/date-fns'
import { yupResolver } from '@hookform/resolvers'
import { UploadPreview } from '@ifca-root/react-component/src/components/Avatar/UploadPreview'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { VoiceTextField } from '@ifca-root/react-component/src/components/Input/CustomTextField'
import { FileUploadInput } from '@ifca-root/react-component/src/components/Input/FileUploadInput'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import useUploadAttachment from '@ifca-root/react-component/src/utils/hooks/useUploadAttachment'
import {
  Checkbox,
  Grid,
  Input,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'
import { ExitConfirmationDialog } from 'components/Dialog/ExitConfirmationDialog'
import {
  AddFavoriteDialog,
  DeleteFavoriteDialog,
} from 'components/Favorite/FavoriteDialog'
import FavoriteMenu from 'components/Favorite/FavoriteMenu'
import { TooltipAmountFooter } from 'components/Footer/TooltipAmountFooter'
import { IsEinvoiceSelfBilled } from 'containers/APModule/EInvoiceComponent/SelfBilledComponent'

import { ItemDialog } from 'containers/APModule/components/ItemDialog'
import { ItemDetail } from 'containers/ARAPModule/Components/Detail/ItemDetail'
import {
  findDuplicates,
  statusInput,
} from 'containers/ARAPModule/Function/DocumentChecker'
import { dueDateChecker } from 'containers/ARAPModule/Helper/DateCalculation'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  DocDateValidationAfter,
  DocDateValidationBefore,
} from 'containers/CashBookModule/DocDateValidation'
import {
  AcctPermission,
  DocumentListingDocument,
  GetApFavoriteInvoiceDocument,
  GetApInvoiceDocument,
  GetApInvoicebyStatusDocument,
  RecordStatus,
  useCreateApFavoriteInvoiceMutation,
  useCreateApInvoiceMutation,
  useDeleteApFavoriteInvoiceMutation,
  useDocumentListingLazyQuery,
  useGetApFavoriteInvoiceQuery,
  useGetCompanyNameQuery,
  useGetCostCentreCodeLazyQuery,
  useGetCreditorAccountCompanyAssignmentNameLazyQuery,
  useGetDoItemLazyQuery,
  useGetDocNumTitleQuery,
  useGetDocumentDateValidationQuery,
  useGetEInvoiceCompanyStartLazyQuery,
  useGetExpenseItemLazyQuery,
  useGetGeneralDOsLazyQuery,
  useGetMasterCoaCheckingQuery,
  useGetMsicCodeQuery,
  useGetTaxEffectiveDateQuery,
  useGetTaxSchemeQuery,
  useLatestOpenPeriodCheckingDateQuery,
  useUpdateApInvoiceMutation,
} from 'generated/graphql'
import { handleExitConfirmation } from 'helpers/Form/ExitConfirmation'
import { CommonYupValidation } from 'helpers/Form/YupValidation'
import { useMenuOption } from 'helpers/Hooks/useMenuOption'
import { usePermissionChecker } from 'helpers/Hooks/usePermissionChecker'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { formatDashDate } from 'helpers/StringNumberFunction/FormatDate'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory, useLocation, useParams } from 'react-router'
import { uuid } from 'uuidv4'
import * as yup from 'yup'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
interface APInvoiceProps {
  //Invoice
  DocDate: string
  TrxDate: string
  CreditorAccountID: string
  DOHeaderID: string
  Description: string
  Remark: string
  InvoiceID: string
  DocAmt: number
  DocNo: string
  RefNo: string
  UIN: string
  isSelfBilled: boolean
  MSICCodeID: string
}

interface FavInvoiceProps {
  Name: string
}

export const APInvoiceStateForm = (props: any) => {
  const { CompanyID, InvoiceID }: any = useParams()
  let history = useHistory()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  let location = useLocation()
  const editData = location?.state as any
  const [taxEffectiveList, setTaxEffectiveList] = useState<any>()
  const [invoiceItemData, setInvoiceItemData] = useState([])
  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(SnackBarContext)
  const [initDocs, setInitDocs] = useState([])
  const [openExitConf, setOpenExitConf] = useState(null)
  const [openFavoriteDialog, setOpenFavoriteDialog] = useState(false)
  const [detailMode, setDetailMode] = useState('')
  const [creditorAccID, setCreditorAccID] = useState(
    editData ? editData?.CreditorAccountID : null
  )
  const [openFavoriteDeleteDialog, setOpenFavoriteDeleteDialog] = useState(
    false
  )
  const [errorDia, setErrorDia] = useState<boolean>(false)
  const [errMsg, setErrMsg] = useState<string>('')
  const [voiceRemark, setVoiceRemark] = useState('')

  const [record, setRecord] = useState(false)
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [edit, setEdit] = useState(false)
  const [docNoChecker, setDocNoChecker] = useState(false)
  const [selectedDOs, setSelectedDOs] = useState<Set<any>>(new Set([]))

  //For Default Department
  const [defaultCostCentre, loadDefaultCostCentre] = useState(null)

  //e-invoice setup for transaction
  const [isSelfBilled, setIsSelfBilled] = useState(
    editData ? editData?.einvoice_self_billed : false
  )
  const [isEinvoice, setIsEinvoice] = useState<boolean>(false)
  const [msicCodeID, setMsicCodeID] = useState(
    editData ? editData?.einvoice_msic_code_id : null
  )

  const { formMode } = props
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
  const checkedIcon = <CheckBoxIcon fontSize="small" />
  let form, mode
  switch (formMode) {
    case 'add':
      form = 'New'
      mode = 'add'
      break
    case 'edit':
      form = 'Draft'
      mode = 'edit'
      break
    case 'approve-reject':
      form = 'Approve'
      mode = 'approve-reject'
      break
    case 'resubmit':
      form = 'Resubmit'
      mode = 'edit'
      break
  }

  const {
    anchorEl,
    menu,
    handleClick,
    handleClose,
    resetMenu,
  } = useMenuOption()

  const {
    anchorEl: anchorElFav,
    setAnchorEl: setAnchorElFav,
    menu: menuFav,
    handleClick: handleClickFav,
    handleClose: handleCloseFav,
  } = useMenuOption()

  const {
    loading: docNumHeaderLoading,
    error: docNumHeaderError,
    data: { getDocumentNumberHeader } = { getDocumentNumberHeader: [] },
  } = useGetDocNumTitleQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID: CompanyID, RefTable: 'AP_Invoice' },
    onCompleted: ({ getDocumentNumberHeader }) => {
      if (
        (getDocumentNumberHeader?.length > 0 &&
          getDocumentNumberHeader?.find(x => x?.IsAutoDocNo === true)) ||
        getDocumentNumberHeader?.find(x => x?.IsAutoDocNo === true)
      ) {
        setDocNoChecker(true)
      }
    },
  })

  const InvoiceFormSchema = yup.object().shape({
    CreditorAccountID: CommonYupValidation.requireField(
      'Creditor Name is required'
    ),
    Description: CommonYupValidation.requireField(SystemMsgs.description()),
    DocDate: yup.string().required('Doc Date is Required'),
    TrxDate: yup.string().required('Transaction Date is Required'),
    ...(docNoChecker === false
      ? {
          DocNo: yup.string().required('Document No. is Required'),
        }
      : null),
    RefNo: yup.string().required('Reference No is Required'),
    ...(isSelfBilled
      ? {
          MSICCodeID: yup.string().when('isSelfBilled'.toString(), {
            is: true,
            then: yup.string().required(SystemMsgs.MSICCode()),
            otherwise: null,
          }),
        }
      : null),
  })

  const FavInvoiceFormSchema = yup.object().shape({
    Name: CommonYupValidation.requireField(SystemMsgs.name()),
  })

  const {
    handleSubmit,
    register,
    errors,
    control,
    getValues,
    watch,
    setValue,
    clearErrors,
    formState: { isSubmitted },
  } = useForm<APInvoiceProps>({
    mode: 'onSubmit',
    resolver: yupResolver(InvoiceFormSchema),
    defaultValues: {
      UIN: editData ? editData?.einvoice_irbm_uniqueID : null,
      MSICCodeID: editData ? editData?.einvoice_msic_code_id : null,
      isSelfBilled: editData
        ? editData?.einvoice_self_billed
        : !editData?.einvoice_self_billed,
    },
  })

  const {
    handleSubmit: handleFavSubmit,
    register: favRegister,
    control: favControl,
    errors: favErrors,
    watch: favWatch,
  } = useForm<FavInvoiceProps>({
    mode: 'onSubmit',
    resolver: yupResolver(FavInvoiceFormSchema),
  })

  /* -------------------------------------------- */
  /*                    QUERY                     */
  /* -------------------------------------------- */

  let docDate = watch('DocDate') ? new Date(watch('DocDate')) : null

  const {
    loading: CompanyLoading,
    error: CompanyError,
    data: { getCompany: curCompany } = { getCompany: [] },
  } = useGetCompanyNameQuery({
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
      RecordStatus: RecordStatus.Active,
    },
    onCompleted: ({ getCompany }) => {
      loadDefaultCostCentre(getCompany[0]?.DefaultCostCentre)
      fetchEinvComp({
        variables: {
          CompanyID: CompanyID,
          RecordStatus: RecordStatus.Active,
          DocDate: !!watch('DocDate') ? docDate : new Date(),
        },
      })
      fetchCreditorAcc({
        variables: {
          CompanyID: CompanyID,
        },
      })
    },
  })

  const [
    fetchEinvComp,
    {
      loading: EinvCompStartLoading,
      error: EinvCompStartError,
      data: { getCompany: curEinvCompStart } = { getCompany: [] },
    },
  ] = useGetEInvoiceCompanyStartLazyQuery({
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
      RecordStatus: RecordStatus.Active,
      DocDate: !!watch('DocDate') ? docDate : new Date(),
    },
    onCompleted: ({ getCompany }) => {
      setIsEinvoice(curEinvCompStart[0]?.EInvoiceStarted)
      if (!editData) {
        setIsSelfBilled(curEinvCompStart[0]?.EInvoiceStarted)
      }
    },
  })

  const {
    loading: masterCOALoading,
    error: masterCOAError,
    data: { getMasterCOA } = { getMasterCOA: [] },
  } = useGetMasterCoaCheckingQuery({
    fetchPolicy: 'network-only',
    variables: { IsLastNode: true, orderByAsc: 'Name' },
  })

  const {
    loading: TaxLoading,
    error: TaxError,
    data: { getTaxScheme } = { getTaxScheme: [] },
  } = useGetTaxSchemeQuery({
    onCompleted: () => {},
  })

  const {
    loading: DocDateValidationLoading,
    error: DocDateValidationError,
    data: { getDocumentDateValidation } = { getDocumentDateValidation: [] },
  } = useGetDocumentDateValidationQuery({
    fetchPolicy: 'network-only',
    onCompleted: () => {},
  })

  const {
    loading: msicCodeLoading,
    data: { getMSICCode } = { getMSICCode: [] },
  } = useGetMsicCodeQuery({
    fetchPolicy: 'network-only',
  })

  const [
    fetchCreditorAcc,
    {
      data: { getCreditorAccountCompanyAssignment } = {
        getCreditorAccountCompanyAssignment: [],
      },
      loading: CreditorAccountLoading,
      error: CreditorAccountError,
    },
  ] = useGetCreditorAccountCompanyAssignmentNameLazyQuery({
    fetchPolicy: 'network-only',
  })

  let creditorAcc = getCreditorAccountCompanyAssignment?.map(x => {
    return x?.CreditorAccount
  })

  let selectedCreditor = creditorAcc?.filter(
    x => x?.CreditorAccountID === watch('CreditorAccountID')
  )

  //e-invoice setup for transaction

  let defaultMsicCodeID = creditorAcc?.find(
    x => x?.CreditorAccountID === watch('CreditorAccountID')
  )?.einvoice_msic_code_id

  // General DO
  const [
    fetchGeneralDos,
    {
      data: { getGeneralDOs } = { getGeneralDOs: [] },
      loading: GeneralDosLoading,
      error: GeneralDosError,
    },
  ] = useGetGeneralDOsLazyQuery({
    fetchPolicy: 'network-only',
  })

  // Item DO
  const [
    fetchDOItem,
    {
      data: { getDOItem } = { getDOItem: [] },
      loading: ItemDosLoading,
      error: ItemDosError,
    },
  ] = useGetDoItemLazyQuery({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    fetchPolicy: 'network-only',
    onCompleted: ({ getDOItem }) => {
      let arry = []
      if (getDOItem?.length > 0) {
        getDOItem?.map((el, index) => {
          const taxCodeRate = amtStr(
            getTaxScheme?.filter(x => x?.TaxSchemeID === el?.TaxSchemeID)[0]
              ?.LatestTax?.Rate
          )
          const taxAmt = (parseFloat(taxCodeRate) / 100) * el?.Amount
          arry.push({
            DOHeaderID: el?.DOHeaderID,
            DOItemID: el?.DOItemID,
            UOMID: el?.UOMID,
            ExpenseItemName: el?.ItemName,
            ExpenseItemID: el?.ItemID,
            CostCentreCode: el?.CostCentreCode,
            CostCentreID: el?.CostCentreID,
            Amount: el?.Amount,
            Quantity: Number(parseFloat(el?.Quantity).toFixed(4)),
            UnitPrice: Number(parseFloat(el?.UnitPrice).toFixed(4)),
            DocAmt: el?.Amount + taxAmt,
            TaxSchemeID: el?.TaxSchemeID,
            TaxCode: el?.TaxCode,
            TaxAmt: taxAmt,
            TaxRate: taxCodeRate,
            Remark: el?.Remarks,
            einvoice_classification_code_id:
              selectedCreditor[0]?.einvoice_classification_code_id,
            Sequence: index + 1,
          })
          return el
        })
        setInvoiceItemData(arry)
      }
      if (selectedDOs?.size === 0) setInvoiceItemData([])
    },
  })

  // Item
  const [
    fetchExpenseItem,
    {
      data: { getItemByCompany } = { getItemByCompany: [] },
      loading: ExpenseItemLoading,
      error: ExpenseItemError,
    },
  ] = useGetExpenseItemLazyQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID },
  })

  const {
    loading: TaxEffectiveDateLoading,
    error: TaxEffectiveDateError,
    data: { getTaxEffectiveDate } = { getTaxEffectiveDate: [] },
  } = useGetTaxEffectiveDateQuery({
    fetchPolicy: 'network-only',
    onCompleted: () => {
      if (getTaxEffectiveDate?.length > 0) {
        let temp = getTaxEffectiveDate?.filter(
          tax =>
            Number(new Date(tax?.Date)) <
              DocDateValidationAfter(
                getDocumentDateValidation?.map(x => x?.MonthsAfter)
              ) &&
            Number(new Date(tax?.Date)) >
              DocDateValidationBefore(
                getDocumentDateValidation?.map(x => x?.MonthsBefore)
              )
        )
        setTaxEffectiveList(temp)
      }
    },
  })

  const {
    loading: FavoriteInvoiceLoading,
    error: FavoriteInvoiceError,
    data: { getAPFavoriteInvoice } = {
      getAPFavoriteInvoice: [],
    },
  } = useGetApFavoriteInvoiceQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID, UserID: user?.ID },
  })

  const favNames = getAPFavoriteInvoice?.map(fav => fav?.Name)

  //CostCentre
  const [
    fetchCostCentre,
    {
      loading: CostCentreLoading,
      error: CostCentreErrors,
      data: { getCostCentre } = { getCostCentre: [] },
    },
  ] = useGetCostCentreCodeLazyQuery({
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
      orderByAsc: 'CostCentreID',
      IsLastNode: true,
    },
  })

  const {
    loading: openPeriodCheckDateLoading,
    data: { latestOpenPeriodCheckingDate } = {
      latestOpenPeriodCheckingDate: {} as any,
    },
  } = useLatestOpenPeriodCheckingDateQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID },
  })

  /* -------------------------------------------- */
  /*                   USEEFECT                   */
  /* -------------------------------------------- */

  useEffect(() => {
    if (formMode === 'edit' && editData) {
      fetchCreditorAcc({
        variables: {
          CompanyID: CompanyID,
        },
      })
      fetchGeneralDos({
        variables: {
          CreditorAccountID: editData?.CreditorAccountID,
          CompanyID,
          DOItemIDs: editData?.APInvoiceItem?.filter?.(
            y => y?.DOItemID !== null
          )?.map(z => z?.DOItemID),
        },
      })
      const InvoiceItems = editData?.APInvoiceItem?.map((el, index) => {
        return {
          DOHeaderID: el?.DO?.DOHeaderID,
          DOItemID: el?.DOItemID,
          InvoiceItemID: el?.InvoiceItemID,
          ExpenseItemName: el?.ExpenseItem?.ItemName,
          ExpenseItemID: el?.ExpenseItemID,
          CostCentreCode: el?.CostCentre?.Code,
          CostCentreID: el?.CostCentreID,
          Quantity: Number(parseFloat(el?.Quantity).toFixed(4)),
          UnitPrice: Number(parseFloat(el?.UnitPrice).toFixed(4)),
          UOMID: el?.UOMID,
          UOMCode: el?.UOM?.Code,
          Amount: el?.Amount,
          DocAmt: el?.DocAmt,
          TaxSchemeID: el?.TaxScheme?.TaxSchemeID,
          TaxCode: el?.TaxScheme?.Code,
          TaxAmt: el?.TaxAmt,
          TaxRate: el?.TaxRate,
          Remark: el?.Remark,
          einvoice_classification_code_id: el?.einvoice_classification_code_id,
          ClassificationCode: el?.ClassificationCode?.classificationCode,
          ClassificationDescription: el?.ClassificationCode?.description,
        }
      })
      invoiceItemData.push(...InvoiceItems)
      if (editData?.DO?.length > 0) {
        editData?.DO?.map(x => selectedDOs.add(x?.DOHeaderID))
      }
    }
  }, [formMode, editData])

  useEffect(() => {
    if (!documentLoading && mode !== 'add') {
      fetchDocuments({ variables: { refID: editData?.InvoiceID } })
    }

    if (editData?.files) {
      setFiles(editData?.files)
      setPreviewFiles(
        editData?.files?.map(file => {
          return URL.createObjectURL(file)
        })
      )
    }
  }, [mode, editData?.InvoiceID, editData])

  useEffect(() => {
    if (editData?.Attachment) {
      setInitDocs(editData?.Attachment)
    }
  }, [editData])

  useEffect(() => {
    if (InvoiceID && editData?.Remark) {
      setValue('Remark', editData?.Remark)
    }
  }, [editData])

  /* -------------------------------------------- */
  /*                  FUNCTION                    */
  /* -------------------------------------------- */

  const [term, setTerm] = useState(
    mode === 'edit' ? editData?.CreditorAccount?.CreditTerm : 0
  )

  let totalAmount = invoiceItemData?.reduce(
    (total, currentValue) => (total = total + currentValue.DocAmt),
    0
  )

  let totalTaxAmt = invoiceItemData?.reduce(
    (total, currentValue) => (total = total + currentValue.TaxAmt),
    0
  )

  let amtBeforeTax = totalAmount - totalTaxAmt

  let calcTotal = items => {
    return items?.reduce(
      (total, currentValue) => (total = total + currentValue.DocAmt),
      0
    )
  }

  const handleAddFavorite = () => {
    setOpenFavoriteDialog(true)
  }

  const handleSelectFavorite = data => {
    setSelectedDOs(new Set([]))
    fetchCreditorAcc({
      variables: {
        CompanyID: CompanyID,
      },
    })
    const favInvItem = data?.APFavoriteInvoiceItem?.map(x => {
      return {
        InvoiceItemID: uuid(),
        ExpenseItemID: x?.ExpenseItemID,
        ExpenseItemName: x?.ExpenseItem?.ItemName,
        CostCentreID: x?.CostCentreID,
        CostCentreCode: x?.CostCentre?.Code,
        UOMID: x?.UOMID,
        UOMCode: x?.UOM?.Code,
        TaxSchemeID: x?.TaxSchemeID,
        TaxCode: x?.TaxScheme?.Code,
        TaxRate: parseFloat(x?.TaxRate),
        TaxAmt: parseFloat(x?.TaxAmt),
        Amount: parseFloat(x?.Amount),
        DocAmt: parseFloat(x?.DocAmt),
        Quantity: parseFloat(x?.Quantity),
        UnitPrice: parseFloat(x?.UnitPrice),
        Sequence: x?.Sequence,
        Remark: x?.Remark,
      }
    })
    setInvoiceItemData(favInvItem)
    setValue('RefNo', data?.RefNo)
    setValue('CreditorAccountID', data?.CreditorAccountID)
    setCreditorAccID(data?.CreditorAccountID)
    setValue('Remark', data?.Remark)
    setValue('DocAmt', data?.DocAmt)
    setValue('Description', data?.Description)
  }

  const updateNewInvoiceItem = (newDate, TEList) => {
    const arr = []
    invoiceItemData?.map(x => {
      let NewTax =
        getTaxEffectiveDate?.filter(
          j =>
            j?.TaxSchemeID === x?.TaxSchemeID &&
            new Date(j?.Date) <= new Date(newDate)
        )?.length > 0
          ? getTaxEffectiveDate
              ?.filter(
                j =>
                  j?.TaxSchemeID === x?.TaxSchemeID &&
                  new Date(j?.Date) <= new Date(newDate)
              )
              .reduce((a, b) => {
                return new Date(a.Date) > new Date(b.Date) ? a : b
              })
          : null
      if (NewTax !== null) {
        arr.push({
          DOItemID: x?.DOItemID,
          InvoiceItemID: x?.InvoiceItemID,
          ExpenseItemID: x?.ExpenseItemID,
          RevenueCode: x?.RevenueCode,
          RevenueName: x?.RevenueName,
          Amount: x?.Amount,
          TaxSchemeID: x?.ExpenseItem?.TaxSchemeID,
          TaxCode: x?.TaxCode,
          TaxRate: NewTax?.Rate,
          TaxAmt: x?.Amount * (NewTax?.Rate / 100),
          DocAmt: x?.DocAmt,
          CostCentreID: x?.CostCentreID,
          CostCentreCode: x?.CostCentreCode,
          Remark: x?.Remark,
        })
      }
    })
  }

  const [selectedTax, setSelectedTax] = useState(null)

  const handleCheckBoxChange = (event: any) => {
    let temp = selectedDOs || new Set()
    if (event?.target?.checked) {
      temp.add(event?.target?.value)
      setSelectedDOs(new Set(temp))
      fetchDOItem({ variables: { DOHeaderID: Array.from(selectedDOs) } })
    } else {
      temp.delete(event?.target?.value)
      setSelectedDOs(new Set(temp))
    }
    fetchDOItem({ variables: { DOHeaderID: Array.from(selectedDOs) } })
  }

  const readOnly = () => {
    if (menu?.obj?.DOItemID || menu?.obj?.GRTNItemID) return true
    else return false
  }

  //this is to check self bill setup from creditor account
  const handleCreditorChange = event => {
    let selfBilled = creditorAcc?.find(x => x?.CreditorAccountID === event)
      ?.einvoice_self_billed
    if (
      new Date(watch('DocDate')).toISOString() <
      curEinvCompStart[0]?.eInvStartDate
    )
      setIsSelfBilled(false)
    else {
      register('isSelfBilled')
      setValue('isSelfBilled', selfBilled)
      setIsSelfBilled(selfBilled)
    }
    let defaultMsicCodeID = creditorAcc?.find(
      x => x?.CreditorAccountID === event
    )?.einvoice_msic_code_id
    register('MSICCodeID')
    setValue('MSICCodeID', defaultMsicCodeID)
    setMsicCodeID(defaultMsicCodeID)
  }

  let selfBilled = creditorAcc?.find(
    x => x?.CreditorAccountID === watch('CreditorAccountID')
  )?.einvoice_self_billed

  //this is for toggle self bill e-invoice
  const handleToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue('isSelfBilled', e.target.checked)
    setIsSelfBilled(e.target.checked)
  }

  /* -------------------------------------------- */
  /*         CREATE & UPDATE MUTATION             */
  /* -------------------------------------------- */

  const [
    createAPInvoice,
    {
      loading: createAPInvoiceLoading,
      called: createAPInvoiceCalled,
      error: createAPInvoiceError,
    },
  ] = useCreateApInvoiceMutation({
    onError: error => {},
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.createNewRecord())
      setTimeout(() => {
        history.push({ pathname: `/account-payable/${CompanyID}/invoice` })
      }, 500)
    },
  })

  const [
    updateAPInvoice,
    {
      loading: updateAPInvoiceLoading,
      called: updateAPInvoiceCalled,
      error: updateAPInvoiceError,
    },
  ] = useUpdateApInvoiceMutation({
    onError: error => {},
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.createNewRecord())
      setTimeout(() => {
        history.push({ pathname: `/account-payable/${CompanyID}/invoice` })
      }, 500)
    },
  })

  const [
    createApFavoriteInvoice,
    {
      loading: createFavoriteInvoiceLoading,
      error: createFavoriteInvoiceError,
    },
  ] = useCreateApFavoriteInvoiceMutation({
    onError: error => {},
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.createNewRecord())
      setOpenFavoriteDialog(false)
    },
  })

  const checkSetupNumStruc = () => {
    if (
      getDocumentNumberHeader?.length > 0 &&
      getDocumentNumberHeader?.find(x => x?.IsAutoDocNo === true) &&
      getDocumentNumberHeader[0]?.SampleOutput === ''
    ) {
      return true
    } else return false
  }

  //checking for existing docNo
  const watchDocNo = watch('DocNo')

  const existingDocNo = creditorAcc?.find(
    x => x?.CreditorAccountID === watch('CreditorAccountID')
  )?.DocNoList

  /* -------------------------------------------- */
  /*                  ATTACHMENT                  */
  /* -------------------------------------------- */

  const {
    files,
    setFiles,
    setPreviewFiles,
    handleUploadChange,
    previewFiles,
    remove: removeFile,
    handleEditUpload,
  } = useUploadAttachment()

  const [
    fetchDocuments,
    { loading: documentLoading, error: DocError, data: DocData },
  ] = useDocumentListingLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ DocumentListing }) => {
      // only applies when first opening edit form, when editData still does not have "files" but only Attachment from field resolver
      if (!editData?.files) {
        handleEditUpload(
          DocumentListing?.filter(doc => doc?.description !== 'document_pdf')
        )
        previewFiles?.push(
          ...DocumentListing?.filter(
            doc => doc?.description !== 'document_pdf'
          )?.map(x => x?.fileURL)
        )
      }
    },
  })

  /* -------------------------------------------- */
  /*                   DELETE                     */
  /* -------------------------------------------- */

  const onDeleteInvoiceItem = (delInd: number) => {
    invoiceItemData.splice(delInd, 1)
  }

  const [
    deleteAPFavoriteInvoice,
    {
      loading: deleteFavoriteInvoiceLoading,
      error: deleteFavoriteInvoiceError,
    },
  ] = useDeleteApFavoriteInvoiceMutation({
    onError: error => {},
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.deleteRecord())
      setOpenFavoriteDeleteDialog(false)
    },

    variables: {
      FavoriteInvoiceID: menuFav?.ID,
    },
  })

  /* -------------------------------------------- */
  /*                  ONSUBMIT                    */
  /* -------------------------------------------- */

  const onSubmit = (data, status) => {
    if (formMode === 'add') {
      if (
        // getDocumentNumberHeader?.filter(x => x?.RefTable === 'AP_Invoice')
        //   ?.length === 0
        checkSetupNumStruc() === true
      ) {
        setOpenSnackBar(true)
        setSnackBarMsg(SystemMsgs.errorNumberingStructure())
      }
      if (
        findDuplicates(existingDocNo, watchDocNo, formMode, editData?.DocNo)
      ) {
        setOpenSnackBar(true)
        setSnackBarMsg(SystemMsgs.duplicateDocNo())
      } else {
        //add the data with GraphQL mutation
        createAPInvoice({
          variables: {
            input: {
              InvoiceID: InvoiceID,
              CompanyID: CompanyID,
              DocDate: formatDashDate(new Date(data?.DocDate)?.toISOString()),
              TransactionDate: formatDashDate(
                new Date(data?.TrxDate)?.toISOString()
              ),
              DueDate: new Date(
                dueDateChecker(data?.DocDate, term)
              ).toISOString(),
              Remark: data?.Remark,
              ApprovalStatus: statusInput(status),
              Attachment: files,
              CreditorAccountID: data?.CreditorAccountID,
              Description: data.Description,
              CreditTerm: term,
              // DocAmt: totalAmount ?? 0,
              DocAmt: totalAmount ?? 0,
              ...(docNoChecker === false ? { DocNo: data.DocNo } : null),
              RefNo: data?.RefNo,
              DocAmtBeforeTax: amtBeforeTax ?? 0,
              einvoice_irbm_uniqueID: data?.UIN,
              einvoice_self_billed: isSelfBilled,
              ...(isSelfBilled ? { einvoice_msic_code_id: msicCodeID } : null),
            },
            itemInput: invoiceItemData?.map((x, i) => {
              return {
                ...(!!x?.DOItemID ? { DOItemID: x?.DOItemID } : null),
                ExpenseItemID: x?.ExpenseItemID,
                Amount: parseFloat(x?.Amount),
                TaxSchemeID: x?.TaxSchemeID,
                TaxRate: parseInt(x?.TaxRate),
                TaxAmt: parseFloat(x?.TaxAmt),
                DocAmt: parseFloat(x?.DocAmt),
                Quantity: Number(parseFloat(x?.Quantity).toFixed(4)),
                UnitPrice: Number(parseFloat(x.UnitPrice).toFixed(4)),
                UOMID: x?.UOMID,
                CostCentreID: x?.CostCentreID,
                Remark: x?.Remark,
                Sequence: x?.Sequence ?? i + 1,
                einvoice_classification_code_id:
                  x?.einvoice_classification_code_id,
              }
            }),
          },
          refetchQueries: [
            {
              query: GetApInvoiceDocument,
              variables: {
                CompanyID: CompanyID,
                StatusArr: ['ACTIVE'],
              },
            },
            {
              query: DocumentListingDocument,
              variables: {
                refTable: 'S_Attachment',
                refID: editData?.InvoiceID,
              },
            },
          ],
        })
      }
    } else if (formMode !== 'add') {
      //update the data
      if (
        findDuplicates(existingDocNo, watchDocNo, formMode, editData?.DocNo)
      ) {
        setOpenSnackBar(true)
        setSnackBarMsg(SystemMsgs.duplicateDocNo())
      } else {
        updateAPInvoice({
          variables: {
            input: {
              InvoiceID: InvoiceID,
              CompanyID: CompanyID,
              DocDate: formatDashDate(new Date(data?.DocDate)?.toISOString()),
              TransactionDate: formatDashDate(
                new Date(data?.TrxDate)?.toISOString()
              ),
              ...(docNoChecker === false ? { DocNo: data.DocNo } : null),
              RefNo: data?.RefNo,
              Remark: data?.Remark,
              ApprovalStatus: statusInput(status),
              Attachment: files,
              CreditorAccountID: data?.CreditorAccountID,
              DocAmt: totalAmount ?? 0,
              Description: data.Description,
              CreditTerm: term,
              DueDate: new Date(
                dueDateChecker(data?.DocDate, term)
              ).toISOString(),
              DocAmtBeforeTax: amtBeforeTax ?? 0,
              einvoice_irbm_uniqueID: data?.UIN,
              einvoice_self_billed: isSelfBilled,
              ...(isSelfBilled ? { einvoice_msic_code_id: msicCodeID } : null),
            },
            itemInput: invoiceItemData?.map((x, index) => {
              return {
                ...(!!x?.DOItemID ? { DOItemID: x?.DOItemID } : null),
                ExpenseItemID: x?.ExpenseItemID,
                Amount: parseFloat(x?.Amount),
                TaxSchemeID: x?.TaxSchemeID,
                TaxRate: parseFloat(x?.TaxRate),
                TaxAmt: parseFloat(x?.TaxAmt),
                DocAmt: parseFloat(x?.DocAmt),
                Quantity: Number(parseFloat(x?.Quantity).toFixed(4)),
                UnitPrice: Number(parseFloat(x.UnitPrice).toFixed(4)),
                UOMID: x?.UOMID,
                CostCentreID: x?.CostCentreID,
                Remark: x?.Remark,
                Sequence: x?.Sequence ?? index + 1,
                einvoice_classification_code_id:
                  x?.einvoice_classification_code_id,
              }
            }),
          },
          refetchQueries: [
            {
              query: GetApInvoicebyStatusDocument,
              variables: {
                CompanyID: CompanyID,
                StatusArr: ['ACTIVE'],
              },
            },
            {
              query: DocumentListingDocument,
              variables: {
                refTable: 'S_Attachment',
                refID: editData?.InvoiceID,
              },
            },
          ],
        })
      }
    }
  }

  const favRefetchQuery = [
    {
      query: GetApFavoriteInvoiceDocument,
      variables: {
        CompanyID,
        UserID: user?.ID,
      },
    },
  ]

  const onSubmitFavorite = data => {
    const tempFav = {
      Name: data?.Name,
      UserID: user?.ID,
      InvoiceID: !!getValues('InvoiceID') ? getValues('InvoiceID') : null,
      Remark: !!getValues('Remark') ? getValues('Remark') : null,
      CreditorAccountID: !!getValues('CreditorAccountID')
        ? getValues('CreditorAccountID')
        : null,
      Description: !!getValues('Description') ? getValues('Description') : null,
      RefNo: !!getValues('RefNo') ? getValues('RefNo') : null,
      DocAmt: !!getValues('DocAmt') ? getValues('DocAmt') : 0,
      DocDate: !!getValues('DocDate') ? getValues('DocDate') : null,
    }
    const invItem: any[] = invoiceItemData
    const tempFavItem = invItem?.map(x => {
      return {
        ExpenseItemID: x?.ExpenseItemID,
        CostCentreID: x?.CostCentreID,
        TaxSchemeID: x?.TaxSchemeID,
        UOMID: x?.UOMID,
        TaxRate: parseFloat(x?.TaxRate),
        TaxAmt: parseFloat(x?.TaxAmt),
        Amount: parseFloat(x?.Amount),
        DocAmt: parseFloat(x?.DocAmt),
        Quantity: Number(parseFloat(x?.Quantity).toFixed(4)),
        UnitPrice: Number(parseFloat(x.UnitPrice).toFixed(4)),
        Sequence: x?.Sequence,
        Remark: x?.Remark,
      }
    })
    createApFavoriteInvoice({
      variables: {
        APFavoriteInvoiceInput: tempFav,
        APFavoriteInvoiceItemInput: tempFavItem,
      },
      refetchQueries: favRefetchQuery,
    })
  }

  /* -------------------------------------------- */
  /*               EXIT CONFIRMATION              */
  /* -------------------------------------------- */
  const hasChanges = () =>
    handleExitConfirmation({
      watch: watch,
      editData: editData,
      itemArr: invoiceItemData,
      initFiles: initDocs,
      currFiles: files?.map(file => file?.name),
      itemSuffixID: 'InvoiceItemID',
      itemTableName: 'APInvoiceItem',
      formMode: formMode,
    })

  const { handlePermDisabled } = usePermissionChecker()

  const createUpdateLoading = editData
    ? updateAPInvoiceLoading
    : createAPInvoiceLoading
  const createUpdateCalled = editData
    ? updateAPInvoiceCalled
    : createAPInvoiceCalled

  const docDateTimestamp = new Date(watch('DocDate'))
  const yearDocDate = docDateTimestamp.getFullYear()
  const monthDocDate = (docDateTimestamp.getMonth() + 1)
    .toString()
    .padStart(2, '0')
  const dayDocDate = docDateTimestamp
    .getDate()
    .toString()
    .padStart(2, '0')

  const transferDate = `${yearDocDate}-${monthDocDate}-${dayDocDate}`

  const trxDateTimestamp = new Date(watch('TrxDate'))
  const yearTrxDate = trxDateTimestamp.getFullYear()
  const monthTrxDate = (trxDateTimestamp.getMonth() + 1)
    .toString()
    .padStart(2, '0')
  const dayTrxDate = trxDateTimestamp
    .getDate()
    .toString()
    .padStart(2, '0')

  const trxDate = `${yearTrxDate}-${monthTrxDate}-${dayTrxDate}`

  const openPeriod1 =
    transferDate <= latestOpenPeriodCheckingDate?.StartDate ||
    transferDate >= latestOpenPeriodCheckingDate?.EndDate

  const openPeriod2 =
    trxDate >= latestOpenPeriodCheckingDate?.StartDate &&
    trxDate <= latestOpenPeriodCheckingDate?.EndDate

  const checkingYearClose1 = openPeriod1 ? true : false
  const checkingYearClose2 = openPeriod2 ? false : true

  /* -------------------------------------------- */
  /*                    FOOTER                    */
  /* -------------------------------------------- */

  const draftFooterOption = {
    name: 'Save as Draft',
    onClick: () => {
      handleSubmit(data => !createUpdateLoading && onSubmit(data, 'draft'))()
    },
    color: 'primary',
    props: {
      type: 'submit',
    },
    disabled: checkingYearClose2
      ? true
      : handlePermDisabled({
          companyID: CompanyID,
          permEnum: AcctPermission.AccPayableInvoicesDraft,
        }),
  }

  const rejectFooterOption = {
    name: 'Save',
    onClick: () => {
      handleSubmit(data => !createUpdateLoading && onSubmit(data, 'reject'))()
    },
    color: 'primary',
    props: {
      type: 'submit',
    },
    disabled:
      !!errors?.DocDate ||
      !!errors?.TrxDate ||
      !!errors?.RefNo ||
      !!errors?.CreditorAccountID ||
      !!errors?.Description ||
      checkingYearClose2
        ? true
        : handlePermDisabled({
            companyID: CompanyID,
            permEnum: AcctPermission.AccPayableInvoicesUpdate,
          }),
  }

  const submitFooterOption = {
    name: 'Submit',
    onClick: () => {
      handleSubmit(data => !createUpdateCalled && onSubmit(data, 'submit'))()
    },
    color: 'primary',
    props: {
      type: 'submit',
    },
    disabled:
      calcTotal(invoiceItemData) === 0 ||
      calcTotal(invoiceItemData) === undefined ||
      checkingYearClose2 === true
        ? true
        : false,
  }

  let footerOptions: any[]
  if (editData?.mode === 'resubmit') {
    footerOptions = [rejectFooterOption, submitFooterOption]
  } else {
    footerOptions = [draftFooterOption, submitFooterOption]
  }

  return (
    <>
      {TaxLoading && <Loading />}
      {CompanyLoading && <Loading />}
      {documentLoading && <Loading />}
      {docNumHeaderLoading && <Loading />}
      {CreditorAccountLoading && <Loading />}
      {openPeriodCheckDateLoading && <Loading />}
      {createFavoriteInvoiceLoading && <Loading />}
      {deleteFavoriteInvoiceLoading && <Loading />}
      {TaxEffectiveDateLoading && <Loading />}
      {FavoriteInvoiceLoading && <Loading />}
      {ItemDosLoading && <Loading />}
      {DocDateValidationLoading && <Loading />}
      {msicCodeLoading && <Loading />}
      {GeneralDosLoading && <Loading />}
      {EinvCompStartLoading && <Loading />}

      <MainHeader
        mainBtn="close"
        onClick={() => {
          if (hasChanges() === true) {
            setOpenExitConf(true)
          } else {
            history.push({
              pathname: `/account-payable/${CompanyID}/invoice`,
            })
          }
        }}
        smTitle={'Account Payable'}
        title={curCompany[0]?.Name}
        routeSegments={[
          { name: 'Account Payable Main Menu' },
          { name: 'Account Payable Submenu' },
          { name: 'Invoice', current: true },
        ]}
        rightRouteSegments={[
          {
            name:
              formMode === 'add'
                ? 'New'
                : formMode === 'approve-reject'
                ? 'Approve/Reject'
                : formMode === 'edit'
                ? 'Edit'
                : 'Draft',
            current: true,
          },
        ]}
      />

      <ContentWrapper float footer>
        <CardContents
          section={{
            header: {
              title: 'Invoice',
              icon: (
                <FavoriteMenu
                  options={getAPFavoriteInvoice}
                  funcLabel={'Add Favorite'}
                  addFavFunc={handleAddFavorite}
                  selectFavFunc={handleSelectFavorite}
                  optionIDName={'FavoriteInvoiceID'}
                  setOpenFavoriteDeleteDialog={setOpenFavoriteDeleteDialog}
                  anchorEl={anchorElFav}
                  setAnchorEl={setAnchorElFav}
                  handleClose={handleCloseFav}
                  handleClickDelete={handleClickFav}
                />
              ),
            },
          }}
        >
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              required
              as={KeyboardDatePicker}
              name="DocDate"
              label="Invoice Date"
              control={control}
              onChange={(date: Date | null) => {}}
              onAccept={(date: Date | null) => {
                updateNewInvoiceItem(date, taxEffectiveList)
                fetchEinvComp({
                  variables: {
                    CompanyID: CompanyID,
                    RecordStatus: RecordStatus.Active,
                    DocDate: date,
                  },
                })
                let selfBilled = creditorAcc?.find(
                  x => x?.CreditorAccountID === watch('CreditorAccountID')
                )?.einvoice_self_billed
                let defaultMsicCodeID = creditorAcc?.find(
                  x => x?.CreditorAccountID === watch('CreditorAccountID')
                )?.einvoice_msic_code_id
                if (
                  new Date(date).toISOString() <
                  curEinvCompStart[0]?.eInvStartDate
                )
                  setIsSelfBilled(false)
                else {
                  register('isSelfBilled')
                  setValue('isSelfBilled', selfBilled)
                  setIsSelfBilled(selfBilled)
                  setValue('MSICCodeID', defaultMsicCodeID)
                  setMsicCodeID(defaultMsicCodeID)
                }
              }}
              format="dd/MM/yyyy"
              value={watch(formMode === 'add' ? new Date() : editData?.DocDate)}
              margin="dense"
              allowKeyboardControl
              ref={register}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              defaultValue={editData ? editData?.DocDate : new Date()}
              showTodayButton
              disabled={formMode === 'approve-reject'}
              className=" left"
            />
          </MuiPickersUtilsProvider>

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              as={KeyboardDatePicker}
              name="TrxDate"
              required
              label="Transaction Date"
              control={control}
              format="dd/MM/yyyy"
              margin="dense"
              allowKeyboardControl
              onChange={(date: Date | null) => {}}
              ref={register}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              showTodayButton
              className="right"
              value={new Date()}
              defaultValue={editData ? editData?.TransactionDate : new Date()}
              minDate={new Date(latestOpenPeriodCheckingDate?.StartDate)}
              maxDate={new Date(latestOpenPeriodCheckingDate?.EndDate)}
              helperText={
                checkingYearClose2
                  ? 'Financial Period already closed'
                  : errors?.TrxDate?.message
              }
              error={errors?.TrxDate || checkingYearClose2 ? true : false}
            />
          </MuiPickersUtilsProvider>

          {!docNumHeaderLoading && (
            <>
              {docNoChecker === false ||
              getDocumentNumberHeader[0]?.IsAutoDocNo === false ? (
                <Controller
                  as={TextField}
                  name="DocNo"
                  label="Document No"
                  margin="dense"
                  required
                  id="standard-basic"
                  defaultValue={editData?.DocNo}
                  autoComplete="off"
                  control={control}
                  className="left"
                  ref={register}
                  helperText={errors?.DocNo?.message}
                  error={errors?.DocNo ? true : false}
                />
              ) : null}
            </>
          )}

          <Controller
            as={TextField}
            id="standard-basic"
            name="RefNo"
            label="Reference No."
            required
            autoComplete="off"
            control={control}
            className={
              docNoChecker === false ||
              getDocumentNumberHeader[0]?.IsAutoDocNo === false
                ? 'right'
                : ''
            }
            fullWidth={
              docNoChecker === false ||
              getDocumentNumberHeader[0]?.IsAutoDocNo === false
                ? true
                : false
            }
            margin="dense"
            ref={register}
            helperText={errors?.RefNo?.message}
            error={errors?.RefNo ? true : false}
            defaultValue={editData ? editData?.RefNo : ''}
            disabled={mode == 'approve-reject'}
          />

          <Controller
            as={TextField}
            id="standard-basic"
            name="UIN"
            label="UIN"
            // required
            autoComplete="off"
            control={control}
            fullWidth
            margin="dense"
            ref={register}
            helperText={errors?.UIN?.message}
            error={errors?.UIN ? true : false}
            defaultValue={editData ? editData?.einvoice_irbm_uniqueID : ''}
          />

          {!CreditorAccountLoading && (
            <Controller
              render={({ value, onChange }) => {
                const defVal = creditorAcc?.filter(
                  x =>
                    x?.CreditorAccountID === creditorAccID ??
                    editData?.CreditorAccountID
                )[0]

                return (
                  <Autocomplete
                    options={
                      creditorAcc?.sort((a, b) => {
                        return a.CompanyName.localeCompare(b.CompanyName)
                      }) || []
                    }
                    getOptionLabel={option => `${option?.CompanyName}`}
                    fullWidth
                    onChange={(value, newValue: any) => {
                      handleCreditorChange(newValue?.CreditorAccountID)
                      setValue('CreditorAccountID', newValue?.CreditorAccountID)
                      setTerm(newValue?.CreditTerm)
                      clearErrors('CreditorAccountID')
                      setSelectedDOs(new Set([]))
                      setInvoiceItemData([])
                      fetchGeneralDos({
                        variables: {
                          CreditorAccountID: newValue?.CreditorAccountID,
                          CompanyID,
                          DOItemIDs: editData?.APInvoiceItem?.filter?.(
                            y => y?.DOItemID !== null
                          )?.map(z => z?.DOItemID),
                        },
                      })
                    }}
                    renderOption={(props, option) => {
                      return <span>{props?.CompanyName}</span>
                    }}
                    defaultValue={defVal}
                    disabled={mode === 'approve-reject'}
                    renderInput={(params: any) => {
                      return (
                        <div>
                          <TextField
                            {...params}
                            helperText={errors?.CreditorAccountID?.message}
                            error={errors?.CreditorAccountID ? true : false}
                            label="Creditor Name"
                            style={{ width: '100%' }}
                            margin="dense"
                            required
                          />
                        </div>
                      )
                    }}
                  />
                )
              }}
              label="Creditor Name"
              name="CreditorAccountID"
              autoComplete="off"
              control={control}
              multiline={true}
              fullWidth
              margin="dense"
              ref={register}
              helperText={errors?.CreditorAccountID?.message}
              error={errors?.CreditorAccountID ? true : false}
              defaultValue={editData?.CreditorAccountID ?? creditorAccID}
              required
              disabled={mode === 'approve-reject'}
            />
          )}

          <Controller
            render={({ value, onChange }) => {
              return (
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  options={
                    getGeneralDOs
                      ?.sort((a, b) => {
                        return a.DocNo.localeCompare(b.DocNo)
                      })
                      ?.filter(y => y?.isMatchedQty === false) || []
                  }
                  getOptionLabel={option => `${option?.DocNo}`}
                  fullWidth
                  onChange={(event, value, reason) => {
                    const selected = new Set()
                    value?.map(x => {
                      selected.add(x?.DOHeaderID)
                    })
                    setSelectedDOs(new Set(selected))

                    fetchDOItem({
                      variables: {
                        DOHeaderID: Array.from(selectedDOs),
                      },
                    })
                    if (reason === 'remove-option') {
                      value?.map(x => {
                        selectedDOs.clear()
                        selectedDOs.add(x?.DOHeaderID)
                      })

                      fetchDOItem({
                        variables: {
                          DOHeaderID: Array.from(selectedDOs),
                        },
                      })
                    } else if (reason === 'clear') {
                      setSelectedDOs(new Set())
                      setInvoiceItemData([])
                    }
                  }}
                  defaultValue={editData?.DO}
                  renderOption={(option, { selected }) => (
                    <Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        value={option?.DOHeaderID}
                        style={{ marginRight: 8 }}
                        checked={selected}
                        color="primary"
                        onChange={event => {
                          handleCheckBoxChange(event)
                        }}
                      />
                      <ListItemText
                        primary={
                          <Grid container>
                            <Grid
                              item
                              xs={8}
                              component="span"
                              className="xsTitle "
                            >
                              {option?.DocNo}
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              component="span"
                              className="xsTitle right-text highlight-text"
                            >
                              {amtStr(option?.AcceptedAmt)}
                            </Grid>
                          </Grid>
                        }
                        secondary={
                          <Grid container>
                            <Grid
                              item
                              xs={8}
                              component="span"
                              className="desc"
                              style={{
                                wordWrap: 'break-word',
                                wordBreak: 'break-word',
                                whiteSpace: 'pre-wrap',
                              }}
                            >
                              {option?.PONo}
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              component="span"
                              className="xsTitle right-text highlight-text"
                            ></Grid>
                          </Grid>
                        }
                      />
                    </Fragment>
                  )}
                  renderInput={(params: any) => {
                    return (
                      <div>
                        <TextField
                          {...params}
                          variant="outlined"
                          style={{ width: '100%' }}
                          label="GRN No."
                          margin="normal"
                          onKeyDown={(event: any) => {
                            if (event.key === 'Backspace') {
                              event.stopPropagation()
                              setSelectedDOs(new Set())
                            }
                          }}
                        />
                      </div>
                    )
                  }}
                />
              )
            }}
            name="DOHeaderID"
            label="GRN No."
            margin="normal"
            autoComplete="off"
            fullWidth
            multiline={true}
            control={control}
            select
            ref={register}
          />
          <Controller
            as={TextField}
            id="standard-basic"
            name="Description"
            label="Description"
            required
            autoComplete="off"
            control={control}
            fullWidth
            margin="dense"
            helperText={errors?.Description?.message}
            error={errors?.Description ? true : false}
            ref={register}
            defaultValue={editData ? editData?.Description : ''}
            disabled={mode === 'approve-reject'}
          />

          <VoiceTextField
            mounted={true}
            label="Remark"
            name="Remark"
            value={voiceRemark}
            setValue={setValue}
            record={record}
            setRecord={setRecord}
            control={control}
            controllerProps={{
              error: errors?.Remark ? true : false,
              helperText: errors?.Remark?.message,
              ref: register,
              autoComplete: 'off',
            }}
          />

          {/* Self-Billed e-Invoice Implementation */}

          {isEinvoice && (
            <IsEinvoiceSelfBilled
              register={register}
              control={control}
              errors={errors}
              selfBilled={selfBilled}
              defaultMsicCodeID={defaultMsicCodeID}
              isSelfBilled={isSelfBilled}
              handleToggle={handleToggle}
              msicCodeLoading={msicCodeLoading}
              getMSICCode={getMSICCode}
              msicCodeID={msicCodeID}
              setMsicCodeID={setMsicCodeID}
              selfBilledChecking={!!selfBilled}
              haveSelfBilled={
                !!selfBilled && !!watch('isSelfBilled') && !msicCodeLoading
              }
              data={null}
              dataLoading={null}
              isDocRefID={false}
              docRefKeyID={null}
              editData={editData}
            />
          )}

          <div style={{ width: '100%', marginTop: '24px' }}>
            <FileUploadInput
              placeholder={previewFiles.length === 0 ? 'Attachment' : null}
              label={previewFiles.length > 0 ? 'Attachment' : null}
              name="Attachment"
              files={files}
              onHandleUploadChange={handleUploadChange}
              multiple
              accept={
                '.png, .jpg, .jpeg, .gif, .mp4, .avi, .mkv, .mov, .flv, .3gp, application/msword, application/pdf, application/vnd.ms-excel, application/*, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
              }
              imagePreview={
                <>
                  {previewFiles?.map((v, i) => (
                    <UploadPreview
                      remove
                      key={v}
                      src={v}
                      onClick={() => removeFile(i)}
                      mediaType={
                        files[i]?.type ?? DocData?.DocumentListing[i]?.mediaType
                      }
                    />
                  ))}
                </>
              }
            />
          </div>
        </CardContents>

        <ItemDetail
          titleLabel={'Invoice'}
          itemData={invoiceItemData}
          setOpenDialog={setOpenDialog}
          fetchExpenseItem={fetchExpenseItem}
          fetchCostCentre={fetchCostCentre}
          resetMenu={resetMenu}
          setSelectedTax={setSelectedTax}
          setDetailMode={setDetailMode}
          formMode={formMode}
          detailMode={detailMode}
          getItemByCompany={getItemByCompany}
          getCostCentre={getCostCentre}
          handleClick={handleClick}
          keyItemID={'InvoiceItemID'}
          isSelfBilled={isSelfBilled}
          creditorID={selectedCreditor}
        />
      </ContentWrapper>

      <Menu
        id="menu-list"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
      >
        <MenuItem
          onClick={() => {
            setEdit(true)
            setOpenDialog(true)
            setDetailMode('edit')
            fetchExpenseItem()
            fetchCostCentre()
          }}
        >
          <span className="">Edit</span>
        </MenuItem>
        <MenuItem
          onClick={() => {
            onDeleteInvoiceItem(menu?.index)
          }}
          disabled={readOnly() === true ? true : false}
        >
          <span className="">Delete</span>
        </MenuItem>
      </Menu>
      {!ExpenseItemLoading && (
        <ItemDialog
          companyID={CompanyID}
          title={'Invoice Detail'}
          fromAP={true}
          openItemDialog={openDialog}
          setOpenItemDialog={setOpenDialog}
          watch={watch}
          menu={menu}
          ExpenseItemLoading={ExpenseItemLoading}
          CompanyID={CompanyID}
          fetchExpenseItem={fetchExpenseItem}
          CostCentreLoading={CostCentreLoading}
          fetchCostCentre={fetchCostCentre}
          getCostCentre={getCostCentre}
          getItemByCompany={getItemByCompany}
          isEdit={edit}
          itemData={invoiceItemData}
          selectedTax={selectedTax}
          setSelectedTax={setSelectedTax}
          detailMode={detailMode}
          setDetailMode={setDetailMode}
          resetMenu={resetMenu}
          primaryItemKey="InvoiceItemID"
          defaultCostCentre={defaultCostCentre}
          getMasterCOA={getMasterCOA}
          masterCOALoading={masterCOALoading}
          selfBillStatus={isSelfBilled}
          EInvoiceStarted={isEinvoice}
          creditorAcc={selectedCreditor}
        />
      )}

      <AddFavoriteDialog
        openFavoriteDialog={openFavoriteDialog}
        setOpenFavoriteDialog={setOpenFavoriteDialog}
        favRegister={favRegister}
        favControl={favControl}
        favNames={favNames}
        favErrors={favErrors}
        favWatch={favWatch}
        dialogTitle={'Invoice Detail'}
        handleFavSubmit={handleFavSubmit}
        onSubmitFavorite={onSubmitFavorite}
      />

      <DeleteFavoriteDialog
        openFavoriteDeleteDialog={openFavoriteDeleteDialog}
        setOpenFavoriteDeleteDialog={setOpenFavoriteDeleteDialog}
        dialogTitle={'Favorite Invoice Entry'}
        menuFav={menuFav}
        deleteMutation={deleteAPFavoriteInvoice}
        favRefetchQuery={favRefetchQuery}
        favID={menuFav?.ID}
      />

      <ExitConfirmationDialog
        openExitConf={openExitConf}
        setOpenExitConf={setOpenExitConf}
        onConfirm={() => {
          history.push(`/account-payable/${CompanyID}/invoice`)
          localStorage.removeItem('invoice')
          localStorage.removeItem('invoiceItem')
        }}
      />

      <ErrorDialog
        errorDia={errorDia}
        setErrorDia={setErrorDia}
        errorMsg={errMsg}
        errorHeaderMsg={'Error!'}
      />

      {footerOptions?.length > 0 ? (
        <Footer options={[...footerOptions]} />
      ) : null}

      <TooltipAmountFooter data={invoiceItemData} module={'invoice-draft'} />
    </>
  )
}
