import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { default as useUploadDocument } from '@ifca-root/react-component/src/utils/hooks/useUploadAttachment'
import { ApprovalLogDialog } from 'components/Dialog/ApprovalLogDialog'
import { DetailAllocationTableContent } from 'components/Table/DetailAllocationTableContent'
import {
  useDocumentListingQuery,
  useGetUsersByAccountAndSoftwareQuery,
  UserSelection,
} from 'generated/graphql'
import { formatDate } from 'helpers/StringNumberFunction/FormatDate'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import React, { useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import { ARDetailContent } from './ARDetailContent'
import { ARItemDetailContent } from './ARItemDetailContent'
import { smTitle } from '@ifca-root/react-component/src/global/TitleVariable'
import { TooltipAmountFooter } from 'components/Footer/TooltipAmountFooter'

export const ARDetail = props => {
  let history = useHistory()
  let location = useLocation()
  const listData = location?.state as any
  const { CompanyID, DocumentID }: any = useParams()
  const { arSubmenu, routeSegments, accountType } = props
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const [openWorkFlow, setWorkFlow] = useState(false)

  const {
    loading: userLoading,
    error: userError,
    data: { getUsersByAccountAndSoftware } = {
      getUsersByAccountAndSoftware: [],
    },
  } = useGetUsersByAccountAndSoftwareQuery({
    fetchPolicy: 'network-only',
    variables: { superUserBool: UserSelection.All },
  })

  const {
    files,
    previewFiles,
    remove: removeFile,
    handleEditUpload,
    setFiles,
  } = useUploadDocument()

  const {
    loading: docLoading,
    error: docError,
    data: { DocumentListing } = { DocumentListing: [] },
  } = useDocumentListingQuery({
    fetchPolicy: 'network-only',
    variables: { refID: DocumentID },
    onCompleted: data => {
      handleEditUpload(data?.DocumentListing)
      previewFiles.push(...data?.DocumentListing?.map(x => x?.fileURL))
    },
  })

  let itemName
  switch (arSubmenu) {
    case 'credit-note':
      itemName = 'ARCreditNoteItem'
      break
    case 'invoice':
      itemName = 'ARInvoiceItem'
      break
    case 'debit-note':
      itemName = 'ARDebitNoteItem'
      break
    default:
      break
  }

  const allocationTotal = listData?.Allocation?.reduce(
    (prevValue, curVal) => prevValue + curVal?.AllocationAmt,
    0
  )

  return (
    <>
      {docLoading && <Loading />}
      {userLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() =>
          !!listData?.DebtorAccountID
            ? history.push(
                `/${accountType}/${CompanyID}/debtor-account/debtor-profile/${listData?.DebtorAccountID}/${arSubmenu}`
              )
            : history.push(`/${accountType}/${CompanyID}/${arSubmenu}`)
        }
        smTitle={smTitle.ACCOUNT_RECEIVABLE}
        title={`${user?.companyName}`}
        currency={user?.companyCurrencyCode}
        routeSegments={[
          { name: 'Account Receivable' },
          { name: 'AR Submenu' },
          { name: `${routeSegments}`, current: true },
        ]}
        rightRouteSegments={[{ name: `Approved`, current: true }]}
      />

      <DynamicSubHeader
        title={
          <>
            <span className="xsTitle" style={{ color: '#ff9800' }}>
              {listData?.DocNo}
            </span>
          </>
        }
        rightText={
          <span className="c-orange">{formatDate(listData?.DocDate)}</span>
        }
        infoLine={
          <>
            <div
              className="text-overflow"
              style={{ fontSize: '11px', fontWeight: 'bold', width: '270px' }}
            >
              {listData?.Description}
            </div>
          </>
        }
        rightInfoLine={
          <span className="desc flex-space">
            <span className="desc flex-space c-orange">
              {amtStr(listData?.DocAmt)}
            </span>
          </span>
        }
      />

      <ContentWrapper multiDynamicInfo float>
        <CardContents>
          <ARDetailContent
            arSubmenu={arSubmenu}
            listEl={listData}
            documentListing={DocumentListing}
            listStatus={'COMPLETED'}
            userList={getUsersByAccountAndSoftware}
            mode={'detail'}
          />
        </CardContents>

        {(arSubmenu === 'credit-note' ||
          arSubmenu === 'official-receipt' ||
          arSubmenu === 'refund') && (
          <>
            <CardContents
              section={{
                header: {
                  title: 'Allocation(s)',
                  rightTitle: amtStr(allocationTotal),
                },
              }}
            >
              <DetailAllocationTableContent listItem={listData?.Allocation} />
            </CardContents>
          </>
        )}

        {(arSubmenu === 'invoice' ||
          arSubmenu === 'debit-note' ||
          arSubmenu === 'credit-note') && (
          <CardContents
            section={{
              header: {
                title: `${routeSegments} Detail`,
                customFontSizeClass: 'xsTitle',
              },
            }}
          >
            <ARItemDetailContent listItem={listData?.[itemName]} />
          </CardContents>
        )}
      </ContentWrapper>
      <ApprovalLogDialog
        data={listData}
        setWorkFlow={setWorkFlow}
        openWorkFlow={openWorkFlow}
        moduleName={routeSegments}
      />

      <TooltipAmountFooter
        data={listData}
        module={`AR-${arSubmenu}-approved`}
        detail={true}
      />
    </>
  )
}
