import theme from '@ifca-root/react-component/src/assets/theme'
import { Divider, Grid, useMediaQuery } from '@material-ui/core'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import React from 'react'

export const ARItemDetailContent = (props: any) => {
  const { listItem } = props
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  })

  return (
    <>
      <div className="rm-padding table-wrap ">
        {listItem
          ?.filter(x => x?.DocAmt !== 0)
          ?.map((el, index) => {
            return (
              <>
                {/* First Row */}
                <Grid spacing={1} container className="table-content">
                  <Grid item xs={1} style={{ placeSelf: 'start' }}>
                    <span className="xxTitle">{`${index + 1}.`}</span>
                  </Grid>

                  <Grid
                    item
                    xs={8}
                    style={{ placeSelf: 'start', marginTop: '8px' }}
                  >
                    <div className="mdDesc text-noflow">
                      {el?.BillItem?.Name}
                    </div>
                  </Grid>

                  <Grid
                    item
                    xs={3}
                    style={{
                      placeSelf: 'start',
                      marginTop: '8px',
                      marginRight: 'auto',
                      textAlign: 'right',
                      flexWrap: 'wrap',
                      display: 'flex',
                      justifyContent: isDesktop ? 'end' : 'end',
                    }}
                  >
                    <div className="mdDesc">
                      {el.DocAmt < 0
                        ? `(${amtStr(el.DocAmt)})`
                        : amtStr(el.DocAmt)}
                    </div>
                  </Grid>
                </Grid>

                {/* Second Row */}
                <Grid spacing={1} container className="table-content">
                  <Grid item xs={1} style={{ placeSelf: 'start' }} />
                  <Grid
                    item
                    xs={8}
                    style={{ placeSelf: 'start', marginTop: '-18px' }}
                  >
                    <div className="desc text-noflow c-grey">
                      {el?.CostCentre?.Code}
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    style={{
                      placeSelf: 'start',
                      marginTop: '-18px',
                      marginRight: 'auto',
                      textAlign: 'right',
                      flexWrap: 'wrap',
                      display: 'flex',
                      justifyContent: isDesktop ? 'end' : 'end',
                    }}
                  >
                    <div className="desc text-noflow c-grey">
                      {amtStr(el?.Amount)}
                    </div>
                  </Grid>
                </Grid>

                {/* Third Row */}
                <Grid spacing={1} container className="table-content">
                  <Grid item xs={1} style={{ placeSelf: 'start' }} />
                  <Grid
                    item
                    xs={5}
                    style={{ placeSelf: 'start', marginTop: '-18px' }}
                  >
                    <div className="desc text-noflow c-grey">
                      {`${el?.ClassificationCode?.classificationCode}` +
                        ' ' +
                        `(${el?.ClassificationCode?.description})`}
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    style={{ placeSelf: 'start', marginTop: '-18px' }}
                  >
                    <div className="desc c-grey">
                      {`${el?.TaxScheme?.Code} | ${el?.TaxRate}%`}
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    style={{
                      placeSelf: 'start',
                      marginTop: '-18px',
                      marginRight: 'auto',
                      textAlign: 'right',
                      flexWrap: 'wrap',
                      display: 'flex',
                      justifyContent: isDesktop ? 'end' : 'end',
                    }}
                  >
                    <div className="desc text-noflow c-grey">
                      {amtStr(el?.TaxAmt)}
                    </div>
                  </Grid>
                </Grid>

                {/* Fourth Row */}
                {!!el?.Remark && (
                  <Grid spacing={1} container className="table-content">
                    <Grid item xs={1} style={{ placeSelf: 'start' }} />
                    <Grid
                      item
                      xs={11}
                      style={{
                        placeSelf: 'start',
                        marginTop: isDesktop
                          ? '-21px'
                          : el?.CostCentre?.Name.length > 20
                          ? '-16px'
                          : '-21px',
                      }}
                    >
                      <div className="desc text-noflow c-grey">
                        {el?.Remark}
                      </div>
                    </Grid>
                  </Grid>
                )}

                {index !== listItem?.length - 1 && (
                  <Divider
                    variant="fullWidth"
                    style={{ background: '#E4E4E4' }}
                  />
                )}
              </>
            )
          })}
      </div>
      <div className="footer-label" style={{ background: '#bfd3ff' }} />
    </>
  )
}
