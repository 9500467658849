import { Grid, Switch, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { AccCodeDropdownFullWidth } from 'components/Dropdown/AccCodeDropdown'
import { Controller } from 'react-hook-form'
import React from 'react'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { changeDateFormat } from '@ifca-root/react-component/src/helpers/Functions/dateFunction'

interface EinvoiceProps {
  register
  control
  errors
  selfBilled
  defaultMsicCodeID
  isSelfBilled
  handleToggle
  msicCodeLoading
  getMSICCode
  msicCodeID
  setMsicCodeID
  selfBilledChecking: boolean
  haveSelfBilled: boolean
  data?: any
  dataLoading?: any
  isDocRefID?: boolean
  docRefKeyID?: any
  editData?: any
  isSelfBilledForm?: boolean
}
export const IsEinvoiceSelfBilled = (props: EinvoiceProps) => {
  const {
    register,
    control,
    errors,
    selfBilled,
    defaultMsicCodeID,
    isSelfBilled,
    handleToggle,
    msicCodeLoading,
    getMSICCode,
    msicCodeID,
    setMsicCodeID,
    selfBilledChecking,
    haveSelfBilled,
    data,
    dataLoading,
    isDocRefID,
    docRefKeyID,
    editData,
    isSelfBilledForm = false,
  } = props

  return (
    <Grid spacing={1} container className="table-content">
      {selfBilledChecking && (
        <Grid item xs={6} style={{ padding: '18px' }}>
          <span className="flex-space">{'Self-Billed e-Invoice'}</span>
          <Switch
            name="isSelfBilled"
            color="primary"
            inputRef={register()}
            checked={isSelfBilled}
            defaultChecked={
              editData ? editData?.einvoice_self_billed : selfBilled
            }
            onChange={e => {
              handleToggle(e)
            }}
          />
        </Grid>
      )}
      {haveSelfBilled && (
        <>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              required
              as={KeyboardDatePicker}
              name="einvoice_date"
              label="Self Billed Date"
              control={control}
              onChange={(date: Date | null) => {
                changeDateFormat(date, 'YYYY-MM-DD')
              }}
              format="dd/MM/yyyy"
              value={new Date()}
              allowKeyboardControl
              ref={register}
              defaultValue={new Date()}
              showTodayButton
              disabled={true}
              className=" left"
              style={{ padding: '10px' }}
            />
          </MuiPickersUtilsProvider>

          <Grid item xs={isSelfBilledForm || isDocRefID ? 6 : 12}>
            {!msicCodeLoading && (
              <Controller
                render={({ value, onChange }) => {
                  let defVal
                  if (editData) {
                    defVal = getMSICCode?.filter(
                      msic =>
                        msic?.id === msicCodeID ??
                        editData?.einvoice_msic_code_id
                    )[0]
                  } else {
                    defVal = getMSICCode?.filter(
                      msic => msic?.id === defaultMsicCodeID
                    )[0]
                  }

                  return (
                    <Autocomplete
                      options={
                        getMSICCode?.sort((a, b) => {
                          return a?.msicCode.localeCompare(b?.msicCode)
                        }) || []
                      }
                      getOptionLabel={option =>
                        `${option?.msicCode} (${option?.description})`
                      }
                      fullWidth
                      onChange={(value, newValue: any) => {
                        onChange(newValue?.id)
                        setMsicCodeID(newValue?.id)
                      }}
                      // disabled={!isSelfBilled}
                      PopperComponent={AccCodeDropdownFullWidth}
                      renderOption={(props, option) => {
                        return (
                          <div className="content-wrap full">
                            <div>
                              <span className="xsTitle">{props?.msicCode}</span>
                            </div>
                            <div className="desc">{props?.description}</div>
                          </div>
                        )
                      }}
                      defaultValue={defVal}
                      renderInput={(params: any) => {
                        return (
                          <div>
                            <TextField
                              {...params}
                              name="MSICCodeID"
                              helperText={
                                (msicCodeID === null ||
                                  msicCodeID === undefined) &&
                                errors?.MSICCodeID?.message
                              }
                              error={
                                (msicCodeID === null ||
                                  msicCodeID === undefined) &&
                                errors?.MSICCodeID
                                  ? true
                                  : false
                              }
                              label="MSIC Code"
                              style={{ width: '100%' }}
                              InputLabelProps={defVal ? { shrink: true } : null}
                              margin="dense"
                              variant="standard"
                              required
                            />
                          </div>
                        )
                      }}
                    />
                  )
                }}
                label="MSIC Code"
                name="MSICCodeID"
                autoComplete="off"
                control={control}
                multiline={true}
                fullWidth
                margin="dense"
                ref={register}
                value={msicCodeID}
                defaultValue={
                  editData ? editData?.einvoice_msic_code_id : defaultMsicCodeID
                }
                // disabled={!isSelfBilled}
              />
            )}
          </Grid>
          {isDocRefID && (
            <Grid item xs={isSelfBilledForm ? 12 : 6}>
              {!dataLoading && (
                <Controller
                  render={({ value, onChange }) => {
                    let defVal
                    if (editData) {
                      defVal = data?.filter(
                        inv =>
                          inv?.[`${docRefKeyID}`] ===
                          editData?.einvoice_doc_ref_invoice_id
                      )[0]
                    }

                    return (
                      <Autocomplete
                        options={
                          data?.sort((a, b) => {
                            return a?.DocNo?.localeCompare(b?.DocNo)
                          }) || []
                        }
                        getOptionLabel={option => `${option?.DocNo}`}
                        fullWidth
                        onChange={(value, newValue: any) => {
                          onChange(newValue[docRefKeyID])
                        }}
                        renderOption={(props, option) => {
                          return <span>{props?.DocNo}</span>
                        }}
                        defaultValue={defVal}
                        renderInput={(params: any) => {
                          return (
                            <div>
                              <TextField
                                {...params}
                                helperText={errors?.DocRefID?.message}
                                error={errors?.DocRefID ? true : false}
                                label="Document Reference"
                                style={{ width: '100%' }}
                                InputLabelProps={
                                  defVal ? { shrink: true } : null
                                }
                                margin="dense"
                                variant="standard"
                                required
                              />
                            </div>
                          )
                        }}
                      />
                    )
                  }}
                  label="Document Reference"
                  name="DocRefID"
                  autoComplete="off"
                  control={control}
                  multiline={true}
                  fullWidth
                  margin="dense"
                  ref={register}
                  helperText={errors?.DocRefID?.message}
                  error={errors?.DocRefID ? true : false}
                  defaultValue={editData?.einvoice_doc_ref_invoice_id}
                />
              )}
            </Grid>
          )}
        </>
      )}
    </Grid>
  )
}
