import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { Editor } from '@tinymce/tinymce-react'
import {
  useGetStandardLetterParameterQuery,
  useUpdateCompanyLetterTemplateMutation,
  useUpdateLetterTemplateMutation,
} from 'generated/graphql'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import AppContext from 'containers/App/Store/AppContext'
import { useEDocTemplate } from './DocTemplate'
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'

export const LetterDesignForm = (props: any) => {
  const { mode } = props
  const {
    pageMode,
    StandardLetterID,
    LetterTemplateID,
    CompanyID,
    CompanyLetterTemplateID,
  }: any = useParams()
  const isHomeDefault = localStorage.getItem('isDefault')
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const { globalState, dispatch }: any = useContext(AppContext as any)
  let history = useHistory()
  let location: any = useLocation()
  let editData = location.state
  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any
  )

  const { htmlWrapper } = useEDocTemplate()

  const {
    loading,
    data: { getStandardLetterParameter } = { getStandardLetterParameter: [] },
  } = useGetStandardLetterParameterQuery({
    variables: {
      StandardLetterID: StandardLetterID,
    },
    fetchPolicy: 'network-only',
  })
  const [
    //updateLetterTemplate to updateLetterTemplate
    updateLetterTemplate,
    { loading: updateLetterTemplateLoading, error: updateLetterTemplateError },
  ] = useUpdateLetterTemplateMutation({
    onError: error => {
      setSnackBarMsg(error.message)
    },
    onCompleted: ({ updateLetterTemplate }) => {
      if (updateLetterTemplate) {
        setOpenSnackBar(true)
        setSnackBarMsg(SystemMsgs.updateRecord())
        history.push({
          pathname: `/${pageMode}/general-setting/standard-letter/${StandardLetterID}/letter-template`,
        })
      } else {
        setSnackBarMsg('Update Failed!')
      }
    },
  })
  const [
    //updateCompanyLetterTemplate to updateCompanyLetterTemplate
    updateCompanyLetterTemplate,
    {
      loading: updateCompanyLetterTemplateLoading,
      error: updateCompanyLetterTemplateError,
    },
  ] = useUpdateCompanyLetterTemplateMutation({
    onError: error => {
      setSnackBarMsg(error.message)
    },
    onCompleted: ({ updateCompanyLetterTemplate }) => {
      if (updateLetterTemplate) {
        setOpenSnackBar(true)
        setSnackBarMsg(SystemMsgs.updateRecord())
        history.push({
          pathname: `/${pageMode}/${CompanyID}/company-setting/company-standard-letter/${StandardLetterID}/company-letter-template`,
        })
      } else {
        setSnackBarMsg('Update Failed!')
      }
    },
  })

  const [contentValue, setContentValue] = useState('')
  const [ConfirmationDialog, setConfirmationDialog] = useState(false)
  const [IsChange, setIsChange] = useState(false)
  const checkIsChange = (changed: boolean) => {
    if (!changed) {
      return history.goBack()
    } else {
      return setConfirmationDialog(true)
    }
  }

  useEffect(() => {
    if (!!editData) {
      setContentValue(editData?.TemplateBody)
    }
  }, [editData, document])

  const newContentValue = contentValue?.replaceAll('&nbsp;', '')

  const onSubmit = () => {
    if (CompanyID) {
      updateCompanyLetterTemplate({
        variables: {
          input: {
            CompanyLetterTemplateID: CompanyLetterTemplateID,
            TemplateBody: newContentValue,
          },
        },
      })
    } else {
      updateLetterTemplate({
        variables: {
          input: {
            LetterTemplateID: LetterTemplateID,
            TemplateBody: newContentValue,
          },
        },
      })
    }
  }

  const itemName = getStandardLetterParameter
    ?.map(x => {
      if (x?.ColumnName === '[DateTime]') return '[Date/Time]'
      return x?.ColumnName
    })
    ?.join(' ')

  const newListing = getStandardLetterParameter

  let headerTitle
  if (pageMode === 'general-ledger') headerTitle = 'General Ledger'
  else if (pageMode === 'cash-book') headerTitle = 'Cash Book'
  else if (pageMode === 'account-receivable') headerTitle = 'Account Receivable'
  else if (pageMode === 'account-payable') headerTitle = 'Account Payable'
  else if (pageMode === 'inventory-control') headerTitle = 'Inventory Control'
  else if (pageMode === 'general-purchase') headerTitle = 'General Purchase'

  // WHY ARE YOU USING USEEFFECT MUHAMMAD KOCHA? - EMIR
  // useEffect(() => {
  //   if (pageMode === 'general-ledger') {
  //     headerTitle = 'General Ledger'
  //   } else if (pageMode === 'cash-book') {
  //     headerTitle = 'Cash Book'
  //   } else if (pageMode === 'account-receivable') {
  //     headerTitle = 'Account Receivable'
  //   } else if (pageMode === 'account-payable') {
  //     headerTitle = 'Account Payable'
  //   } else if (pageMode === 'inventory-control') {
  //     headerTitle = 'Inventory Control'
  //   } else if (pageMode === 'general-purchase') {
  //     headerTitle = 'General Purchase'
  //   }
  // }, [pageMode])

  return (
    <>
      {loading && <Loading />}
      {updateLetterTemplateLoading && <Loading />}
      {updateCompanyLetterTemplateLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() => {
          history.push({
            pathname: CompanyID
              ? `/${pageMode}/${CompanyID}/company-setting/company-standard-letter/${StandardLetterID}/company-letter-template`
              : `/${pageMode}/general-setting/standard-letter/${StandardLetterID}/letter-template`,
          })
        }}
        smTitle={isHomeDefault === 'true' ? 'AccountX' : headerTitle}
        title={user?.companyName}
        // title={getCompany[0]?.Name}
        routeSegments={
          globalState?.isPathSysAdmin
            ? [
                { name: 'System Admin' },
                { name: 'System Admin' },
                { name: 'Standard Letter' },
                { name: 'Letter Template', current: true },
              ]
            : [
                { name: 'System Admin' },
                { name: 'Standard Letter' },
                { name: 'Letter Template', current: true },
              ]
        }
        rightRouteSegments={[
          {
            name: 'Design',
            current: true,
          },
        ]}
      />

      <DynamicSubHeader
        title={editData?.Name} // title text
      />

      <ContentWrapper search>
        {!loading ? (
          <Editor
            apiKey={'l1efx9nxpkp8sqwe0wd8c3kl299h3djao396u2zsm9ijvexf'}
            // initialValue={'type here'}
            init={{
              // height: 500,
              // menubar: false,
              // image_title: true,
              // automatic_uploads: true,
              // file_picker_types: 'image',

              // plugins: [
              //   'advlist autolink lists link image',
              //   'charmap print preview anchor help',
              //   'searchreplace visualblocks code',
              //   'insertdatetime media table paste wordcount',
              //   'table',
              //   'image  | code | preview',
              // ],
              // toolbar:
              //   'undo redo | formatselect | bold italic | \
              // alignleft aligncenter alignright | \
              // bullist numlist outdent indent | help | \
              // table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol | \
              // image | code | preview',
              convert_urls: false,
              plugins:
                'preview paste importcss searchreplace autolink autosave  directionality code visualblocks visualchars fullscreen image link  template table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
              imagetools_cors_hosts: ['picsum.photos'],
              menubar: 'file edit view insert format tools table custom ',
              toolbar:
                ' customInsertButton | undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview   | insertfile image  template link anchor code | ltr rtl',
              toolbar_sticky: true,
              image_advtab: true,
              //importcss_append: true,
              entity_encoding: 'raw',
              forced_root_block: false,

              menu: {
                custom: {
                  title: 'Parameters',
                  items: itemName,
                },
              },
              setup: function(editor) {
                editor.ui.registry.addButton('customInsertButton', {
                  text: 'Reset',
                  onAction: function(_) {
                    setContentValue(editData?.TemplateBody)
                    setIsChange(true)
                    // editor.insertContent(
                    //   "&nbsp;<strong>It's my button!</strong>&nbsp;"
                    // )
                  },
                })
                return newListing?.map(x => {
                  const slicedColumnName = x?.ColumnName?.replaceAll('[', '')
                    ?.replaceAll(']', '')
                    ?.trim()

                  const newParams =
                    x?.ColumnName === '[Date/Time]'
                      ? '[DateTime]'
                      : x?.ColumnName
                  editor.ui.registry?.addMenuItem(x.ColumnName, {
                    text: `${slicedColumnName}`,
                    onAction: function() {
                      editor.insertContent(`${newParams}`)
                    },
                  })
                })
              },

              // template_cdate_format:
              //   '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
              // template_mdate_format:
              //   '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
              height: 700,
              // image_caption: true,
              // quickbars_selection_toolbar:
              //   'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
              // noneditable_noneditable_class: 'mceNonEditable',
              // toolbar_mode: 'sliding',
              // contextmenu: 'link image imagetools table',
              // skin: 'oxide',
              // content_css: 'default',
              // content_style:
              //   'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
            }}
            value={contentValue}
            onEditorChange={(newValue, editor) => {
              setContentValue(newValue)
              setIsChange(true)
            }}
          />
        ) : null}

        <Footer
          options={[
            {
              name: 'Save',
              onClick: () => onSubmit(),

              color: 'primary',
            },
          ]}
        />
      </ContentWrapper>
    </>
  )
}
