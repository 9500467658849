import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  useCreateApCreditNoteMutation,
  useCreateApCreditorCreditNoteMutation,
  useCreateApCreditorDebitNoteMutation,
  useCreateApDebitNoteMutation,
  useCreateApSelfBilledMutation,
  useUpdateApCreditNoteMutation,
  useUpdateApCreditorCreditNoteMutation,
  useUpdateApCreditorDebitNoteMutation,
  useUpdateApDebitNoteMutation,
  useUpdateApSelfBilledMutation,
} from 'generated/graphql'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import React, { useContext, useState } from 'react'
import { useHistory, useParams } from 'react-router'

interface APCNForm {
  docType: string
  CompanyID: string
  mode?: string
  isSubmit: boolean
}
export const useAPCreateUpdateMutation = ({
  docType,
  mode,
  CompanyID,
  isSubmit,
}: APCNForm) => {
  const { ID }: any = useParams()
  let history = useHistory()

  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any
  )
  const [errorDia, setErrorDia] = useState<boolean>(false)
  const [errMsg, setErrMsg] = useState<string>('')
  const path = `/account-payable/${CompanyID}/${docType}`
  /* -------------------------------------------- */
  /*                  MUTATION                    */
  /* -------------------------------------------- */

  const [
    createAPCreditorCreditNote,
    {
      loading: createAPCreditorCreditNoteLoading,
      called: createAPCreditorCreditNoteCalled,
      error: createAPCreditorCreditNoteError,
    },
  ] = useCreateApCreditorCreditNoteMutation({
    onError: error => {},
    onCompleted: () => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.createNewRecord())
      setTimeout(() => {
        history.push({
          pathname: path,
        })
      }, 500)
    },
  })
  const [
    updateAPCreditorCreditNote,
    {
      loading: updateAPCreditorCreditNoteLoading,
      called: updateAPCreditorCreditNoteCalled,
      error: updateAPCreditorCreditNoteError,
    },
  ] = useUpdateApCreditorCreditNoteMutation({
    onError: error => {},
    onCompleted: () => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.createNewRecord())
      setTimeout(() => {
        history.push({
          pathname: path,
        })
      }, 500)
    },
  })

  const [
    createAPCreditNote,
    {
      loading: createAPCreditNoteLoading,
      called: createAPCreditNoteCalled,
      error: createAPCreditNoteError,
    },
  ] = useCreateApCreditNoteMutation({
    onCompleted: ({ createAPCreditNote }) => {
      setTimeout(() => {
        if (!!isSubmit) {
          history.push({
            pathname: `/account-payable/${CompanyID}/${docType}/${createAPCreditNote?.CreditNoteID}/preview`,
          })
        } else {
          history.push({
            pathname: path,
          })
        }
      }, 500)
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.createNewRecord())
    },
  })
  const [
    updateAPCreditNote,
    {
      loading: updateAPCreditNoteLoading,
      called: updateAPCreditNoteCalled,
      error: updateAPCreditNoteError,
    },
  ] = useUpdateApCreditNoteMutation({
    onCompleted: ({ updateAPCreditNote }) => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.createNewRecord())
      setTimeout(() => {
        if (!!isSubmit) {
          history.push({
            pathname: `/account-payable/${CompanyID}/${docType}/${updateAPCreditNote?.CreditNoteID}/preview`,
          })
        } else {
          history.push({
            pathname: path,
          })
        }
      }, 500)
    },
  })

  /* -------------------------------------------- */
  /*                  MUTATION                    */
  /* -------------------------------------------- */

  const [
    createAPDebitNote,
    {
      loading: createAPDebitNoteLoading,
      called: createAPDebitNoteCalled,
      error: createAPDebitNoteError,
    },
  ] = useCreateApDebitNoteMutation({
    onError: error => {},
    onCompleted: ({ createAPDebitNote }) => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.createNewRecord())
      setTimeout(() => {
        if (!!isSubmit) {
          history.push({
            pathname: `/account-payable/${CompanyID}/${docType}/${createAPDebitNote?.DebitNoteID}/preview`,
          })
        } else {
          history.push({
            pathname: path,
          })
        }
      }, 500)
    },
  })

  const [
    updateAPDebitNote,
    {
      loading: updateAPDebitNoteLoading,
      called: updateAPDebitNoteCalled,
      error: updateAPDebitNoteError,
    },
  ] = useUpdateApDebitNoteMutation({
    onError: error => {},
    onCompleted: ({ updateAPDebitNote }) => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.createNewRecord())
      setTimeout(() => {
        if (!!isSubmit) {
          history.push({
            pathname: `/account-payable/${CompanyID}/${docType}/${updateAPDebitNote?.DebitNoteID}/preview`,
          })
        } else {
          history.push({
            pathname: path,
          })
        }
      }, 500)
    },
  })

  const [
    createAPCreditorDebitNote,
    {
      loading: createAPCreditorDebitNoteLoading,
      called: createAPCreditorDebitNoteCalled,
      error: createAPCreditorDebitNoteError,
    },
  ] = useCreateApCreditorDebitNoteMutation({
    onError: error => {},
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.createNewRecord())
      setTimeout(() => {
        history.push({
          pathname: path,
        })
      }, 500)
    },
  })

  const [
    updateAPCreditorDebitNote,
    {
      loading: updateAPCreditorDebitNoteLoading,
      called: updateAPCreditorDebitNoteCalled,
      error: updateAPCreditorDebitNoteError,
    },
  ] = useUpdateApCreditorDebitNoteMutation({
    onError: error => {},
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.createNewRecord())
      setTimeout(() => {
        history.push({
          pathname: path,
        })
      }, 500)
    },
  })

  const [
    createAPSelfBilled,
    {
      loading: createAPSelfBilledLoading,
      called: createAPSelfBilledCalled,
      error: createAPSelfBilledError,
    },
  ] = useCreateApSelfBilledMutation({
    onError: error => {},
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.createNewRecord())
      setTimeout(() => {
        history.push({
          pathname: path,
        })
      }, 500)
    },
  })

  const [
    updateAPSelfBilled,
    {
      loading: updateAPSelfBilledLoading,
      called: updateAPSelfBilledCalled,
      error: updateAPSelfBilledError,
    },
  ] = useUpdateApSelfBilledMutation({
    onError: error => {},
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.createNewRecord())
      setTimeout(() => {
        history.push({
          pathname: path,
        })
      }, 500)
    },
  })

  const handleMutation =
    mode === 'add' && docType === 'creditor-credit-note'
      ? createAPCreditorCreditNote
      : mode === 'add' && docType === 'credit-note'
      ? createAPCreditNote
      : mode === 'edit' && docType === 'creditor-credit-note'
      ? updateAPCreditorCreditNote
      : mode === 'edit' && docType === 'credit-note'
      ? updateAPCreditNote
      : mode === 'add' && docType === 'debit-note'
      ? createAPDebitNote
      : mode === 'edit' && docType === 'debit-note'
      ? updateAPDebitNote
      : mode === 'add' && docType === 'creditor-debit-note'
      ? createAPCreditorDebitNote
      : mode === 'edit' && docType === 'creditor-debit-note'
      ? updateAPCreditorDebitNote
      : mode === 'add' && docType === 'self-billed'
      ? createAPSelfBilled
      : updateAPSelfBilled

  const mutationLoading =
    mode === 'add' && docType === 'creditor-credit-note'
      ? createAPCreditorCreditNoteLoading
      : mode === 'add' && docType === 'credit-note'
      ? createAPCreditNoteLoading
      : mode === 'edit' && docType === 'creditor-credit-note'
      ? updateAPCreditorCreditNoteLoading
      : mode === 'edit' && docType === 'credit-note'
      ? updateAPCreditNoteLoading
      : mode === 'add' && docType === 'debit-note'
      ? createAPDebitNoteLoading
      : mode === 'edit' && docType === 'debit-note'
      ? updateAPDebitNoteLoading
      : mode === 'add' && docType === 'creditor-debit-note'
      ? createAPCreditorDebitNoteLoading
      : mode === 'edit' && docType === 'creditor-debit-note'
      ? updateAPCreditorDebitNoteLoading
      : mode === 'add' && docType === 'self-billed'
      ? createAPSelfBilledLoading
      : updateAPSelfBilledLoading

  const mutationCalled =
    mode === 'add' && docType === 'creditor-credit-note'
      ? createAPCreditorCreditNoteCalled
      : mode === 'add' && docType === 'credit-note'
      ? createAPCreditNoteCalled
      : mode === 'edit' && docType === 'creditor-credit-note'
      ? updateAPCreditorCreditNoteCalled
      : mode === 'edit' && docType === 'credit-note'
      ? updateAPCreditNoteCalled
      : mode === 'add' && docType === 'debit-note'
      ? createAPDebitNoteCalled
      : mode === 'edit' && docType === 'debit-note'
      ? updateAPDebitNoteCalled
      : mode === 'add' && docType === 'creditor-debit-note'
      ? createAPCreditorDebitNoteCalled
      : mode === 'edit' && docType === 'debit-note'
      ? updateAPCreditorDebitNoteCalled
      : mode === 'add' && docType === 'self-billed'
      ? createAPSelfBilledCalled
      : updateAPSelfBilledCalled

  return {
    handleMutation,
    mutationLoading,
    mutationCalled,
  }
}
