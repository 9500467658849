import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import DropdownBar from '@ifca-root/react-component/src/components/SpecialBar/DropdownBar'
import { SearchBar } from '@ifca-root/react-component/src/components/SpecialBar/SearchBar'
import { useFilterDialog } from '@ifca-root/react-component/src/components/SpecialBar/SearchFilter/SearchFilterV2'
import { Tune } from '@material-ui/icons'
import { useGetCompanyNameQuery } from 'generated/graphql'
import { useFuseSearch } from 'helpers/Hooks/useSearch'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { APEntryList } from './APListing/APEntryList'
import { APPostingList } from './APListing/APPostingList'
import { useAPListingData } from './Hooks/useAPListingData'

export const APMain = (props: any) => {
  let history = useHistory()
  const { apSubmenu, routeSegments, accountType } = props
  const [isEinvoice, setIsEinvoice] = useState<boolean>(false)

  const { CompanyID }: any = useParams()
  /* -------------------------------------------- */
  /*                    SEARCH                    */
  /* -------------------------------------------- */
  const getSearch = JSON.parse(localStorage.getItem('searchFilter'))
  const {
    filteredList,
    handleSearch,
    setOriginalListing,
    originalList,
  } = useFuseSearch()

  const { openFilterDialog, handleFilterDate, filterDialog } = useFilterDialog({
    dateRange: {
      title: 'Doc Date',
      key: 'DocDate',
    },
  })

  /* -------------------------------------------- */
  /*                     ENUM                     */
  /* -------------------------------------------- */

  const approvalStatusEnum = [
    { name: 'Draft', ID: 'ACTIVE' },
    { name: 'Submitted', ID: 'SUBMIT' },
    { name: 'Rejected', ID: 'REJECTED' },
    { name: 'Approved', ID: 'COMPLETED' },
  ]

  /* -------------------------------------------- */
  /*                     STATE                    */
  /* -------------------------------------------- */
  const [listStatus, setListStatus] = useState<string>(
    getSearch?.option ?? 'ACTIVE'
  )

  const [postIDs, setPostIDs] = useState<Set<any>>(new Set([]))

  /* -------------------------------------------- */
  /*                     QUERY                    */
  /* -------------------------------------------- */

  const {
    loading: CompanyLoading,
    data: { getCompany } = { getCompany: [] },
  } = useGetCompanyNameQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID },
    onCompleted: ({ getCompany }) => {
      setIsEinvoice(getCompany[0]?.EInvoiceStarted)
    },
  })
  const {
    loading,
    primaryKey,
    createPermissionCheck,
    updatePermissionCheck,
    deletePermissionCheck,
    approveRejectPermissionCheck,
    userList,
    loadData,
  } = useAPListingData({
    accountType,
    apSubmenu,
    CompanyID,
    setOriginalListing,
    filteredList,
  })

  /* -------------------------------------------- */
  /*                   FUNCTION                   */
  /* -------------------------------------------- */

  useEffect(() => {
    setOriginalListing([])
    loadData({
      variables: {
        CompanyID: CompanyID,
        StatusArr: [listStatus],
        skip: 0,
        take: 30,
        searchValue:
          getSearch?.name === '' || !getSearch?.name
            ? undefined
            : getSearch?.name,
      },
    })
  }, [listStatus])

  //to make delay for search function for the query to load
  const [timer, setTimer] = useState(null)
  function changeDelay(change) {
    if (timer) {
      clearTimeout(timer)
      setTimer(null)
    }
    setTimer(
      setTimeout(() => {
        setOriginalListing([])
        loadData({
          variables: {
            CompanyID: CompanyID,
            StatusArr: [listStatus],
            skip: 0,
            take: 30,
            searchValue: change,
          },
        })
      }, 1000)
    )
  }

  return (
    <>
      {loading && <Loading />}
      {CompanyLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() => {
          history.push(`/account-payable/${CompanyID}`)
          localStorage.removeItem(`searchFilter`)
        }}
        smTitle={'Account Payable'}
        title={getCompany[0]?.Name}
        routeSegments={[
          { name: 'Account Payable' },
          { name: routeSegments, current: true },
        ]}
      />

      <div className="search-filter with-dropdown-filter">
        <SearchBar
          borderRadius="4px"
          onChangeAction={e => {
            // handleSearch(e?.target?.value, [
            //   'CreditorAccount.CompanyName',
            //   'DocAmt',
            //   'DocNo',
            //   'Description',
            //   'DocDateFormatted',
            // ])
            localStorage.setItem(
              'searchFilter',
              JSON.stringify({
                option: listStatus ?? 'ACTIVE',
                name: e?.target?.value,
              })
            )
            changeDelay(e?.target?.value)
          }}
          isDefaultValue={!!getSearch}
          defaultValue={getSearch?.name ? getSearch?.name : ''}
          onCloseAction={() => {
            handleSearch('', [])
            setOriginalListing([])
            localStorage.removeItem('searchFilter')
            loadData({
              variables: {
                CompanyID: CompanyID,
                StatusArr: [listStatus],
                skip: 0,
                take: 30,
              },
            })
          }}
          option={{
            icon: <Tune />,
            onClick: openFilterDialog,
          }}
        />
        {filterDialog}

        <DropdownBar
          options={approvalStatusEnum}
          setOptionID={setListStatus}
          borderRadius="4px"
          defaultOption={approvalStatusEnum
            ?.map(opt => opt?.ID)
            .indexOf(listStatus ?? 'ACTIVE')}
          searchFilter={getSearch}
        />
      </div>

      {(listStatus === 'ACTIVE' ||
        listStatus === 'COMPLETED' ||
        listStatus === 'REJECTED') && (
        <APEntryList
          apSubmenu={apSubmenu}
          loading={loading}
          setOriginalListing={setOriginalListing}
          filteredList={filteredList}
          listLoading={loading}
          listStatus={listStatus}
          userList={userList}
          primaryKey={primaryKey}
          handleFilterDate={handleFilterDate}
          createPermissionCheck={createPermissionCheck}
          updatePermissionCheck={updatePermissionCheck}
          deletePermissionCheck={deletePermissionCheck}
          loadData={loadData}
          CompanyID={CompanyID}
          getSearch={getSearch}
          isEinvoice={isEinvoice}
        />
      )}

      {listStatus === 'SUBMIT' && (
        <APPostingList
          list={filteredList}
          loading={loading}
          filteredList={filteredList}
          listLoading={loading}
          listStatus={listStatus}
          postIDs={postIDs}
          setPostIDs={setPostIDs}
          userList={userList}
          handleFilterDate={handleFilterDate}
          approveRejectPermissionCheck={approveRejectPermissionCheck}
          setOriginalListing={setOriginalListing}
          apSubmenu={apSubmenu}
          loadData={loadData}
          CompanyID={CompanyID}
          getSearch={getSearch}
          isEinvoice={isEinvoice}
        />
      )}
    </>
  )
}
